import { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import CroppedImg from "../CroppedImg";
import { useAccountState } from "../../state/store";
import { sort_by_order } from "../../utils/utils";
import { DefaultStyles } from "../../utils/proposal_utils";
import DraggableList from "../DraggableList";
import { FileDropZone } from "../FileUploader";
import { GridControl } from "../Proposal";

export const ImagesV4 = (props) => {
  const [
    addProposalImg,
    handleProposalImgDragEnd,
    removeProposalImg,
    updateProposalImgInState,
  ] = useAccountState((state) => [
    state.addProposalImg,
    state.handleProposalImgDragEnd,
    state.removeProposalImg,
    state.updateProposalImgInState,
  ]);

  const addImg = (data) => {
    addProposalImg({
      ...data,
      proposal_section_uuid: props.proposal_section.uuid,
    });
  };

  const handleUploadedFile = (images) => {
    var data = {
      cropped_image: images,
      version: 2,
    };
    addImg(data);
  };

  const updateProposalImg = (data) => {
    updateProposalImgInState({
      ...data,
      proposal_section_uuid: props.proposal_section.uuid,
    });
  };

  const removeImg = (image_uuid) => {
    removeProposalImg(props.proposal_section.uuid, image_uuid);
  };
  const handleDragEnd = (result) => {
    handleProposalImgDragEnd(props.proposal_section.uuid, result);
  };

  const max_photos = 50;

  return (
    <Box sx={{ p: "1rem" }}>
      <Grid container spacing={2}>
        {!props.displayOnly && (
          <Grid item xs={12} container spacing={1}>
            <Grid item xs="auto">
              <GridControl
                label="Columns"
                id="imageCols"
                defaultKey="imageCols"
                proposal_section={props.proposal_section}
                min={1}
                max={4}
                step={1}
              />
            </Grid>
            <Grid item xs="auto">
              <GridControl
                label="Spacing"
                id="spacing"
                defaultKey="spacing"
                proposal_section={props.proposal_section}
                min={0}
                max={25}
                step={1}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="info.main">
                Mobile:{" "}
                {(props.proposal_section.style?.imageCols ||
                  DefaultStyles.imageCols) === 1
                  ? "1 column"
                  : "2 columns"}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DraggableList
            list={props.proposal_section.proposal_imgs.sort(sort_by_order)}
            listContainerComponent={Grid}
            listContainerComponentProps={{
              container: true,
              spacing:
                props.proposal_section.style?.spacing || DefaultStyles.spacing,
              justifyContent:
                props.proposal_section.style?.items_align ||
                DefaultStyles.itemsAlign,
            }}
            listItemComponent={GridImg}
            listItemComponentProps={{
              proposal_section: props.proposal_section,
              displayOnly: props.displayOnly,
              zoom: props.zoom,
              removeImg: removeImg,
              addImg: addImg,
              updateProposalImg: updateProposalImg,
              height:
                props.proposal_section.style?.row_height ||
                DefaultStyles.rowHeight,
            }}
            listItemPropName="img"
            onDragEnd={handleDragEnd}
          />
        </Grid>
        {!props.displayOnly && (
          <Grid item xs={12} container justifyContent={"center"}>
            <Grid item xs="auto">
              <Typography variant="caption" color="info.main">
                drag and drop to reorder
              </Typography>
            </Grid>
          </Grid>
        )}
        {!props.displayOnly &&
        props.proposal_section.proposal_imgs.length < max_photos ? (
          <Grid item xs={12}>
            <>
              <Box
                sx={{
                  width: "95%",
                  p: "1rem",
                  borderStyle: "dotted",
                  borderColor: "lightgrey",
                  display: props.displayOnly ? "none" : "flex",
                  overflow: "visible",
                }}
              >
                <Box sx={{ m: "auto", width: "100%", height: "100%" }}>
                  <FileDropZone
                    hideBorder
                    width="100%"
                    height="100%"
                    id={props.id + "-dropzone"}
                    handleSubmit={handleUploadedFile}
                    maxSelections={
                      max_photos - props.proposal_section.proposal_imgs.length
                    }
                    disableMaxSelectionsText
                  />
                </Box>
              </Box>
            </>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

const GridImg = (props) => {
  const [img, setImg] = useState(props.img);

  const handleCropSubmit = (cropped_images) => {
    props.updateProposalImg({
      uuid: img.uuid,
      cropped_image: { ...img.cropped_image, ...cropped_images[0] },
    });
  };

  const handleDelete = () => {
    props.removeImg(img.uuid);
    setImg(undefined);
  };

  useEffect(() => {
    setImg(props.img);
  }, [props.img, props.img.cropped_image]);

  const grid_props = props.displayOnly
    ? {}
    : {
        draggable: props.draggable,
        onDragStart: props.onDragStart,
        onDragOver: props.onDragOver,
        onDrop: props.onDrop,
        onDragEnd: props.onDragEnd,
        style: props.style,
      };

  const imageColsMap = {
    1: 12,
    2: 6,
    3: 4,
    4: 3,
  };

  return img ? (
    <Grid
      item
      xs={
        (props.proposal_section?.style?.imageCols ||
          DefaultStyles.imageCols) === 1
          ? 12
          : 6
      }
      sm={
        imageColsMap[
          props.proposal_section?.style?.imageCols || DefaultStyles.imageCols
        ]
      }
      {...grid_props}
    >
      {!props.isPlaceholder && (
        <CroppedImg
          img={img.cropped_image}
          zoom={props.zoom}
          width={"100%"}
          height={"100%"}
          onDelete={!props.displayOnly ? handleDelete : undefined}
          onEdit={!props.displayOnly ? handleCropSubmit : undefined}
          square
        />
      )}
    </Grid>
  ) : null;
};

const widths = [15, 20, 15, 25, 17, 30, 20, 30, 15];

export const ImagesV4Preview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      spacing={0.1}
    >
      {widths.map((w, idx) => (
        <Grid item xs={4} key={idx}>
          <Skeleton
            variant="rectangular"
            width={23}
            height={23}
            animation={false}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const ImagesV4View = (props) => {
  return (
    <ImagesV4 proposal_section={props.proposal_section} displayOnly zoom />
  );
};
