import React, { useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Alert,
} from "@mui/material";

import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  getSetupIntentApi,
  deletePaymentMethodApi,
  getPaymentMethodApi,
} from "../api/StripeApi";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useAccountState } from "../state/store";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const PaymentDetails = (props) => {
  const [adding_payment_details, setAddingPaymentDetails] =
    React.useState(false);
  const [deleting_payment_method, setDeletingPaymentMethod] =
    React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return adding_payment_details ? (
    <NewPaymentDetailsWrapper
      handleCancel={() => setAddingPaymentDetails(false)}
      setStripeCustomer={props.setStripeCustomer}
      open={adding_payment_details}
    />
  ) : props.stripe_customer && props.stripe_customer.default_payment_method ? (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Card</TableCell>

            <TableCell>Expiration</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ textTransform: "capitalize" }}>
              {props.stripe_customer.default_payment_method.card.brand}{" "}
              {"x" + props.stripe_customer.default_payment_method.card.last4}
            </TableCell>
            <TableCell>
              {props.stripe_customer.default_payment_method.card.exp_month}/
              {props.stripe_customer.default_payment_method.card.exp_year}
            </TableCell>
            <TableCell>
              <Grid container>
                <Grid item xs={fullScreen ? "6" : "auto"}>
                  <IconButton onClick={() => setAddingPaymentDetails(true)}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={fullScreen ? "6" : "auto"}>
                  <IconButton onClick={() => setDeletingPaymentMethod(true)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DeletePaymentDetailsDialog
        open={deleting_payment_method}
        handleCancel={() => setDeletingPaymentMethod(false)}
        setStripeCustomer={props.setStripeCustomer}
        stripe_customer={props.stripe_customer}
      />
    </TableContainer>
  ) : (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item xs={12}>
        <Box
          sx={{
            borderStyle: "dotted",
            borderColor: "lightgray",
            borderRadius: "5px",
            p: "1rem",
          }}
        >
          <Typography align="center" color="lightgray">
            No payment details on file.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setAddingPaymentDetails(true)}
        >
          Add Payment Details
        </Button>
      </Grid>
    </Grid>
  );
};

export const NewPaymentDetailsWrapper = (props) => {
  const [options, setOptions] = React.useState(undefined);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getSetupIntentApi().then((resp) => {
      setOptions({ clientSecret: resp.data.client_secret });
      setLoaded(true);
    });
  }, []);
  return loaded ? (
    <Elements stripe={stripePromise} options={options}>
      <Dialog open={props.open} onClose={props.handleCancel}>
        <Box sx={{ p: "1rem" }}>
          <PaymentDetailsCollect
            handleCancel={props.handleCancel}
            setStripeCustomer={props.setStripeCustomer}
            setLoaded={setLoaded}
            callback={props.callback}
          />
        </Box>
      </Dialog>
    </Elements>
  ) : (
    <Dialog open={props.open}>
      <Box display="flex" sx={{ p: "1rem" }}>
        <CircularProgress sx={{ m: "auto" }} />
      </Box>
    </Dialog>
  );
};

export const NewPaymentDetailsWrapperNoDialog = (props) => {
  const [options, setOptions] = React.useState(undefined);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getSetupIntentApi().then((resp) => {
      setOptions({ clientSecret: resp.data.client_secret });
      setLoaded(true);
    });
  }, []);
  return loaded ? (
    <Elements stripe={stripePromise} options={options}>
      <Box sx={{ p: "1rem" }}>
        <PaymentDetailsCollect
          handleCancel={props.handleCancel}
          setStripeCustomer={props.setStripeCustomer}
          setLoaded={setLoaded}
          callback={props.callback}
          submitLabel={props.submitLabel}
          submitDisabled={props.submitDisabled}
        />
      </Box>
    </Elements>
  ) : (
    <Box display="flex" sx={{ p: "1rem" }}>
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  );
};

const PaymentDetailsCollect = (props) => {
  const [updateUserStripeCustomer] = useAccountState((state) => [
    state.updateUserStripeCustomer,
  ]);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = React.useState(undefined);
  const [error_alert_open, setErrorAlertOpen] = React.useState(false);
  const [active_step, setActiveStep] = React.useState("address");
  const setError = (error_message) => {
    setErrorMessage(error_message);
    setErrorAlertOpen(true);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { setupIntent, error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_BASE_URL + "/account",
      },
      redirect: "if_required",
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setError(error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      getPaymentMethodApi(setupIntent.payment_method).then((resp) => {
        if (props.setStripeCustomer) {
          props.setStripeCustomer(resp.data);
        } else {
          updateUserStripeCustomer(resp.data);
        }
        props.handleCancel();
        if (props.callback) {
          props.callback();
        }
      });
    }
  };

  return stripe && elements ? (
    <Box>
      {error_alert_open ? (
        <Grid item xs={12}>
          <Alert
            severity="error"
            onClose={() => {
              setErrorAlertOpen(false);
            }}
          >
            {error}
          </Alert>
        </Grid>
      ) : null}
      <form onSubmit={handleSubmit}>
        <AddressElement options={{ mode: "billing" }} />
        <PaymentElement />
        {/* {errorMessage && <div>{errorMessage}</div>} */}

        {!loading ? (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                onClick={props.handleCancel}
                variant="outlined"
                color="info"
                sx={{ ml: "auto" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={!stripe || props.submitDisabled}
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ ml: "auto" }}
              >
                {props.submitLabel ? props.submitLabel : "Submit"}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Box display="flex">
            <CircularProgress sx={{ ml: "auto" }} />
          </Box>
        )}
      </form>
    </Box>
  ) : (
    <CircularProgress />
  );
};

const DeletePaymentDetailsDialog = (props) => {
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = () => {
    setLoading(true);
    deletePaymentMethodApi().then((resp) => {
      props.setStripeCustomer(resp.data);
      props.handleCancel();
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Confirm Delete Payment Details</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want delete the following payment method?
        </Typography>
        <Box display="flex">
          <Typography sx={{ textTransform: "capitalize", mr: ".25rem" }}>
            {props.stripe_customer.default_payment_method.card.brand}
          </Typography>
          <Typography>
            {"x" +
              props.stripe_customer.default_payment_method.card.last4 +
              " "}{" "}
            {"Exp." +
              props.stripe_customer.default_payment_method.card.exp_month}
            /{props.stripe_customer.default_payment_method.card.exp_year}
          </Typography>
        </Box>
      </DialogContent>
      {!loading ? (
        <DialogActions>
          <Button variant="outlined" color="info" onClick={props.handleCancel}>
            Nevermind
          </Button>
          <Button variant="contained" color="error" onClick={handleSubmit}>
            Delete Payment Method
          </Button>
        </DialogActions>
      ) : (
        <CircularProgress sx={{ ml: "auto", mr: "1rem", mb: "1rem" }} />
      )}
    </Dialog>
  );
};
