import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { styled } from "@mui/material/styles";

import { eventDateTime } from "./EventDetails.js";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Paper,
  Switch,
  Grid,
  Typography,
  Box,
  Divider,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Select,
  MenuItem,
  CircularProgress,
  Snackbar,
  Link,
  Alert,
  FormGroup,
  Fab,
  RadioGroup,
  Radio,
  Collapse,
  FormControl,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
  TableHead,
  TableRow,
  TableCell,
  Table,
  Stack,
  Slider,
} from "@mui/material";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ColorizeOutlinedIcon from "@mui/icons-material/ColorizeOutlined";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import Moment from "moment";
import { HexColorPicker } from "react-colorful";
import "react-image-crop/dist/ReactCrop.css";
import {
  hexToRGB,
  sort_by_order,
  sortByName,
  sortEventItemsAndSubItems,
} from "../utils/utils.js";
import { Document, Page, pdfjs } from "react-pdf";
import { PdfViewerDialog } from "./PdfViewerDialog.js";
import useResizeObserver from "use-resize-observer";
import { useAccountState } from "../state/store.js";
import { CollectPaymentDetailsForPlan } from "./CollectPaymentDetails.js";
import {
  DefaultStyles,
  ProposalSectionsByType,
  ProposalSectionsLibrary,
} from "../utils/proposal_utils.js";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { PDFUploader } from "./FileUploader.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import GamesOutlinedIcon from "@mui/icons-material/GamesOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import { keyframes } from "@emotion/react";
import { HeadingTextControls } from "./ProposalSections/Notes.js";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { FontSelector } from "./FontSelector.js";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import FormatAlignRightOutlinedIcon from "@mui/icons-material/FormatAlignRightOutlined";
import FormatAlignJustifyOutlinedIcon from "@mui/icons-material/FormatAlignJustifyOutlined";
import { ProposalViewDialog } from "./ProposalView.js";
import {
  FontSizeValueLabelMap,
  ProposalSectionStylePopover,
} from "./ProposalSections/ProposalSectionStylePopover.js";
import { PrintProposalDialog } from "./ClientView.js";
import { RecipeProposalSectionName } from "./RecipeProposalSection.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Proposal = (props) => {
  const [
    back_office,
    event,
    eventHasAmendments,
    flowerbuddyFeeCost,
    handleProposalSectionDragEnd,
    lockEventItemPrices,
    proposal,
    proposalWarnings,
    updateEvent,
    updateProposal,
    userCanPublish,
    userPlanType,
  ] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.eventHasAmendments,
    state.flowerbuddyFeeCost,
    state.handleProposalSectionDragEnd,
    state.lockEventItemPrices,
    state.proposal,
    state.proposalWarnings,
    state.updateEvent,
    state.updateProposal,
    state.userCanPublish,
    state.userPlanType,
  ]);
  const [collecting_payment_details, setCollectingPaymentDetails] =
    React.useState(false);
  const [updatingBackgroundColor, setUpdatingBackgroundColor] =
    React.useState(false);
  const [updatingFontColor, setUpdatingFontColor] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [publishing, setPublishing] = React.useState(false);
  const [copiedDialogOpen, setCopiedDialogOpen] = React.useState(false);

  const setUpdatingBackgroundColorTrue = () => {
    setUpdatingBackgroundColor(true);
  };

  const setUpdatingBackgroundColorFalse = () => {
    setUpdatingBackgroundColor(false);
  };

  const setUpdatingFontColorTrue = () => {
    setUpdatingFontColor(true);
  };

  const setUpdatingFontColorFalse = () => {
    setUpdatingFontColor(false);
  };

  const updateProposalSection = (section_name, value, cb) => {
    updateProposal(
      {
        uuid: proposal.uuid,
        [section_name]: value,
      },
      cb
    );
  };

  Moment.locale("en");

  const updateBackgroundColor = (hexCode) => {
    updateProposalSection("background_color", hexCode);
  };

  const updateFontColor = (hexCode) => {
    updateProposalSection("font_color", hexCode);
  };

  const updateBackgroundTexture = (e) => {
    updateProposalSection("background_texture", e.target.value);
  };

  const updateFont = (e) => {
    updateProposalSection("font", e.target.value);
  };

  const togglePublished = (e) => {
    if (e.target.checked) {
      setPublishing(true);
    } else {
      updateProposalSection("published", e.target.checked);
    }
  };

  const toggleAmendmentsPublished = (e) => {
    if (e.target.checked) {
      setPublishing(true);
    } else {
      updateProposalSection("amendments_published", e.target.checked);
    }
  };

  const getExpireDate = () => {
    if (proposal.expiration_date) {
      return new Date(proposal.expiration_date);
    } else {
      var date = new Date();
      date.setDate(date.getDate() + proposal.default_expiration_days);
      return date;
    }
  };

  const toggleExpires = (e) => {
    if (e.target.checked) {
      var date_str = getExpireDate();
      updateProposal({
        expiration_date: date_str,
        expires: e.target.checked,
      });
    } else {
      updateProposalSection("expires", e.target.checked);
    }
  };

  const updateExpirationDate = (val) => {
    updateProposalSection("expiration_date", val);
  };

  const toggleShowExpirationDate = (e) => {
    updateProposal({
      show_expiration_date: e.target.checked,
    });
  };

  const [dragging, setDragging] = React.useState(false);

  const handleDragEnd = (result) => {
    handleProposalSectionDragEnd(result);
  };

  const [addingSection, setAddingSection] = React.useState(false);

  const [updatingSectionFonts, setUpdatingSectionFonts] = React.useState(false);

  const [defaultExpirationDays, setDefaultExpirationDays] = React.useState(
    proposal.default_expiration_days
  );

  const handleDefaultExpirationDaysChange = (e) => {
    setDefaultExpirationDays(e.target.value);
  };

  const updateDefaultExpirationDays = () => {
    updateProposal({
      default_expiration_days: defaultExpirationDays,
    });
  };

  const [previewing, setPreviewing] = React.useState(false);

  const copyProposalLink = () => {
    try {
      navigator.clipboard
        .writeText(
          process.env.REACT_APP_BASE_URL + "/proposal/" + proposal.uuid
        )
        .then(() => {
          setCopied(true);
        });
    } catch (err) {
      console.log("Error copying to clipboard: ", err);
    }
  };

  const [printing, setPrinting] = React.useState(false);

  return proposal ? (
    <Box
      sx={{
        height: "calc(100vh - 225px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ mb: !dragging ? "calc(100vh/2)" : "1rem", pt: ".5rem" }}>
        <Grid
          container
          justifyContent="flex-end"
          sx={{
            maxWidth: "800px",
            m: "auto",
          }}
        >
          <Grid
            item
            xs={12}
            container
            spacing={1}
            justifyContent={"flex-end"}
            alignItems="center"
          >
            {userPlanType() !== "FRE" && (
              <Grid item xs="auto">
                <Grid item xs="auto">
                  <Button
                    onClick={() => setPrinting(true)}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    Print Proposal
                  </Button>

                  <PrintProposalDialog
                    open={printing}
                    handleCancel={() => setPrinting(false)}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setPreviewing(true)}
              >
                Preview Proposal
              </Button>
              <ProposalViewDialog
                open={previewing}
                handleCancel={() => setPreviewing(false)}
              />
            </Grid>

            {!props.profileSettings && !event.is_template && (
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_BASE_URL +
                        "/proposal/" +
                        proposal.uuid +
                        "?preview=true"
                    );
                  }}
                  id="preview-proposal-button"
                >
                  Client Portal
                </Button>
              </Grid>
            )}

            {!props.profileSettings && !event.is_template && (
              <Grid item xs="auto">
                {userCanPublish() || event.created_during_trial ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    id="copy-proposal-link-button"
                    onClick={() => {
                      if (proposal.published) {
                        copyProposalLink();
                      } else {
                        setPublishing(true);
                      }
                    }}
                  >
                    Copy Sharable Link
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setCollectingPaymentDetails(true);
                    }}
                    id="copy-proposal-link-button"
                  >
                    Copy Sharable Link
                  </Button>
                )}
                {!userCanPublish() && (
                  <CollectPaymentDetailsForPlan
                    open={collecting_payment_details}
                    handleCancel={() => {
                      setCollectingPaymentDetails(false);
                    }}
                    plan_type="FRE"
                  />
                )}
                <Snackbar
                  open={copied}
                  autoHideDuration={3000}
                  onClose={() => {
                    setCopied(false);
                  }}
                  message="Link Copied!"
                />
                <PublishProposalDialog
                  open={publishing}
                  handleCancel={() => setPublishing(false)}
                  copyProposalLink={copyProposalLink}
                />
              </Grid>
            )}
          </Grid>
          {!event.is_template && !props.profileSettings && (
            <Grid item xs={12} container alignItems="center">
              <Grid item xs="auto">
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (proposal.published &&
                          (userCanPublish() || event.created_during_trial)) ||
                        event.status === "BO"
                      }
                      onChange={togglePublished}
                      id="toggle-published-switch"
                      disabled={
                        (!userCanPublish() && !event.created_during_trial) ||
                        event.status === "BO"
                      }
                    />
                  }
                  label={"Published"}
                  labelPlacement="start"
                />
                {!userCanPublish() && !event.created_during_trial && (
                  <Tooltip title="A payment method is required to publish a proposal.">
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </Tooltip>
                )}
              </Grid>

              {eventHasAmendments() && (
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.amendments_published}
                        onChange={toggleAmendmentsPublished}
                        id="toggle-amendments-published-switch"
                      />
                    }
                    label={"Amendments Published"}
                    labelPlacement="start"
                  />
                </Grid>
              )}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs="auto" container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <FormControlLabel
                  control={
                    <Switch
                      checked={proposal.expires}
                      onChange={toggleExpires}
                      id="toggle-expires-switch"
                    />
                  }
                  label={"Expires"}
                  labelPlacement="start"
                />
              </Grid>
              {proposal.expires && (
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.show_expiration_date}
                        onChange={toggleShowExpirationDate}
                        id="toggle-show-expiration-switch"
                      />
                    }
                    label={"Show Expiration Date"}
                    labelPlacement="start"
                  />
                </Grid>
              )}
            </Grid>
            {proposal.expires &&
              !props.profileSettings &&
              !event.is_template && (
                <Grid item xs="auto">
                  <DatePicker
                    inputFormat={back_office.date_format}
                    name="expire_date"
                    id="expire_date"
                    value={getExpireDate()}
                    onChange={updateExpirationDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="filled"
                        size="small"
                        hiddenLabel
                      />
                    )}
                    fullWidth
                  />
                </Grid>
              )}
            {proposal.expires &&
              (props.profileSettings || event.is_template) && (
                <Grid item xs="auto">
                  <TextField
                    id="default_expiration_days"
                    value={defaultExpirationDays}
                    label="Expires After (days)"
                    onChange={handleDefaultExpirationDaysChange}
                    onBlur={updateDefaultExpirationDays}
                    size="small"
                    sx={{ mt: "1rem" }}
                  />
                </Grid>
              )}
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{
                m: "auto",
                mb: !dragging && "calc(100vh/4)",
                mt: "1rem",
                pb: "1rem",
                maxWidth: "800px",
                backgroundColor: proposal.background_color,
                backgroundImage: proposal.background_texture
                  ? `url("${
                      process.env.REACT_APP_S3_STATIC_MEDIA_URL +
                      proposal.background_texture
                    }")`
                  : undefined,
              }}
            >
              <Grid
                container
                id="proposal-title-and-style-settings"
                justifyContent={"center"}
              >
                {proposalWarnings(
                  proposal,
                  event.is_template,
                  eventHasAmendments()
                ).map((warning) => (
                  <Grid item xs={12} key={warning}>
                    <Alert severity="warning">{warning}</Alert>
                  </Grid>
                ))}

                <Grid
                  item
                  xs={12}
                  sm={3}
                  display="grid"
                  sx={{ m: "auto", mt: "1rem" }}
                >
                  <Typography textAlign="center">Background Color</Typography>
                  <Button
                    onClick={setUpdatingBackgroundColorTrue}
                    variant="contained"
                    sx={{ m: "auto" }}
                    id="background-color-button"
                  >
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        mr: ".25rem",
                        backgroundColor: proposal.background_color,
                      }}
                    ></Box>
                    {proposal.background_color}
                  </Button>
                  <ColorPickerDialog
                    open={updatingBackgroundColor}
                    handleCancel={setUpdatingBackgroundColorFalse}
                    handleSubmit={updateBackgroundColor}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  display="grid"
                  sx={{ m: "auto", mt: "1rem" }}
                >
                  <Typography textAlign="center">Background Texture</Typography>
                  <Select
                    name="background_texture"
                    label="Background Texture"
                    id="background_texture"
                    value={proposal.background_texture}
                    onChange={updateBackgroundTexture}
                    variant="outlined"
                    sx={{
                      m: "auto",
                      width: "10rem",
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "primary.main",
                        pt: ".42rem",
                        pb: ".42rem",
                      },
                    }}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="beige-paper.png">Beige Paper</MenuItem>
                    <MenuItem value="cardboard-flat.png">
                      Cardboard Flat
                    </MenuItem>
                    <MenuItem value="clean-gray-paper.png">
                      Clean Paper
                    </MenuItem>
                    <MenuItem value="cream-paper.png">Cream Paper</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  display="grid"
                  sx={{ m: "auto", mt: "1rem" }}
                >
                  <Typography textAlign="center">Default Font</Typography>
                  <FontSelector
                    value={proposal.font}
                    updateFont={updateFont}
                    id="font-selector"
                    sx={{
                      m: "auto",
                      width: "10rem",
                    }}
                    inputProps={{
                      sx: {
                        backgroundColor: "primary.main",
                        pt: ".42rem",
                        pb: ".42rem",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  display="grid"
                  sx={{ m: "auto", mt: "1rem" }}
                >
                  <Typography textAlign="center">Default Font Color</Typography>
                  <Button
                    onClick={setUpdatingFontColorTrue}
                    variant="contained"
                    sx={{ m: "auto" }}
                    id="font-color-button"
                  >
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        mr: ".25rem",
                        backgroundColor: proposal.font_color,
                      }}
                    ></Box>
                    {proposal.font_color}
                  </Button>
                  <ColorPickerDialog
                    open={updatingFontColor}
                    handleCancel={setUpdatingFontColorFalse}
                    handleSubmit={updateFontColor}
                  />
                </Grid>
                <Divider sx={{ width: "100%", mt: "1rem", mb: "1rem" }} />

                {dragging && (
                  <>
                    <Grid
                      item
                      xs={"auto"}
                      container
                      justifyContent={"center"}
                      spacing={1}
                    >
                      <Grid item xs="auto">
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => setDragging(false)}
                        >
                          Done Reordering
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography textAlign={"center"} color="info.main">
                          Drag and Drop to Reorder Sections
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!dragging && (
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent={"flex-end"}
                    spacing={1}
                  >
                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => setUpdatingSectionFonts(true)}
                      >
                        Update Sections Style
                      </Button>
                      <UpdateSectionStyles
                        open={updatingSectionFonts}
                        handleCancel={() => setUpdatingSectionFonts(false)}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ mr: ".5rem" }}
                        onClick={() => setAddingSection(true)}
                      >
                        + Section
                      </Button>
                      <AddProposalSectionDialog
                        open={addingSection}
                        handleCancel={() => setAddingSection(false)}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="proposal-sections" type="parent">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Box
                            sx={{
                              mb: !dragging && "calc(100vh/4)",
                              pl: dragging ? ".5rem" : undefined,
                              pr: dragging ? ".5rem" : undefined,
                            }}
                          >
                            {proposal.proposal_sections
                              ?.sort(sort_by_order)
                              .map((section, idx) => (
                                <ProposalSection
                                  id={`proposal-section-${section.uuid}`}
                                  key={section.uuid}
                                  proposal_section={section}
                                  index={idx}
                                  dragging={dragging}
                                  setDragging={setDragging}
                                  profileSettings={props.profileSettings}
                                />
                              ))}
                            {provided.placeholder}
                          </Box>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>
                {!dragging && (
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{ mr: ".5rem" }}
                      onClick={() => setAddingSection(true)}
                    >
                      + Section
                    </Button>
                    <AddProposalSectionDialog
                      open={addingSection}
                      handleCancel={() => setAddingSection(false)}
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
            {dragging && (
              // <Grid container justifyContent={"center"}>
              //   <Grid item xs={"auto"}>
              <Fab
                variant="extended"
                color="secondary"
                size="small"
                onClick={() => setDragging(false)}
                sx={{
                  position: "fixed",
                  bottom: "2rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                Done Reordering
              </Fab>
              //   </Grid>
              // </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress
          sx={{
            m: "auto",
            mt: "25%",
          }}
        />
      </Box>
    </Box>
  );
};

const UpdateSectionStyles = (props) => {
  const [bulkUpdateProposalSections, proposal] = useAccountState((state) => [
    state.bulkUpdateProposalSections,
    state.proposal,
  ]);

  const defaultSettings = () => {
    return {
      font: proposal.font,
      font_size: "1rem",
      font_color: proposal.font_color,
      text_align: "left",
      font_body: true,
      font_size_body: true,
      font_color_body: true,
      text_align_body: true,
    };
  };

  const [settings, setSettings] = React.useState(defaultSettings());

  const handleCancel = () => {
    setSettings(defaultSettings());
    props.handleCancel();
  };

  const updateFonts = () => {
    let data = {};
    if (settings.font_enabled) {
      if (settings.font_heading) {
        data["heading_font"] = settings.font;
      }
      if (settings.font_body) {
        data["font"] = settings.font;
      }
    }
    if (settings.font_size_enabled) {
      if (settings.font_size_heading) {
        data["heading_font_size"] = settings.font_size;
      }
      if (settings.font_size_body) {
        data["font_size"] = settings.font_size;
      }
    }
    if (settings.font_color_enabled) {
      if (settings.font_color_heading) {
        data["heading_font_color"] = settings.font_color;
      }
      if (settings.font_color_body) {
        data["font_color"] = settings.font_color;
      }
    }
    if (settings.text_align_enabled) {
      if (settings.text_align_body) {
        data["text_align"] = settings.text_align;
      }
      if (settings.text_align_heading) {
        data["heading_text_align"] = settings.text_align;
      }
    }
    if (settings.heading_underline_enabled) {
      data["heading_underline"] = settings.heading_underline;
    }
    if (!data) {
      handleCancel();
      return;
    } else {
      bulkUpdateProposalSections({ style: data });
      handleCancel();
    }
  };

  const selectFont = (e) => {
    setSettings({ ...settings, font: e.target.value });
  };

  const updateFontSize = (val) => {
    setSettings({ ...settings, font_size: val });
  };

  const [updatingFontColor, setUpdatingFontColor] = React.useState(false);
  const setUpdatingFontColorTrue = () => {
    setUpdatingFontColor(true);
  };
  const setUpdatingFontColorFalse = () => {
    setUpdatingFontColor(false);
  };
  const updateFontColor = (hexCode) => {
    setSettings({ ...settings, font_color: hexCode });
  };

  return (
    <Dialog open={props.open} onClose={handleCancel}>
      <DialogTitle>Update Section Styles</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert severity="warning">
              This action will update styling for all sections.
            </Alert>
          </Grid>
          <StyleUpdateSection
            id="font"
            settings={settings}
            setSettings={setSettings}
            label="Font"
          >
            <FontSelector
              value={settings.font}
              updateFont={selectFont}
              label="Font"
              id="font-selector"
            />
          </StyleUpdateSection>
          <StyleUpdateSection
            id="font_size"
            settings={settings}
            setSettings={setSettings}
            label="Font Size"
          >
            <FontSizeSelector
              value={settings.font_size}
              updateSize={updateFontSize}
            />
          </StyleUpdateSection>
          <StyleUpdateSection
            id="font_color"
            settings={settings}
            setSettings={setSettings}
            label="Font Color"
          >
            <Button
              onClick={setUpdatingFontColorTrue}
              variant="contained"
              sx={{ m: "auto" }}
              id="font-color-button"
            >
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  mr: ".25rem",
                  backgroundColor: settings.font_color,
                }}
              ></Box>
              {settings.font_color}
            </Button>
          </StyleUpdateSection>
          <StyleUpdateSection
            id="text_align"
            settings={settings}
            setSettings={setSettings}
            label="Text Align"
          >
            <TextAlignToggle settings={settings} setSettings={setSettings} />
          </StyleUpdateSection>
          <ColorPickerDialog
            open={updatingFontColor}
            handleCancel={setUpdatingFontColorFalse}
            handleSubmit={updateFontColor}
          />
          <StyleUpdateSection
            id="heading_underline"
            settings={settings}
            setSettings={setSettings}
            label="Line Under Heading"
            disableHeadingBodyToggles
          >
            <FormControlLabel
              control={
                <Switch
                  checked={settings.heading_underline}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      heading_underline: e.target.checked,
                    })
                  }
                  id="toggle-heading-underline-switch"
                />
              }
              label={"Underline"}
              labelPlacement="start"
            />
          </StyleUpdateSection>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={updateFonts}
          disabled={
            !settings.font_enabled &&
            !settings.font_size_enabled &&
            !settings.font_color_enabled &&
            !settings.text_align_enabled &&
            !settings.heading_underline_enabled
          }
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyleUpdateSection = (props) => {
  const enabled_field = `${props.id}_enabled`;
  const heading_field = `${props.id}_heading`;
  const body_field = `${props.id}_body`;

  const updateEnabled = (e) => {
    props.setSettings({ ...props.settings, [enabled_field]: e.target.checked });
  };
  const updateHeading = (e) => {
    props.setSettings({ ...props.settings, [heading_field]: e.target.checked });
  };
  const updateBody = (e) => {
    props.setSettings({ ...props.settings, [body_field]: e.target.checked });
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          borderColor: "lightgray",
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            container
            justifyContent={"space-between"}
            sx={{ backgroundColor: "lightgray" }}
            alignItems={"center"}
          >
            <Grid item xs="auto">
              <Typography sx={{ pl: ".25rem" }}>{props.label}</Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Switch
                checked={props.settings[enabled_field]}
                onChange={updateEnabled}
                id="toggle-enabled-switch"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={props.settings[enabled_field]}>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ pt: ".5rem", pl: ".5rem", pr: ".5rem" }}>
                    {props.children}
                  </Box>
                </Grid>
                {!props.disableHeadingBodyToggles && (
                  <Grid item xs="auto">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.settings[heading_field]}
                          onChange={updateHeading}
                          id="toggle-heading-switch"
                        />
                      }
                      label={"Heading"}
                      labelPlacement="start"
                    />
                  </Grid>
                )}
                {!props.disableHeadingBodyToggles && (
                  <Grid item xs="auto">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.settings[body_field]}
                          onChange={updateBody}
                          id="toggle-body-switch"
                        />
                      }
                      label={"Body"}
                      labelPlacement="start"
                    />
                  </Grid>
                )}
                {!props.settings[body_field] &&
                  !props.settings[heading_field] &&
                  !props.disableHeadingBodyToggles && (
                    <Grid item xs={12}>
                      <Alert severity="info" size="small">
                        No updates will take place for {props.label} with
                        current settings. Enable the Heading or Body settings
                        above to apply changes.
                      </Alert>
                    </Grid>
                  )}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

const TextAlignToggle = (props) => {
  const handleAlignment = (event, newAlignment) => {
    props.setSettings({ ...props.settings, text_align: newAlignment });
  };

  return (
    <ToggleButtonGroup
      value={props.settings.text_align}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      size="small"
    >
      <ToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeftOutlinedIcon />
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
        <FormatAlignCenterOutlinedIcon />
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        <FormatAlignRightOutlinedIcon />
      </ToggleButton>
      <ToggleButton value="justify" aria-label="justified">
        <FormatAlignJustifyOutlinedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const FontSizeSelector = (props) => {
  const handleChange = (e) => {
    props.updateSize(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="font-size-select-label">{"Font Size"}</InputLabel>
      <Select
        labelId="font-size-select-label"
        id="font-size-select"
        value={props.value}
        label={"Font Size"}
        onChange={handleChange}
        size="small"
        renderValue={(value) => (
          <Typography>{FontSizeValueLabelMap[value]}</Typography>
        )}
      >
        <MenuItem value={"0.5rem"}>
          <Typography sx={{ fontSize: "0.5rem" }}>Extra Small</Typography>
        </MenuItem>
        <MenuItem value={"0.75rem"}>
          <Typography sx={{ fontSize: "0.75rem" }}>Small</Typography>
        </MenuItem>
        <MenuItem value={"1rem"}>
          <Typography sx={{ fontSize: "1rem" }}>Regular</Typography>
        </MenuItem>
        <MenuItem value={"1.25rem"}>
          <Typography sx={{ fontSize: "1.25rem" }}>Large</Typography>
        </MenuItem>
        <MenuItem value={"1.5rem"}>
          <Typography sx={{ fontSize: "1.5rem" }}>Extra Large</Typography>
        </MenuItem>
        <MenuItem value={"2rem"}>
          <Typography sx={{ fontSize: "2rem" }}>Huge</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

function ProposalSection(props) {
  const Component =
    ProposalSectionsByType[props.proposal_section.section_type]
      ?.section_component;

  const [updateProposalSection, updateProposalSectionStyle] = useAccountState(
    (state) => [state.updateProposalSection, state.updateProposalSectionStyle]
  );

  const toggleEnabled = () => {
    updateProposalSection({
      uuid: props.proposal_section.uuid,
      enabled: !props.proposal_section.enabled,
    });
  };
  const toggleHeader = () => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      show_heading: !props.proposal_section.style?.show_heading,
    });
  };

  const wiggle = keyframes`
  0%, 100% {
    transform: rotate(-.1deg);
  }
  50% {
    transform: rotate(.1deg);
  }
`;

  const [deleting, setDeleting] = React.useState(false);

  const [addingSection, setAddingSection] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Draggable draggableId={props.proposal_section.uuid} index={props.index}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{ p: "1rem", border: "1px solid black" }}
          >
            <Grid
              item
              xs={12}
              container
              sx={
                props.dragging &&
                !snapshot.isDragging && {
                  display: "inline-block",
                  animation: `${wiggle} 0.25s infinite`,
                  animationDelay: `${Math.random() * 0.5}s`,
                }
              }
            >
              <Grid item xs={12}>
                <Box
                  sx={{ backgroundColor: "lightgrey", mt: "1rem", mb: "1rem" }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Grid
                      item
                      xs={props.dragging ? "auto" : 5}
                      container
                      alignItems={"center"}
                    >
                      <Grid item xs="auto">
                        {props.dragging ? (
                          <div {...provided.dragHandleProps}>
                            <DragIndicatorOutlinedIcon
                              sx={{ color: "info.main" }}
                            />
                          </div>
                        ) : (
                          <Tooltip title="Click to Reorder Sections">
                            <IconButton onClick={() => props.setDragging(true)}>
                              <ImportExportOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                      {!props.dragging && (
                        <Grid item xs="auto">
                          <Tooltip title="Add Section Above">
                            <IconButton onClick={() => setAddingSection(true)}>
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs="auto">
                        <Typography variant="h6" sx={{ pl: ".5rem" }}>
                          {
                            ProposalSectionsByType[
                              props.proposal_section.section_type
                            ].label
                          }
                          {props.dragging &&
                            props.proposal_section.style?.heading &&
                            ` - ${props.proposal_section.style?.heading}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <AddProposalSectionDialog
                      open={addingSection}
                      handleCancel={() => setAddingSection(false)}
                      order={props.index}
                    />

                    <Grid item xs={6} container justifyContent={"flex-end"}>
                      {!(
                        ProposalSectionsByType[
                          props.proposal_section.section_type
                        ].component_props?.disableHeading ||
                        ProposalSectionsByType[
                          props.proposal_section.section_type
                        ].component_props?.disableHeadingDisplay
                      ) &&
                        !props.dragging && (
                          <Grid item xs="auto">
                            <FormGroup
                              aria-label="position"
                              row
                              sx={{ mr: "1rem" }}
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    checked={
                                      props.proposal_section.style?.show_heading
                                    }
                                    onChange={toggleHeader}
                                    id={props.id + "-toggle-header-switch"}
                                  />
                                }
                                label="Show Header"
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </Grid>
                        )}
                      {!props.dragging &&
                        props.proposal_section.section_type !==
                          "scope_of_services" && (
                          <Grid item xs="auto">
                            <FormGroup
                              aria-label="position"
                              row
                              sx={{ mr: "1rem" }}
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    checked={props.proposal_section.enabled}
                                    onChange={toggleEnabled}
                                    id={props.id + "-toggle-enabled-switch"}
                                  />
                                }
                                label="Enabled"
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </Grid>
                        )}
                      {!props.dragging && (
                        <Grid item xs="auto">
                          <ProposalSectionStylePopover
                            proposal_section={props.proposal_section}
                            {...ProposalSectionsByType[
                              props.proposal_section.section_type
                            ].component_props}
                          />
                        </Grid>
                      )}
                      {!props.dragging &&
                        props.proposal_section.section_type !==
                          "scope_of_services" && (
                          <Grid item xs="auto">
                            <IconButton onClick={() => setDeleting(true)}>
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                            {deleting && (
                              <DeleteProposalSectionDialog
                                open={deleting}
                                handleCancel={() => setDeleting(false)}
                                proposal_section={props.proposal_section}
                              />
                            )}
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {props.proposal_section.enabled && !props.dragging && (
                <Grid item xs={12} container justifyContent={"space-between"}>
                  {!(
                    ProposalSectionsByType[props.proposal_section.section_type]
                      .component_props?.disableHeading ||
                    ProposalSectionsByType[props.proposal_section.section_type]
                      .component_props?.disableHeadingDisplay
                  ) &&
                    props.proposal_section.style?.show_heading && (
                      <>
                        <Grid
                          item
                          xs={12}
                          container
                          spacing={1}
                          alignItems={"center"}
                          justifyContent={
                            props.proposal_section.style?.heading_text_align ||
                            DefaultStyles.textAlign
                          }
                        >
                          <Grid item xs="auto">
                            <ProposalSectionHeading
                              proposal_section={props.proposal_section}
                            />
                          </Grid>
                        </Grid>
                        {props.proposal_section.style?.heading_underline && (
                          <Divider
                            sx={{
                              borderColor:
                                props.proposal_section.style
                                  ?.heading_font_color ||
                                DefaultStyles.fontColor,
                              "&:before": {
                                borderTop: "solid 2px",
                                borderColor:
                                  props.proposal_section.style
                                    ?.heading_font_color ||
                                  DefaultStyles.fontColor,
                              },
                              "&:after": {
                                borderTop: "solid 2px",
                                borderColor:
                                  props.proposal_section.style
                                    ?.heading_font_color ||
                                  DefaultStyles.fontColor,
                              },
                              width: "100%",
                            }}
                          ></Divider>
                        )}
                      </>
                    )}
                  <Grid item xs={12}>
                    {Component && (
                      <Component
                        proposal_section={props.proposal_section}
                        {...ProposalSectionsByType[
                          props.proposal_section.section_type
                        ].component_props}
                        profileSettings={props.profileSettings}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        );
      }}
    </Draggable>
  );
}

const ProposalSectionHeading = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const [updatingHeading, setUpdatingHeading] = React.useState(false);

  const [headingValue, setHeadingValue] = React.useState(
    props.proposal_section.style?.heading
  );
  React.useEffect(() => {
    setHeadingValue(props.proposal_section.style?.heading);
  }, [props.proposal_section.style?.heading]);

  const updateHeading = (e) => {
    setHeadingValue(e.target.value);
    updateProposalSectionStyle(props.proposal_section.uuid, {
      heading: e.target.value,
    });
    setUpdatingHeading(false);
  };

  return (
    <>
      {updatingHeading ? (
        <TextField
          label="Heading"
          value={headingValue}
          onChange={(e) => setHeadingValue(e.target.value)}
          onBlur={updateHeading}
          sx={{
            ml: "1rem",

            fontSize:
              props.proposal_section.style?.heading_font_size ||
              DefaultStyles.headingFontSize,
            color:
              props.proposal_section.style?.heading_font_color ||
              DefaultStyles.fontColor,
            fontWeight: "bold",
            fontFamily:
              props.proposal_section.style?.heading_font ||
              DefaultStyles.fontFamily,
          }}
          size="small"
          id={props.id + "-heading-field"}
          autoFocus
        />
      ) : headingValue && headingValue.replace(" ", "") ? (
        <Typography
          onClick={() => setUpdatingHeading(true)}
          sx={{
            pl: ".25rem",

            fontSize:
              props.proposal_section.style?.heading_font_size ||
              DefaultStyles.headingFontSize,
            color:
              props.proposal_section.style?.heading_font_color ||
              DefaultStyles.fontColor,
            fontFamily:
              props.proposal_section.style?.heading_font ||
              DefaultStyles.fontFamily,
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {headingValue}
        </Typography>
      ) : (
        <Typography
          onClick={() => setUpdatingHeading(true)}
          sx={{
            pl: ".25rem",
            fontSize: ".75rem",
            color: "info.light",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Click to Add Heading
        </Typography>
      )}
    </>
  );
};

const DeleteProposalSectionDialog = (props) => {
  const [deleteProposalSection] = useAccountState((state) => [
    state.deleteProposalSection,
  ]);

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Proposal Section</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete{" "}
          {ProposalSectionsByType[props.proposal_section.section_type].label}
          {props.proposal_section.style?.heading &&
            ` - ${props.proposal_section.style?.heading}`}
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteProposalSection(props.proposal_section.uuid);
            props.handleCancel();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddProposalSectionDialog = (props) => {
  const [addProposalSection] = useAccountState((state) => [
    state.addProposalSection,
  ]);

  const [sectionType, setSectionType] = React.useState(undefined);
  const handleChange = (section_type) => {
    setSectionType(section_type);
  };

  const handleCancel = () => {
    setSectionType(undefined);
    props.handleCancel();
  };

  const handleSubmit = () => {
    addProposalSection({
      section_type: sectionType,
      order: props.order,
    });
    handleCancel();
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullScreen={fullScreen}
    >
      <DialogTitle>Add Proposal Section</DialogTitle>
      <DialogContent dividers>
        <Grid contianer width={fullScreen ? "100%" : 300}>
          {ProposalSectionsLibrary.map((section) => (
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: "info.light", pl: ".25rem" }}>
                  <Typography>{section.label}</Typography>
                </Box>
              </Grid>
              {section.sections.map((section_type) => (
                <Grid item xs="auto" container alignItems={"center"}>
                  <Grid item xs="auto">
                    <ProposalSectionPreview
                      value={sectionType}
                      section_type={section_type}
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={!sectionType}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ProposalSectionPreview = (props) => {
  const Component =
    ProposalSectionsByType[props.section_type].preview_component;

  const selected = () => {
    return props.value === props.section_type;
  };

  const handleChange = () => {
    props.handleChange(props.section_type);
  };

  return Component ? (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography sx={{ fontWeight: "bold", fontSize: ".75rem" }}>
            {ProposalSectionsByType[props.section_type].label}
          </Typography>
          <Typography sx={{ fontSize: ".65rem" }}>
            {ProposalSectionsByType[props.section_type].description}
          </Typography>
        </React.Fragment>
      }
    >
      <Box>
        <Box
          sx={{
            width: 75,
            height: 75,
            borderStyle: "solid",
            borderColor: !selected() ? "info.light" : "primary.main",
            p: ".25rem",
            borderWidth: !selected() ? "1px" : "2px",
            borderRadius: "5px",
            m: ".25rem",
            cursor: "pointer",
          }}
          onClick={handleChange}
        >
          <Component />
        </Box>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Typography
            variant="overline"
            width={"100%"}
            sx={{
              fontSize: ".55rem",
              textAlign: "center",
              width: "100%",
              m: "auto",
            }}
          >
            {ProposalSectionsByType[props.section_type].label}
          </Typography>
        </Box>
      </Box>
    </HtmlTooltip>
  ) : null;
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const ColorPickerDialog = (props) => {
  const [hexColor, setHexColor] = React.useState("#efa00b");
  const [hexColorInput, setHexColorInput] = React.useState("#efa00b");

  const re = /[0-9A-Fa-f]{6}/g;

  const handlePickerChange = (hex_color) => {
    setHexColor(hex_color);
    setHexColorInput(hex_color);
  };

  const handleChange = (e) => {
    setHexColorInput(e.target.value);
    if (re.test(e.target.value)) {
      setHexColor(e.target.value);
    }
  };

  const handleBlur = (e) => {
    if (re.test(e.target.value)) {
      setHexColor(e.target.value);
    }
  };

  const handleSubmit = () => {
    props.handleSubmit(hexColor);
    props.handleCancel();
  };

  const handleEyeDropper = async () => {
    if ("EyeDropper" in window) {
      try {
        const eyeDropper = new window.EyeDropper();
        const result = await eyeDropper.open();
        setHexColor(result.sRGBHex);
        setHexColorInput(result.sRGBHex);
      } catch (e) {
        console.error("Eye Dropper API failed:", e);
      }
    } else {
      alert("Eye Dropper API is not supported in your browser.");
    }
  };

  return (
    <Dialog onClose={props.handleCancel} open={props.open}>
      <DialogTitle>
        {props.title ? props.title : "Add Color To Palette"}
      </DialogTitle>
      <DialogContent>
        <HexColorPicker color={hexColor} onChange={handlePickerChange} />
        <TextField
          value={hexColorInput}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{ mt: "1rem", width: "200px" }}
          label="HEX Code"
          id="hex-code-input"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleEyeDropper}>
                  <ColorizeOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} id="cancel-color-picker-button">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          id="set-color-button"
        >
          Set Color
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SettingSwitch = (props) => {
  const toggleChecked = () => {
    props.updateSwitch(props.name, !props.value);
  };

  return (
    <Grid container alignItems="center" spacing={0.25}>
      <Grid item xs="auto">
        <FormControlLabel
          sx={props.sx}
          control={
            <Switch
              checked={props.value}
              onChange={toggleChecked}
              disabled={props.disabled}
              id={props.name + "-switch"}
            />
          }
          label={props.label}
          labelPlacement={props.labelPlacement ? props.labelPlacement : "start"}
        />
      </Grid>

      {props.tooltip ? (
        <Grid item xs="auto">
          <Tooltip title={props.tooltip}>
            <InfoOutlinedIcon fontSize="small" color="info" />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

const PublishProposalDialog = (props) => {
  const [
    contract_sections,
    event,
    eventHasAmendments,
    eventItemPrice,
    eventItemsBelowSuggested,
    eventPrice,
    eventPriceAmended,
    eventPriceSubtotal,
    eventPriceSubtotalAmended,
    flowerbuddyFee,
    flowerbuddyFeeCost,
    formatCurrency,
    getEventItemAmendments,
    getEventItemsWithAmendments,
    lockEventItemPrices,
    proposal,
    suggestedEventItemPrice,
    updateEvent,
    updateProposal,
  ] = useAccountState((state) => [
    state.contract_sections,
    state.event,
    state.eventHasAmendments,
    state.eventItemPrice,
    state.eventItemsBelowSuggested,
    state.eventPrice,
    state.eventPriceAmended,
    state.eventPriceSubtotal,
    state.eventPriceSubtotalAmended,
    state.flowerbuddyFee,
    state.flowerbuddyFeeCost,
    state.formatCurrency,
    state.getEventItemAmendments,
    state.getEventItemsWithAmendments,
    state.lockEventItemPrices,
    state.proposal,
    state.suggestedEventItemPrice,
    state.updateEvent,
    state.updateProposal,
  ]);

  const handlePublish = () => {
    if (event.status === "BO") {
      updateEvent({
        locked: true,
      });
      updateProposal({
        amendments_published: true,
      });
    } else {
      lockEventItemPrices();
      if (event.status === "IN") {
        updateEvent({
          locked: true,
          status: "PR",
        });
      } else {
        updateEvent({
          locked: true,
        });
      }
      if (!proposal.expiration_date && proposal.expires) {
        var date = new Date();
        date.setDate(date.getDate() + proposal.default_expiration_days);
        var date_str = date.toISOString();
        updateProposal({
          expiration_date: date_str,
          published: true,
        });
      } else {
        updateProposal({
          published: true,
        });
      }
    }
    props.copyProposalLink();
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        Are you sure you want to publish the proposal{" "}
        {eventHasAmendments() && "amendments"} for {event.name}?
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <CopyLinkDialogLine>
            <LockOutlinedIcon />
            <Typography>
              Event prices and quantities will be locked to prevent accidental
              changes. To unlock, use the lock icon in the upper right corner.
            </Typography>
          </CopyLinkDialogLine>
          {!event.flowerbuddy_fee_paid && flowerbuddyFeeCost() > 0 ? (
            <CopyLinkDialogLine>
              <TaskOutlinedIcon />
              <Typography>
                When your client signs, you will be charged a{" "}
                {flowerbuddyFee() * 100}% FlowerBuddy fee equal to{" "}
                {formatCurrency(flowerbuddyFeeCost())} or{" "}
                <Link href="/account?active_section=plan_selection">
                  upgrade to an unlimited plan here.
                </Link>
              </Typography>
            </CopyLinkDialogLine>
          ) : null}
          {eventHasAmendments() && (
            <>
              <CopyLinkDialogLine>
                <InfoOutlinedIcon color="info" />
                <Typography>
                  This event contains amendments. Publishing will allow your
                  clients to view the amendments and sign a new contract.
                </Typography>
              </CopyLinkDialogLine>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "solid 1px",
                    borderColor: "info.main",
                    borderRadius: "5px",
                    pb: ".25rem",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Amendment</TableCell>
                      </TableRow>
                    </TableHead>
                    {getEventItemsWithAmendments()
                      .filter((ei) => ei.event_item_type !== "TA")
                      .sort(sort_by_order)
                      .map((ei) => (
                        <TableRow>
                          <TableCell>{ei.name}</TableCell>
                          <TableCell>
                            <Grid container>
                              {getEventItemAmendments(ei).map((amendment) => (
                                <Grid item xs={12}>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Typography>
                                      {amendment.field}:{" "}
                                      {amendment.contracted_value}
                                    </Typography>
                                    <ArrowRightAltOutlinedIcon />
                                    <Typography>
                                      {amendment.amended_value}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              ))}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    {getEventItemsWithAmendments()
                      .filter((ei) => ei.event_item_type === "TA")
                      .sort(sortByName)
                      .map((ei) => (
                        <TableRow>
                          <TableCell>{ei.name}</TableCell>
                          <TableCell>
                            <Grid container>
                              {getEventItemAmendments(ei).map((amendment) => (
                                <Grid item xs={12}>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Typography>
                                      {amendment.field}:{" "}
                                      {amendment.contracted_value}
                                    </Typography>
                                    <ArrowRightAltOutlinedIcon />
                                    <Typography>
                                      {amendment.amended_value}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              ))}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    {eventPriceSubtotalAmended() && (
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Subtotal
                        </TableCell>
                        <TableCell>
                          <Grid container>
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Typography>
                                  {formatCurrency(eventPriceSubtotal(true))}
                                </Typography>
                                <ArrowRightAltOutlinedIcon />
                                <Typography>
                                  {formatCurrency(eventPriceSubtotal(false))}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {eventPriceAmended() && (
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                        <TableCell>
                          <Grid container>
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Typography>
                                  {formatCurrency(eventPrice(true))}
                                </Typography>
                                <ArrowRightAltOutlinedIcon />
                                <Typography>
                                  {formatCurrency(eventPrice(false))}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </Box>
              </Grid>
            </>
          )}
          {eventItemsBelowSuggested().filter(
            (ei) => ei.event_item_type !== "TA"
          ).length > 0 && (
            <>
              <CopyLinkDialogLine>
                <WarningAmberOutlinedIcon color="error" />
                <Typography>
                  Some recipe prices are set below their suggested retial price.
                </Typography>
              </CopyLinkDialogLine>
              <Grid item xs={12}>
                <UnderPricedEventItemTable />
              </Grid>
            </>
          )}
          {eventItemsBelowSuggested().filter(
            (ei) => ei.event_item_type === "TA"
          ).length > 0 && (
            <>
              <CopyLinkDialogLine>
                <WarningAmberOutlinedIcon color="error" />
                <Typography>
                  Some taxe prices are set below their calculated price.
                </Typography>
              </CopyLinkDialogLine>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "solid 1px",
                    borderColor: "error.main",
                    borderRadius: "5px",
                    pb: ".25rem",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tax</TableCell>
                        <TableCell sx={{ color: "error.main" }}>
                          Current Price
                        </TableCell>
                        <TableCell>Suggested Price</TableCell>
                      </TableRow>
                    </TableHead>
                    {eventItemsBelowSuggested()
                      .filter((ei) => ei.event_item_type === "TA")
                      .sort(sortByName)
                      .map((ei) => (
                        <TableRow>
                          <TableCell>{ei.name}</TableCell>
                          <TableCell>
                            {formatCurrency(eventItemPrice(ei))}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(suggestedEventItemPrice(ei))}
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                </Box>
              </Grid>
            </>
          )}
          {contract_sections.length === 0 && (
            <CopyLinkDialogLine>
              <WarningAmberOutlinedIcon color="error" />
              <Typography>
                No contract has been added to this proposal. Add a contract via
                the Details tab.
              </Typography>
            </CopyLinkDialogLine>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        {eventItemsBelowSuggested().length > 0 && (
          <Button variant="outlined" color="error" onClick={handlePublish}>
            Ignore Errors and Publish
          </Button>
        )}
        {eventItemsBelowSuggested().length === 0 && (
          <Button variant="contained" color="secondary" onClick={handlePublish}>
            Publish and Copy Link
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const UnderPricedEventItemTable = (props) => {
  const [
    eventItemPrice,
    eventItemsBelowSuggested,
    formatCurrency,
    getEventItemByUuid,
    suggestedEventItemPrice,
  ] = useAccountState((state) => [
    state.eventItemPrice,
    state.eventItemsBelowSuggested,
    state.formatCurrency,
    state.getEventItemByUuid,
    state.suggestedEventItemPrice,
  ]);
  return (
    <Box
      sx={{
        border: "solid 1px",
        borderColor: "error.main",
        borderRadius: "5px",
        pb: ".25rem",
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Current Price</TableCell>
            <TableCell>Suggested Price</TableCell>
          </TableRow>
        </TableHead>
        {sortEventItemsAndSubItems(
          eventItemsBelowSuggested().filter((ei) => ei.event_item_type !== "TA")
        ).map((ei) => (
          <TableRow>
            <TableCell>
              {ei.event_item_group &&
                `${getEventItemByUuid(ei.event_item_group).name} > `}
              {ei.name}
            </TableCell>
            <TableCell sx={{ color: "error.main" }}>
              {formatCurrency(eventItemPrice(ei))}
            </TableCell>
            <TableCell>{formatCurrency(suggestedEventItemPrice(ei))}</TableCell>
          </TableRow>
        ))}
      </Table>
    </Box>
  );
};

const CopyLinkDialogLine = (props) => {
  return (
    <Grid item xs={12} container alignItems="center" spacing={1}>
      <Grid item xs={1}>
        {props.children[0]}
      </Grid>
      <Grid item xs={11}>
        {props.children[1]}
      </Grid>
    </Grid>
  );
};

const ProposalTextSetting = (props) => {
  const [value, setValue] = React.useState(props.value);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    props.updateProposal(props.id, value);
  };

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <TextField
      label={props.label}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="outlined"
      fullWidth
      multiline={props.multiline}
      rows={props.rows}
      id={props.id}
      size="small"
    />
  );
};

export const ProposalPdf = (props) => {
  const [viewing, setViewing] = React.useState(false);

  const {
    ref,
    width = 300,
    height = 1,
  } = useResizeObserver({ box: "border-box" });
  return props.proposal.pdf_url ? (
    <>
      <Grid container justifyContent={"center"} spacing={1}>
        <Grid item xs={12} display="flex">
          <Box ref={ref} sx={{ width: "75%", m: "auto" }}>
            <Box
              sx={{ boxShadow: 10, "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setViewing(true);
              }}
            >
              <Document file={props.proposal.pdf_url}>
                <Page
                  pageNumber={1}
                  width={width}
                  renderAnnotationLayer={false}
                />
              </Document>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <Grid item xs="auto">
            <Button
              sx={{ color: props.proposal.font_color }}
              onClick={() => {
                setViewing(true);
              }}
              id="view-design-document-button"
            >
              View Design Document
            </Button>
          </Grid>
          {!props.view_only ? (
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  props.updateProposalSection("pdf_url", null);
                }}
                id="remove-design-document-button"
              >
                <DeleteOutlineOutlinedIcon /> Remove Document
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <PdfViewerDialog
        title="Design Document"
        pdf_url={props.proposal.pdf_url}
        open={viewing}
        handleCancel={() => {
          setViewing(false);
        }}
      />
    </>
  ) : !props.view_only ? (
    <PDFUploader
      proposal={props.proposal}
      updateProposalSection={props.updateProposalSection}
    />
  ) : null;
};

export const RecipeProposalSectionView = (props) => {
  const [proposal, updateRecipeProposalSection] = useAccountState((state) => [
    state.proposal,
    state.updateRecipeProposalSection,
  ]);

  const [description, setDescription] = React.useState(
    props.recipe_proposal_section.description
  );

  React.useEffect(() => {
    setDescription(props.recipe_proposal_section.description);
  }, [props.recipe_proposal_section.description]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDescriptionBlur = (e) => {
    updateRecipeProposalSection({
      ...props.recipe_proposal_section,
      description: description,
    });
  };
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            mt: "1.5rem",
            fontFamily:
              props.proposal_section.style?.font || DefaultStyles.fontFamily,
            color:
              props.proposal_section.style?.font_color ||
              DefaultStyles.fontColor,
            fontWeight: "bold",
          }}
        >
          <RecipeProposalSectionName
            recipe_proposal_section={props.recipe_proposal_section}
            typographySx={{
              fontFamily:
                props.proposal_section.style?.font || DefaultStyles.fontFamily,
              color:
                props.proposal_section.style?.font_color ||
                DefaultStyles.fontColor,
              fontWeight: "bold",
            }}
            disableEditButton
            autoFocus
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.view_only ? (
          <Typography
            sx={{
              fontFamily:
                props.proposal_section.style?.font || DefaultStyles.fontFamily,
              color:
                props.proposal_section.style?.font_color ||
                DefaultStyles.fontColor,
              lineHeight: "1",
            }}
          >
            {props.recipe_proposal_section.description}
          </Typography>
        ) : (
          <RecipeProposalSectionNotesEditor
            recipe_proposal_section={props.recipe_proposal_section}
            proposal_section={props.proposal_section}
          />
        )}
      </Grid>
      <Divider
        sx={{
          width: "100%",
          mt: ".5rem",
          borderColor: hexToRGB(
            props.proposal_section.style?.font_color || DefaultStyles.fontColor,
            0.25
          ),
        }}
      />
      <Divider
        sx={{
          width: "100%",
          mt: ".125rem",
          mb: "1rem",
          borderColor: hexToRGB(
            props.proposal_section.style?.font_color || DefaultStyles.fontColor,
            0.25
          ),
        }}
      />
    </Grid>
  );
};

const RecipeProposalSectionNotesEditor = (props) => {
  const [proposal, updateRecipeProposalSection] = useAccountState((state) => [
    state.proposal,
    state.updateRecipeProposalSection,
  ]);

  const [editing, setEditing] = React.useState(false);

  const [description, setDescription] = React.useState(
    props.recipe_proposal_section.description
  );

  React.useEffect(() => {
    setDescription(props.recipe_proposal_section.description);
  }, [props.recipe_proposal_section.description]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDescriptionBlur = (e) => {
    updateRecipeProposalSection({
      ...props.recipe_proposal_section,
      description: description,
    });
    setEditing(false);
  };

  return editing ? (
    <TextField
      value={description}
      onChange={handleDescriptionChange}
      label="Description"
      onBlur={handleDescriptionBlur}
      fullWidth
      size="small"
      multiline
      inputProps={{
        sx: {
          fontFamily:
            props.proposal_section.style?.font || DefaultStyles.fontFamily,
          color:
            props.proposal_section.style?.font_color || DefaultStyles.fontColor,
        },
      }}
    />
  ) : description ? (
    <Typography
      sx={{
        fontFamily:
          props.proposal_section.style?.font || DefaultStyles.fontFamily,
        color:
          props.proposal_section.style?.font_color || DefaultStyles.fontColor,
      }}
      onClick={() => setEditing(true)}
    >
      {props.recipe_proposal_section.description}
    </Typography>
  ) : (
    <Typography
      sx={{
        color: "info.light",
      }}
      onClick={() => setEditing(true)}
    >
      Click to add a description
    </Typography>
  );
};

export const GridControl = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);
  const decrease = () => {
    if (
      ((props.proposal_section.style &&
        props.proposal_section.style[props.id]) ||
        DefaultStyles[props.defaultKey]) > props.min
    ) {
      updateProposalSectionStyle(props.proposal_section.uuid, {
        [props.id]:
          ((props.proposal_section.style &&
            props.proposal_section.style[props.id]) ||
            DefaultStyles[props.defaultKey]) - (props.step || 1),
      });
    }
  };

  const increase = () => {
    if (
      ((props.proposal_section.style &&
        props.proposal_section.style[props.id]) ||
        DefaultStyles[props.defaultKey]) < props.max
    ) {
      updateProposalSectionStyle(props.proposal_section.uuid, {
        [props.id]:
          ((props.proposal_section.style &&
            props.proposal_section.style[props.id]) ||
            DefaultStyles[props.defaultKey]) + (props.step || 1),
      });
    }
  };
  return (
    <TextField
      sx={{
        width: props.width || "8rem",
        "& .MuiOutlinedInput-root": {
          paddingRight: "0!important",
          paddingLeft: "0!important",
        },
      }}
      size="small"
      label={props.label}
      value={
        (props.proposal_section.style &&
          props.proposal_section.style[props.id]) ||
        DefaultStyles[props.defaultKey]
      }
      inputProps={{
        style: { textAlign: "center" },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              sx={{ pr: 0 }}
              onClick={decrease}
              disabled={
                ((props.proposal_section.style &&
                  props.proposal_section.style[props.id]) ||
                  DefaultStyles[props.defaultKey]) < props.min
              }
            >
              <RemoveCircleOutlineOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ pl: 0 }}
              onClick={increase}
              disabled={
                ((props.proposal_section.style &&
                  props.proposal_section.style[props.id]) ||
                  DefaultStyles[props.defaultKey]) > props.max
              }
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SliderControl = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const handleChange = (e, value) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      [props.id]: value,
    });
  };

  const [value, setValue] = React.useState(
    (props.proposal_section.style && props.proposal_section.style[props.id]) ||
      DefaultStyles[props.defaultKey]
  );

  const handleUpdate = (e) => {
    setValue(e.target.value);
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs="auto">
        <Typography>{props.label}</Typography>
      </Grid>
      <Grid item xs={"auto"}>
        <Slider
          value={value}
          onChange={handleUpdate}
          onChangeCommitted={handleChange}
          min={props.min}
          max={props.max}
          step={props.step}
          valueLabelDisplay="off"
          size="small"
          sx={{ minWidth: "100px" }}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(Proposal);
