import {
  AttachmentsPreview,
  AttachmentsView,
  ProposalAttachments,
} from "../components/ProposalSections/Attachments";
import {
  ColorPalette,
  ColorPalettePreview,
  ColorPaletteView,
} from "../components/ProposalSections/ColorPalette";
import {
  DesignDocument,
  DesignDocumentPreview,
  DesignDocumentView,
} from "../components/ProposalSections/DesignDocument";
import {
  ImageLeftWithTextPreview,
  ImageRightWithTextPreview,
  ImagesV1,
  ImagesV1View,
} from "../components/ProposalSections/ImagesV1";
import {
  ImagesV2,
  ImagesV2Preview,
  ImagesV2View,
} from "../components/ProposalSections/ImagesV2";
import {
  ImagesV3,
  ImagesV3Preview,
  ImagesV3View,
} from "../components/ProposalSections/ImagesV3";
import {
  ImagesV4,
  ImagesV4Preview,
  ImagesV4View,
} from "../components/ProposalSections/ImagesV4";
import {
  Links,
  LinksPreview,
  LinksView,
} from "../components/ProposalSections/Links";
import {
  LocationsAndTimeline,
  LocationsAndTimelinePreview,
  LocationsAndTimelineView,
} from "../components/ProposalSections/LocationsAndTimeline";
import {
  Notes,
  NotesPreview,
  NotesView,
} from "../components/ProposalSections/Notes";
import {
  ProposalItems,
  ProposalItemsPreview,
  ProposalItemsView,
} from "../components/ProposalSections/ProposalItems";
import {
  ScopeOfServices,
  ScopeOfServicesView,
} from "../components/ProposalSections/ScopeOfServices";
import {
  TitlePage1,
  TitlePage1Preview,
  TitlePage1View,
} from "../components/ProposalSections/TitlePage1";
import {
  TitlePage2,
  TitlePage2Preview,
  TitlePage2View,
} from "../components/ProposalSections/TitlePage2";

export const proposalWarnings = (proposal, template, event_has_amendments) => {
  var warnings = [];
  if (!proposal.published && !template) {
    warnings.push(
      "This proposal is not published and cannot be seen by clients. Use the switch above to publish the proposal."
    );
  }
  if (!proposal.amendments_published && event_has_amendments) {
    warnings.push(
      "Amendments for this event are not published and cannot be seen by clients. Use the switch above to publish amendments."
    );
  }
  if (
    proposal.expiration_date &&
    proposal.expiration_date < new Date() &&
    proposal.expires &&
    !template
  ) {
    warnings.push(
      "The expiration date for this proposal has passed and therefore it cannot be seen by clients. Change the exiration date above or use the switch to disable expiration."
    );
  }
  return warnings;
};

export const ProposalSectionsByType = {
  title_page_1: {
    label: "Logo & Event Name",
    description: "A centered image with the event name and date.",
    section_component: TitlePage1,
    preview_component: TitlePage1Preview,
    view_component: TitlePage1View,
    component_props: {
      fontFamily: true,
      fontColor: true,
      disableHeading: true,
    },
  },
  title_page_2: {
    label: "Hero Image",
    description:
      "A full-width image with text overlay and translucent color bar.",
    section_component: TitlePage2,
    preview_component: TitlePage2Preview,
    view_component: TitlePage2View,
    component_props: {
      fontFamily: true,
      fontColor: true,
      fontSize: true,
      disableHeading: true,
      disableTopMargin: true,
      disableHeadingAlign: true,
      disableHeadingUnderline: true,
      bodyName: "Heading",
      text2: true,
      bodyName2: "Subheading",
      fontFamily2: true,
      fontColor2: true,
      fontSize2: true,
      text3: true,
      bodyName3: "Detail",
      fontFamily3: true,
      fontColor3: true,
      fontSize3: true,
      misc: true,
      elementColor: true,
    },
  },
  design_document: {
    label: "Design Doc",
    description: "A clickable PDF document with a large preview.",
    section_component: DesignDocument,
    preview_component: DesignDocumentPreview,
    view_component: DesignDocumentView,
    component_props: {
      disableBodyControls: true,
    },
  },
  attachments: {
    label: "Attachments",
    description: "A list of files that can be downloaded.",
    section_component: ProposalAttachments,
    preview_component: AttachmentsPreview,
    view_component: AttachmentsView,
    component_props: {
      itemsAlign: true,
    },
  },
  color_palette: {
    label: "Color Palette",
    description: "A list of color swatches.",
    section_component: ColorPalette,
    preview_component: ColorPalettePreview,
    view_component: ColorPaletteView,
    component_props: {
      itemsAlign: true,
      itemShape: true,
    },
  },
  items: {
    label: "Flowers & Decor",
    description:
      "A list of flowers and hard good images. Hover over an item to see the item name.",
    section_component: ProposalItems,
    preview_component: ProposalItemsPreview,
    view_component: ProposalItemsView,
    component_props: {
      itemsAlign: true,
    },
  },
  images_v1: {
    label: "Images (Legacy Layout)",
    section_component: ImagesV1,
    component_props: {
      InspirationImgListItemProps: [
        { position: 1, cols: 2, rows: 2 },
        { position: 2, cols: 2, rows: 2 },
        { position: 3, cols: 2, rows: 2 },
        { position: 4, cols: 3, rows: 2 },
        { position: 5, cols: 3, rows: 2 },
        { position: 6, cols: 2, rows: 2 },
        { position: 7, cols: 2, rows: 2 },
        { position: 8, cols: 2, rows: 2 },
      ],
      disableBodyControls: true,
    },
    view_component: ImagesV1View,
  },
  images_v2: {
    label: "Images (Masonry)",
    description: "A grid of images with a masonry layout (column based).",
    section_component: ImagesV2,
    preview_component: ImagesV2Preview,
    view_component: ImagesV2View,
    component_props: {
      disableBodyControls: true,
    },
  },
  images_v3: {
    label: "Images (Brick)",
    description: "A grid of images with a brick layout (row based).",
    section_component: ImagesV3,
    preview_component: ImagesV3Preview,
    view_component: ImagesV3View,
    component_props: {
      itemsAlign: true,
    },
  },
  images_v4: {
    label: "Images (Grid)",
    description: "A grid of images with a fixed grid layout.",
    section_component: ImagesV4,
    preview_component: ImagesV4Preview,
    view_component: ImagesV4View,
    component_props: {
      itemsAlign: true,
    },
  },
  scope_of_services: {
    label: "Scope of Services",
    section_component: ScopeOfServices,
    view_component: ScopeOfServicesView,
    component_props: {
      fontFamily: true,
      fontColor: true,
    },
  },
  locations_and_timeline: {
    label: "Timeline",
    description:
      "A timeline list of locations and times linked to the event details.",
    section_component: LocationsAndTimeline,
    preview_component: LocationsAndTimelinePreview,
    view_component: LocationsAndTimelineView,
    component_props: {
      fontFamily: true,
      fontColor: true,
      itemsAlign: true,
    },
  },
  additional_notes: {
    label: "Notes",
    description: "A freeform rich text field.",
    section_component: Notes,
    preview_component: NotesPreview,
    view_component: NotesView,
    component_props: {
      fontFamily: true,
      fontColor: true,
      fontSize: true,
      textAlign: true,
    },
  },
  image_left_with_text: {
    label: "Image Left",
    description: "An image on the left with text on the right.",
    section_component: ImagesV1,
    component_props: {
      InspirationImgListItemProps: [{ position: 1, cols: 6, rows: 6 }],
      fontFamily: true,
      fontColor: true,
      fontSize: true,
      textAlign: true,
    },
    preview_component: ImageLeftWithTextPreview,
    view_component: ImagesV1View,
  },
  image_right_with_text: {
    label: "Image Right",
    description: "An image on the right with text on the left.",
    section_component: ImagesV1,
    component_props: {
      InspirationImgListItemProps: [{ position: 1, cols: 6, rows: 6 }],
      fontFamily: true,
      fontColor: true,
      fontSize: true,
      textAlign: true,
    },
    preview_component: ImageRightWithTextPreview,
    view_component: ImagesV1View,
  },
  links: {
    label: "Links",
    description:
      "A list of social media logo icons that link to your profiles.",
    section_component: Links,
    preview_component: LinksPreview,
    view_component: LinksView,
    component_props: {
      disableBodyControls: true,
    },
    disablePrint: true,
  },
};

export const ProposalSectionsLibrary = [
  {
    label: "Title Pages",
    sections: ["title_page_1", "title_page_2"],
  },
  {
    label: "Documents",
    sections: ["design_document", "attachments"],
  },
  {
    label: "Design Elements",
    sections: ["items", "color_palette"],
  },
  {
    label: "Images",
    sections: [
      "images_v2",
      "images_v3",
      "images_v4",
      "image_left_with_text",
      "image_right_with_text",
    ],
  },
  {
    label: "Event Details",
    sections: ["locations_and_timeline"],
  },
  {
    label: "Notes",
    sections: ["additional_notes", "links"],
  },
];

export const DefaultStyles = {
  fontColor: "#000000",
  fontFamily: "Raleway",
  fontSize: "1rem",
  textAlign: "left",
  elementColor: "#000000",
  itemsAlign: "left",
  imageCols: 3,
  imageGap: 8,
  dateFormat: "M/d/yyyy",
  itemShape: "square",
  rowHeight: 200,
  spacing: 1,
  imageSize: 7,
  recipeImgSize: 50,
};
