import React from "react";
import { withRouter } from "react-router-dom";

import { sort } from "fast-sort";
import AsciiTable from "ascii-table";

import {
  eventVersionUuidsInItemCount,
  itemCountsCost,
  itemDisplayName,
  itemPriceGroupName,
  itemUnitPluralName,
} from "../utils/item_utils";

import {
  Avatar,
  Box,
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Tooltip,
  Link,
  CircularProgress,
  Chip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";

import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  short_vendor_location_display_name,
  vendor_location_display_name,
} from "../utils/vendor_utils";
import { EditItemCostDialog } from "./RecipeItems";
import CreateItemDialog from "./CreateItemDialog.js";
import AddItemDialog from "./AddItemDialog.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ItemIcon } from "./ItemIcon";
import { useAccountState } from "../state/store";
import { track_event } from "../utils/event_tracking";
import {
  formatStringForId,
  quantityField,
  sortByDate,
  sortByName,
  sortItemCountRecipesByName,
} from "../utils/utils";
import { useContextOrAccountState } from "./AccountStateOverridesContext.js";
import { generateOrderPdfApi } from "../api/EventsApi.js";
import { LoadingButton } from "@mui/lab";
import { OrderAttachments } from "./ProposalSections/Attachments.js";

const sortItemCounts = (flowerCounts) => {
  return sort(flowerCounts).asc((fc) => itemDisplayName(fc.item));
};

const OrderItems = (props) => {
  const [event, _flowerCounts, _materialCounts] = useAccountState((state) => [
    state.event,
    state.flowerCounts,
    state.materialCounts,
  ]);

  const flowerCounts = () =>
    props.flowerCounts ? props.flowerCounts : _flowerCounts();
  const materialCounts = () =>
    props.materialCounts ? props.materialCounts : _materialCounts();
  const addEventAttachmentsInState = useContextOrAccountState(
    "addEventAttachmentsInState"
  );

  const getUniqueVendorLocations = (flower_counts, material_counts) => {
    // var flower_counts = flower_counts.map((fc)=>({...fc, item_price:fc.item_price ? fc.item_price : }))
    var vendor_locations = [
      ...flower_counts.map((fc) => fc.item_price.vendor_location),
      ...material_counts.map((mc) => mc.item_price.vendor_location),
    ];
    var vendor_location_uuids = Array.from(
      new Set(vendor_locations.map((vl) => (vl ? vl.uuid : null)))
    );
    var unique_vendor_locations = [];
    vendor_location_uuids.forEach((vl_uuid) => {
      var idx = vendor_locations.findIndex((e) =>
        e ? e.uuid === vl_uuid : e === vl_uuid
      );
      unique_vendor_locations.push(vendor_locations[idx]);
    });
    unique_vendor_locations = sort(
      unique_vendor_locations.filter((vl) => vl)
    ).asc((vl) => vendor_location_display_name(vl));
    if (vendor_location_uuids.includes(null)) {
      unique_vendor_locations.push({
        uuid: null,
        name: "No Vendor Selected",
      });
    }
    return unique_vendor_locations;
  };

  const [loadingOrderPdf, setLoadingOrderPdf] = React.useState(false);

  const generateOrderDoc = (doc_type) => {
    setLoadingOrderPdf(true);
    generateOrderPdfApi({
      event_uuids: props.events ? props.events.map((e) => e.uuid) : event.uuid,
      doc_type: doc_type ? doc_type : "pdf",
    }).then((resp) => {
      let doc_url = resp.data.doc_url;
      let extension = doc_url.split(".").pop();
      const anchor = document.createElement("a");
      anchor.href = doc_url;
      anchor.target = "_blank";
      anchor.download = `order.${extension}`;

      // Append the anchor to the body (required for Firefox)
      document.body.appendChild(anchor);

      // Trigger the download
      anchor.click();

      // Remove the anchor from the document
      document.body.removeChild(anchor);
      addEventAttachmentsInState(resp.data.event_attachments);
      setLoadingOrderPdf(false);
    });
  };

  return (
    <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
      <Box sx={{ mb: "calc(100vh/2)" }}>
        <Grid container sx={{ width: "100%", maxWidth: "800px", m: "auto" }}>
          {flowerCounts().length > 0 || flowerCounts().length > 0 ? (
            <Paper
              sx={{
                maxWidth: "800px",
                m: "auto",
                // p: "1rem",
              }}
            >
              <Box sx={{ p: "1rem" }}>
                <Grid container spacing={4}>
                  {(props.attachments?.length > 0 ||
                    event?.event_attachments?.filter((ea) => ea.is_order)
                      .length > 0) && (
                    <Grid item xs={12}>
                      <OrderAttachments attachments={props.attachments} />
                    </Grid>
                  )}
                  <Grid item xs={12} container justifyContent={"flex-end"}>
                    <Grid item xs="auto">
                      <SaveAndDownloadButton
                        handleClick={generateOrderDoc}
                        loading={loadingOrderPdf}
                        events={props.events}
                      />
                    </Grid>
                  </Grid>
                  {getUniqueVendorLocations(
                    flowerCounts(),
                    materialCounts(true)
                  ).map((vendor_location) => {
                    let flower_counts = flowerCounts().filter((fc) =>
                      fc.item_price.vendor_location
                        ? fc.item_price.vendor_location.uuid ===
                          vendor_location.uuid
                        : fc.item_price.vendor_location === vendor_location.uuid
                    );
                    let material_counts = materialCounts(true)
                      .filter((mc) =>
                        mc.item_price.vendor_location
                          ? mc.item_price.vendor_location.uuid ===
                            vendor_location.uuid
                          : mc.item_price.vendor_location ===
                            vendor_location.uuid
                      )
                      .filter(
                        (mc) => mc.quantity - mc.quantity_from_inventory > 0
                      );
                    if (
                      flower_counts.length > 0 ||
                      material_counts.length > 0
                    ) {
                      return (
                        <VendorSection
                          key={vendor_location.uuid}
                          vendor_location={vendor_location}
                          flower_counts={flower_counts}
                          material_counts={material_counts}
                          events={props.events}
                        />
                      );
                    } else return null;
                  })}
                </Grid>
              </Box>
            </Paper>
          ) : (
            <Box
              sx={{
                borderStyle: "dotted",
                borderColor: "info.light",
                p: "2rem",
                borderRadius: "7.5px",
                m: "auto",
              }}
            >
              <Typography
                sx={{
                  color: "info.light",
                  fontWeight: "normal",
                }}
                variant="h6"
              >
                There are no items or hard goods in this event. Add them to
                recipes on the{" "}
                <Link
                  onClick={() => props.setTabValue("recipes")}
                  color="inherit"
                  sx={{
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  Recipes Tab
                </Link>
                .
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

const options = ["Save & Download PDF", "Save & Download CSV"];

const SaveAndDownloadButton = (props) => {
  const [eventPaywallLocked] = useAccountState((state) => [
    state.eventPaywallLocked,
  ]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    if (selectedIndex === 0) {
      props.handleClick("pdf");
    } else if (selectedIndex === 1) {
      props.handleClick("csv");
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        color="secondary"
        disabled={
          eventPaywallLocked() && (!props.events || props.events?.length === 0)
        }
      >
        <LoadingButton
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleClick}
          loading={props.loading}
        >
          {options[selectedIndex]}
        </LoadingButton>
        <Button color="secondary" size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    key={0}
                    selected={0 === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, 0)}
                  >
                    Save & Download PDF
                  </MenuItem>
                  <MenuItem
                    key={1}
                    selected={1 === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, 1)}
                  >
                    Save & Download CSV
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

const VendorSection = (props) => {
  const [event, eventPaywallLocked, userPlanType] = useAccountState((state) => [
    state.event,
    state.eventPaywallLocked,
    state.userPlanType,
  ]);
  const [copied, setCopied] = React.useState(false);

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const options = ["Copy Table", "Copy Plain Text"];

  const handleClick = () => {
    setCopied(true);
    if (selectedIndex === 0) {
      copyFormatted(getHtmlTable());
    } else if (selectedIndex === 1) {
      navigator.clipboard.writeText(getAsciiTable());
    }
    track_event("Order Copied", {
      event_uuid: event.uuid,
    });
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const getAsciiTable = () => {
    var output = "Item Name x Qty.\n";
    if (props.flower_counts.length > 0) {
      var table = new AsciiTable().addRowMatrix(
        sortItemCounts(props.flower_counts).map((fc) => [
          itemDisplayName(fc.item) + " x " + Math.ceil(fc.quantity),
        ])
      );
      table.removeBorder();
      output += table + "\n";
    }
    if (props.material_counts.length > 0) {
      var table = new AsciiTable().addRowMatrix(
        sortItemCounts(props.material_counts).map((mc) => [
          itemDisplayName(mc.item) + " x " + mc.quantity,
        ])
      );
      table.removeBorder();
      output += table;
    }
    return output;
  };

  const getHtmlTable = () => {
    var output = `<table border="1" cellpadding="5" cellspacing="1" style="width:50%;background-color:#ffffff;border-collapse:collapse;border:1px solid #000000">`;
    if (props.flower_counts.length > 0) {
      output += `<thead><tr><th>Item</th><th>Stem Count</th><th>Group Count</th></thead>`;
      output += `<tbody>`;
      sortItemCounts(props.flower_counts).forEach((fc) => {
        output +=
          `<tr><td nowrap>` +
          itemDisplayName(fc.item) +
          `</td><td nowrap align='left'>` +
          Math.ceil(fc.quantity) +
          " " +
          itemUnitPluralName(fc.item) +
          `</td><td nowrap align='left'>` +
          fc.num_groups_needed +
          " " +
          itemPriceGroupName(fc.num_groups_needed, fc.item_price) +
          `</td></tr>`;
      });
      output += `</tbody>`;
    }
    if (props.material_counts.length > 0) {
      output += `<thead><tr><th>Hard Good</th><th colspan='2'>Qty.</th></thead>`;
      output += `<tbody>`;
      sortItemCounts(props.material_counts).forEach((mc) => {
        output +=
          `<tr><td nowrap>` +
          itemDisplayName(mc.item) +
          `</td><td align='center' colspan='2'>` +
          mc.quantity +
          `</td></tr>`;
      });
      output += `</tbody>`;
    }
    output += `</table>`;
    return output;
  };

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      key={props.vendor_location.uuid}
    >
      <Grid item>
        <Typography variant="h5">
          {props.vendor_location.uuid
            ? short_vendor_location_display_name(props.vendor_location)
            : props.vendor_location.name}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Tooltip
          title={"This feature is enabled after the event becomes Booked"}
          disableHoverListener={
            !eventPaywallLocked() || props.events?.length > 0
          }
        >
          <ButtonGroup
            variant="contained"
            size="small"
            color="secondary"
            ref={anchorRef}
            aria-label="split button"
            disabled={
              eventPaywallLocked() &&
              (!props.events || props.events?.length === 0)
            }
            id="copy-item-order-button-group"
          >
            <Button onClick={handleClick} id="copy-item-order-button">
              {options[selectedIndex]}
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              id="copy-item-order-button-dropdown"
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Tooltip>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Snackbar
          open={copied}
          autoHideDuration={3000}
          onClose={() => {
            setCopied(false);
          }}
          message="Copied to Clipboard!"
        />
      </Grid>
      <Divider sx={{ width: "100%", mt: ".5rem" }} />
      <Grid item xs={12}>
        <CollapsibleTable
          flower_counts={props.flower_counts}
          material_counts={props.material_counts}
          events={props.events}
        />
      </Grid>
    </Grid>
  );
};

const CollapsibleTable = (props) => {
  const filterMaterialCounts = (material_counts) => {
    return material_counts.filter(
      (mc) => mc.quantity - mc.quantity_from_inventory > 0
    );
  };

  return (
    <TableContainer component={Box}>
      <Table
        size="small"
        aria-label="collapsible dense table"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        {props.flower_counts.length > 0 ? (
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell colSpan={2}>Flowers</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Group Qty</TableCell>
              <TableCell align="right">Cost</TableCell>
              <TableCell align="right">Waste</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {sortItemCounts(props.flower_counts).map((item_count, idx) => (
            <ItemRow
              key={idx}
              item_count={item_count}
              id={"flower-row-" + idx}
              item_type="FL"
              events={props.events}
            />
          ))}
          {filterMaterialCounts(props.material_counts).length <= 0 ? (
            <TotalCostRow
              total_cost={itemCountsCost([
                ...props.flower_counts,
                ...props.material_counts,
              ])}
            />
          ) : null}
        </TableBody>
        {filterMaterialCounts(props.material_counts).length > 0 ? (
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell colSpan={2}>Hard Goods</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell />
              <TableCell align="right">Cost</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {sortItemCounts(filterMaterialCounts(props.material_counts)).map(
            (material_count, idx) => (
              <ItemRow
                key={idx}
                item_count={material_count}
                id={"material-row-" + idx}
                item_type="MA"
                events={props.events}
              />
            )
          )}
          {filterMaterialCounts(props.material_counts).length > 0 ? (
            <TotalCostRow
              total_cost={itemCountsCost([
                ...props.flower_counts,
                ...props.material_counts,
              ])}
            />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TotalCostRow = (props) => {
  const formatCurrency = useAccountState((state) => state.formatCurrency);
  return (
    <React.Fragment key="TOTALCOST">
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell></TableCell>
        <TableCell component="th" scope="row"></TableCell>
        <TableCell component="th" scope="row"></TableCell>
        <TableCell align="right" colSpan={2}>
          <Typography noWrap sx={{ fontWeight: "bold" }}>
            Total Vendor Cost
          </Typography>
        </TableCell>
        <TableCell align="right">{formatCurrency(props.total_cost)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const ItemRow = (props) => {
  const isFlower = props.item_count.item.item_type === "FL";
  const item_name = isFlower ? "flower" : "hard good";
  const [formatCurrency] = useAccountState((state) => [state.formatCurrency]);

  const updateItemPrice = useContextOrAccountState("updateItemPrice");
  const swapEventItem = useContextOrAccountState("swapEventItem");

  const [open, setOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorElState] = React.useState(null);
  const [editingItemCost, setEditingItemCost] = React.useState(false);
  const [editingItemCost2, setEditingItemCost2] = React.useState(false);
  const [swappingItem, setSwappingItem] = React.useState(false);
  const [loadingSwap, setLoadingSwap] = React.useState(false);

  const setMenuAnchorEl = (e) => {
    setMenuAnchorElState(e.currentTarget);
  };

  const unsetMenuAnchorEl = () => {
    setMenuAnchorElState(null);
  };

  const updateItemCost = (data) => {
    let item_price_data = {
      ...props.item_count.item_price,
      ...data,
    };
    updateItemPrice(item_price_data, (resp) => {
      setEditingItemCost(false);
    });
  };

  const swap = (items) => {
    setLoadingSwap(true);
    swapEventItem(items[0], props.item_count.item, () => {
      setLoadingSwap(false);
    });
  };

  return (
    <React.Fragment key={props.item_count.item.uuid}>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            id={formatStringForId(
              itemDisplayName(props.item_count.item) + "-expand"
            )}
          >
            {open ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <ItemIcon width={30} height={30} item={props.item_count.item} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography>{itemDisplayName(props.item_count.item)}</Typography>
        </TableCell>
        <TableCell align="right">
          {isFlower
            ? props.item_count.quantity.toFixed(2)
            : (
                props.item_count.quantity -
                props.item_count.quantity_from_inventory
              ).toFixed(2)}
        </TableCell>
        <TableCell align="right">
          {isFlower
            ? props.item_count.num_groups_needed +
              " " +
              itemPriceGroupName(
                props.item_count.num_groups_needed,
                props.item_count.item_price
              )
            : null}
        </TableCell>
        <TableCell align="right">
          {isFlower
            ? formatCurrency(props.item_count.groups_cost)
            : formatCurrency(props.item_count.individuals_cost)}
          {props.item_count.groups_cost === 0 ? (
            <Tooltip
              title={`No price is set for this ${item_name}. Click the edit icon in the menu to set a price.`}
            >
              <WarningAmberRoundedIcon
                fontSize="inherit"
                sx={{ color: "error.main" }}
              />
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell align="right">
          {isFlower ? props.item_count.waste_individual_count.toFixed(2) : null}
        </TableCell>
        <TableCell>
          {loadingSwap && <CircularProgress size={25} />}
          {!loadingSwap && (
            <IconButton
              onClick={setMenuAnchorEl}
              id={props.item_count.item.uuid + "-menu-button"}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          )}
          <Menu
            sx={{ mt: "25px" }}
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={unsetMenuAnchorEl}
            onClick={unsetMenuAnchorEl}
          >
            <MenuItem
              onClick={() => setEditingItemCost(true)}
              id={props.item_count.item.uuid + "-edit-cost"}
            >
              <EditOutlinedIcon size="small" />
              <Typography textAlign="center" alignItems="center">
                {" Edit Cost"}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => setEditingItemCost2(true)}
              id={props.item_count.item.uuid + "-edit-cost2"}
            >
              <EditOutlinedIcon size="small" />
              <Typography textAlign="center" alignItems="center">
                {" Change Wholesaler"}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => setSwappingItem(true)}
              id={props.item_count.item.uuid + "-swap-item"}
            >
              <SwapHorizOutlinedIcon size="small" />
              <Typography textAlign="center" alignItems="center">
                {" Swap Item"}
              </Typography>
            </MenuItem>
          </Menu>
          <EditItemCostDialog
            item={{ item: props.item_count.item }}
            item_price={props.item_count.item_price}
            open={editingItemCost}
            handleCancel={() => setEditingItemCost(false)}
            handleSubmit={updateItemCost}
            disableUpdateAllEvents={props.events?.length > 0}
          />
          <EditItemCostDialog
            item={{ item: props.item_count.item }}
            item_price={props.item_count.item_price}
            open={editingItemCost2}
            handleCancel={() => setEditingItemCost2(false)}
            handleSubmit={updateItemCost}
            updatingVendor
            disableUpdateAllEvents={props.events?.length > 0}
          />
          <AddItemDialog
            open={swappingItem}
            handleCancel={() => setSwappingItem(false)}
            addItems={swap}
            filter_items={[props.item_count.item]}
            submitLabel={"Swap"}
            item_type={props.item_count.item.item_type}
            max_selectable={1}
          />
        </TableCell>
      </TableRow>
      {props.events?.length > 0 &&
        eventVersionUuidsInItemCount(props.item_count)
          .map((event_version_uuid) => {
            return props.events.find(
              (event) => event.event_version.uuid === event_version_uuid
            );
          })
          .sort(sortByDate)
          .map((event) => (
            <EventRecipesTable
              id={event.uuid}
              item_count={props.item_count}
              open={open}
              event={event}
            />
          ))}
      {(!props.events || props.events?.length === 0) && (
        <EventRecipesTable
          id={props.id}
          item_count={props.item_count}
          open={open}
        />
      )}
    </React.Fragment>
  );
};

const EventRecipesTable = (props) => {
  return (
    <TableRow>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={2}
      ></TableCell>
      <TableCell style={{ padding: 0 }} colSpan={5}>
        <Collapse in={props.open} timeout="auto" unmountOnExit>
          <Box
            sx={{
              border: "solid 1px lightgrey",
              borderRadius: "5px",
              mb: ".5rem",
            }}
            id={props.id + "-collapse"}
          >
            <Table size="small" aria-label="purchases" sx={{ p: 0 }}>
              <TableHead>
                {props.event && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Chip label={props.event.name} size="small" />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Recipe</TableCell>
                  <TableCell align="right">Recipe Qty</TableCell>
                  <TableCell align="center">Item Qty Ea.</TableCell>
                  <TableCell align="right">Item Qty Tot.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.item_count.recipes
                  .sort(sortItemCountRecipesByName)
                  .filter((recipe) =>
                    props.event
                      ? props.event.event_version.uuid ===
                        recipe.recipe.event_version
                      : true
                  )
                  .map((row) => (
                    <ItemRecipeRow key={row.recipe.uuid} row={row} />
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const ItemRecipeRow = (props) => {
  const updateRecipeItem = useContextOrAccountState("updateRecipeItem");
  const removeItem = useContextOrAccountState("removeItem");
  const [quantity, setQuantity] = React.useState(
    parseFloat(props.row.recipe_item.quantity).toFixed(2)
  );
  React.useEffect(() => {
    setQuantity(parseFloat(props.row.recipe_item.quantity).toFixed(2));
  }, [props.row.recipe_item.quantity]);
  const increaseRecipeItemQuantity = () => {
    if (props.row.recipe_item.quantity >= 1) {
      updateRecipeItem(props.row.recipe, {
        uuid: props.row.recipe_item.uuid,
        quantity: parseFloat(props.row.recipe_item.quantity) + 1,
      });
      setQuantity((parseFloat(props.row.recipe_item.quantity) + 1).toFixed(2));
    } else {
      updateRecipeItem(props.row.recipe, {
        uuid: props.row.recipe_item.uuid,
        quantity: parseFloat(props.row.recipe_item.quantity) + 0.1,
      });
      setQuantity(
        (parseFloat(props.row.recipe_item.quantity) + 0.1).toFixed(2)
      );
    }
  };

  const decreaseRecipeItemQuantity = () => {
    if (props.row.recipe_item.quantity > 1) {
      updateRecipeItem(props.row.recipe, {
        uuid: props.row.recipe_item.uuid,
        quantity: parseFloat(props.row.recipe_item.quantity) - 1,
      });
      setQuantity((parseFloat(props.row.recipe_item.quantity) - 1).toFixed(2));
    } else if (props.row.recipe_item.quantity > 0) {
      updateRecipeItem(props.row.recipe, {
        uuid: props.row.recipe_item.uuid,
        quantity: parseFloat(props.row.recipe_item.quantity) - 0.1,
      });
      setQuantity(
        (parseFloat(props.row.recipe_item.quantity) - 0.1).toFixed(2)
      );
    }
  };

  const updateRecipeItemQuantity = (e) => {
    if (e.target.value !== "" && e.target.value != ".") {
      updateRecipeItem(props.row.recipe, {
        uuid: props.row.recipe_item.uuid,
        quantity: parseFloat(e.target.value).toFixed(2),
      });
    }
  };

  const handleQuantityChange = (e) => {
    const parsedVal = parseFloat(e.target.value);
    if (
      isNaN(parsedVal) ||
      parsedVal <= 0 ||
      e.target.value[e.target.value.length - 1] == "."
    ) {
      setQuantity(e.target.value);
    } else {
      setQuantity(parseFloat(e.target.value));
      updateRecipeItemQuantity(e);
    }
  };

  return (
    <TableRow key={props.row.recipe.uuid}>
      <TableCell component="th" scope="row">
        {props.row.recipe.name}
      </TableCell>
      <TableCell align="right">{props.row.recipe_quantity}</TableCell>
      <TableCell align="center">
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item>
            <IconButton
              size="small"
              onClick={decreaseRecipeItemQuantity}
              sx={{ p: 0 }}
              id={props.row.recipe.uuid + "-decrease"}
            >
              <RemoveCircleOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid item>
            <Box display="flex">
              <TextField
                value={quantity}
                onChange={handleQuantityChange}
                inputProps={{
                  inputMode: "decimal",
                  sx: { textAlign: "center", p: "0" },
                }}
                sx={{ width: "4rem", ml: "auto", mr: "auto" }}
                id={props.row.recipe.uuid + "-quantity-input"}
              />
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={increaseRecipeItemQuantity}
              sx={{ p: 0 }}
              id={props.row.recipe.uuid + "-increase"}
            >
              <AddCircleOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right">
        {(props.row.recipe_quantity * props.row.recipe_item.quantity).toFixed(
          2
        )}
      </TableCell>
      <TableCell align="right">
        <IconButton
          size="small"
          onClick={() => {
            removeItem(props.row.recipe.uuid, props.row.recipe_item.uuid);
          }}
          sx={{ p: 0 }}
          id={props.row.recipe.uuid + "-delete"}
        >
          <DeleteOutlineOutlinedIcon fontSize="inherit" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export function copyFormatted(html) {
  // Create an iframe (isolated container) for the HTML
  var container = document.createElement("div");
  container.innerHTML = html;
  // Hide element
  container.style.position = "fixed";
  container.style.pointerEvents = "none";
  container.style.opacity = 0;
  // Mount the iframe to the DOM to make `contentWindow` available
  document.body.appendChild(container);
  // Copy to clipboard
  window.getSelection().removeAllRanges();
  var range = document.createRange();
  range.selectNode(container);
  window.getSelection().addRange(range);
  document.execCommand("copy");
  document.body.removeChild(container);
}

export default withRouter(OrderItems);
