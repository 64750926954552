import { unsecureAPI } from "../api";

export const preRegister = (data) => {
  return unsecureAPI.post("api/preregister/", JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getBackOfficeSettings = () => {
  return unsecureAPI.get("api/back_office/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateBackOfficeSettings = (data) => {
  return unsecureAPI.put("api/back_office/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateUserApi = (data) => {
  return unsecureAPI.put("api/user/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateDefaultVendorApi = (data) => {
  return unsecureAPI.put("api/vendor_defaults/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteDefaultVendorApi = (exception) => {
  return unsecureAPI.delete("api/vendor_defaults/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: exception,
  });
};

export const addDefaultVendorApi = (data) => {
  return unsecureAPI.post("api/vendor_defaults/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getProposalSettings = () => {
  return unsecureAPI.get("api/proposal/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const changePasswordApi = (data) => {
  return unsecureAPI.put("api/reset_password/", JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getCurrenciesApi = () => {
  return unsecureAPI.get("api/currencies/", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const redeemReferralCodeApi = (referral_code) => {
  const data = { referral_code };
  return unsecureAPI.post("api/redeem_referral_code/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};
