import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { format } from "date-fns";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Divider,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {
  cancelSubscriptionApi,
  getProductsApi,
  setSubscriptionApi,
  getProrationPreviewApi,
  upgradeSubscriptionApi,
  getCustomerApi,
} from "../api/StripeApi";
import { PaymentDetails } from "./PaymentDetails";
import { format_currency } from "../utils/utils";
import { useAccountState } from "../state/store";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { CollectPaymentDetailsForPlan } from "./CollectPaymentDetails";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const PlanSelection = (props) => {
  const [fre_plan, setFrePlan] = React.useState(undefined);
  const [ppe_plan, setPpePlan] = React.useState(undefined);
  const [unl_plan, setUnlPlan] = React.useState(undefined);
  const [error, setErrorMessage] = React.useState(undefined);
  const [error_alert_open, setErrorAlertOpen] = React.useState(false);
  const setError = (error_message) => {
    setErrorMessage(error_message);
    setErrorAlertOpen(true);
  };
  const fre_plan_period = "month";
  const ppe_plan_period = "month";
  const unl_plan_period = "year";
  React.useEffect(() => {
    getProductsApi().then((resp) => {
      let fre_ind = resp.data.findIndex((plan) => plan.metadata.type === "FRE");
      setFrePlan(resp.data[fre_ind]);
      let ppe_ind = resp.data.findIndex((plan) => plan.metadata.type === "PPE");
      setPpePlan(resp.data[ppe_ind]);
      let unl_ind = resp.data.findIndex((plan) => plan.metadata.type === "UNL");
      setUnlPlan(resp.data[unl_ind]);
    });
  }, []);

  const getPrice = (plan) => {
    return plan.prices.filter((price) => price.active)[0];
  };

  return fre_plan && ppe_plan && unl_plan && props.stripe_customer ? (
    <Grid container justifyContent="space-evenly" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Plan Selection</Typography>
      </Grid>
      {error_alert_open ? (
        <Grid item xs={12}>
          <Alert
            variant="outlined"
            severity="error"
            onClose={() => {
              setErrorAlertOpen(false);
            }}
          >
            {error}
          </Alert>
        </Grid>
      ) : null}
      {props.stripe_customer &&
      props.stripe_customer.subscription &&
      props.stripe_customer.subscription.status == "trialing" ? (
        <Grid item xs={12}>
          <Box>
            <Alert align="center">
              Trial ends on{" "}
              {
                new Date(
                  props.stripe_customer.subscription.trial_end.toLocaleDateString()
                )
              }
            </Alert>
          </Box>
        </Grid>
      ) : null}
      {props.stripe_customer &&
      props.stripe_customer.subscription &&
      props.stripe_customer.subscription.cancel_at_period_end ? (
        <Grid item xs={12}>
          <Alert severity="error">
            Subscription ends on{" "}
            {new Date(
              props.stripe_customer.subscription.current_period_end
            ).toLocaleDateString()}{" "}
          </Alert>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={4}>
        <PlanCard
          user={props.user}
          stripe_customer={props.stripe_customer}
          title="Scalable Starter"
          product={fre_plan}
          bullets={[
            {
              heading: "Free Forever",
              bullets: [
                "Pre-loaded Floral Data",
                "Unlimited Proposals",
                "Floral Recipes",
                "Templates",
                "Workflows + Tasks",
              ],
            },
            {
              heading: "After 2% Fee",
              bullets: [
                "E-signatures",
                "Payments (0.5% Invoice Fee)",
                "Amendments",
                "Export Orders",
                "Digital Build Instructions",
              ],
            },
          ]}
          setStripeCustomer={props.setStripeCustomer}
          setError={setError}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <PlanCard
          user={props.user}
          stripe_customer={props.stripe_customer}
          title="Scalable Pro"
          product={ppe_plan}
          bullets={[
            {
              heading: "$30/mo",
              bullets: [
                "Pre-loaded Floral Data",
                "Unlimited Proposals",
                "Floral Recipes",
                "Templates",
                "Workflows + Tasks",
                "Inventory Management",
              ],
            },
            {
              heading: "After 1% Fee",
              bullets: [
                "E-signatures",
                "Payments (No Fee)",
                "Amendments",
                "Export Orders",
                "Digital Build Instructions",
              ],
            },
          ]}
          setStripeCustomer={props.setStripeCustomer}
          setError={setError}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <PlanCard
          user={props.user}
          stripe_customer={props.stripe_customer}
          title="Unlimited"
          product={unl_plan}
          bullets={[
            {
              heading: "No Fees",
              bullets: [
                "Pre-loaded Floral Data",
                "Unlimited Proposals",
                "Floral Recipes",
                "Templates",
                "Workflows + Tasks",
                "Inventory Management",
                "E-signatures",
                "Payments (No Fee)",
                "Amendments",
                "Export Orders",
                "Digital Build Instructions",
              ],
            },
          ]}
          setStripeCustomer={props.setStripeCustomer}
          setError={setError}
        />
      </Grid>
      {props.stripe_customer ? (
        <Grid item xs={12}>
          <Divider sx={{ mt: "1rem", mb: "1rem" }} />
          <Typography variant="h6">Payment Details</Typography>
          <PaymentDetails
            stripe_customer={props.stripe_customer}
            setStripeCustomer={props.setStripeCustomer}
          />
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <Box display="flex">
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  );
};

const PlanCard = (props) => {
  const [userPlanType] = useAccountState((state) => [state.userPlanType]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [pre_confirming_plan, setPreConfirmingPlan] = React.useState(false);
  const [confirming_plan, setConfirmingPlan] = React.useState(false);
  const [cancel_plan, setCancelPlan] = React.useState(false);
  const [undo_cancel_plan, setUndoCancelPlan] = React.useState(false);
  const [switching_plan, setSwitchingPlan] = React.useState(false);

  const active_subscription = () => {
    return (
      props.stripe_customer.subscription &&
      props.stripe_customer.subscription.status == "active"
    );
  };
  const active_subscription_type = () => {
    let is_this_type =
      props.stripe_customer.subscription?.items[0].price.product ===
      props.product.id;
    return is_this_type ? props.product.metadata.type : undefined;
  };
  const active_subscription_price_id = () => {
    return props.stripe_customer.subscription?.items[0].price.id;
  };
  const active_subscription_period = () => {
    return props.stripe_customer.subscription?.items[0].price.recurring
      .interval;
  };

  const stripe_customer_payment_method = () => {
    return props.stripe_customer.default_payment_method;
  };

  const getInitialPrice = () => {
    if (props.product.prices.length > 1) {
      if (active_subscription()) {
        let price = props.product.prices.find(
          (price) => price.id === active_subscription_price_id()
        );
        if (price) {
          return price;
        } else {
          return props.product.prices.find(
            (price) => price.recurring.interval === "year"
          );
        }
      } else {
        return props.product.prices.find(
          (price) => price.recurring.interval === "year"
        );
      }
    } else {
      return props.product.prices[0];
    }
  };
  const [price, setPrice] = React.useState(getInitialPrice());
  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod) {
      setPrice(
        props.product.prices.find(
          (price) => price.recurring.interval === newPeriod
        )
      );
    }
  };
  const price_amount = () => {
    return price.unit_amount / 100;
  };
  const period = () => {
    return price.recurring.interval;
  };
  const product_type = () => {
    return props.product.metadata.type;
  };
  const percent_fee = () => {
    return price.metadata.per_event_fee
      ? price.metadata.per_event_fee * 100
      : 0;
  };
  const payment_processing_fee = () => {
    return price.metadata.payment_processing_fee
      ? price.metadata.payment_processing_fee * 100
      : 0;
  };

  const selected = () => {
    return (
      (product_type() === active_subscription_type() &&
        active_subscription() &&
        period() === active_subscription_period()) ||
      (product_type() == "FRE" && !active_subscription())
    );
  };
  const canceled = () => {
    return (
      props.stripe_customer.subscription?.cancel_at_period_end && selected()
    );
  };
  const can_select = () => {
    if (product_type() === "FRE") {
      return false;
    } else if (product_type() === "PPE") {
      return (
        !active_subscription() &&
        (!props.user.anual_contract_end_date ||
          new Date(props.user.anual_contract_end_date) < new Date())
      );
    } else if (product_type() === "UNL") {
      if (
        !active_subscription() ||
        active_subscription_type() !== "UNL" ||
        period() === "year"
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const period_labels = {
    month: "Monthly",
    year: "Yearly",
  };

  return (
    <Paper
      sx={{
        borderColor: selected()
          ? "green"
          : fullScreen
          ? "lightgrey"
          : "lightgrey",
        borderStyle: "solid",
        borderWidth: "1px",
        pt: "1rem",
      }}
      id={props.title + "-plan-card"}
    >
      <Grid
        container
        spacing={2}
        height={"100%"}
        sx={{ maxWidth: "400px" }}
        id={props.title + "-plan-card-grid"}
      >
        <Grid item xs={12} container>
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs="auto">
              <Typography variant="h6" textAlign="center">
                {props.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems={"flex-end"}
          >
            <Grid item xs="auto">
              <Typography
                variant="h2"
                textAlign="center"
                sx={{ mr: "auto", lineHeight: 1, fontWeight: "bold" }}
              >
                ${price_amount()}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography
                textAlign="flex-start"
                variant="h5"
                sx={{ fontWeight: "bold" }}
              >
                {"/" + (period().includes("month") ? "mo" : "yr")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} container justifyContent={"center"}>
              <Grid item xs="auto">
                <Typography sx={{ mt: "1rem" }}>
                  {percent_fee() + "% Booking Fee"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <Grid item xs="auto">
                <Typography>
                  {payment_processing_fee() + "% Invoicing Fee"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <Grid item xs="auto">
            <ToggleButtonGroup
              color="info"
              value={period()}
              exclusive
              onChange={handlePeriodChange}
              size="small"
            >
              {props.product.prices.map((price) => (
                <ToggleButton
                  value={price.recurring.interval}
                  sx={{ width: "100px" }}
                >
                  {period_labels[price.recurring.interval]}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} container justifyContent={"center"}>
            <Grid item xs="auto">
              <Typography
                sx={{ color: "info.main", fontSize: ".75rem", pt: ".25rem" }}
              >
                {product_type() !== "UNL"
                  ? "Cancel Any Time"
                  : "Annual Contract"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ height: "395px" }} container>
          {props.bullets.map((bullet) => (
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ pl: ".5rem" }}>
                  {bullet.heading}
                </Typography>
              </Grid>
              {bullet.bullets.map((b) => (
                <Grid item xs={12} container>
                  <Grid item xs="auto">
                    <CheckOutlinedIcon sx={{ pl: ".5rem" }} />
                  </Grid>
                  <Grid item xs="auto">
                    <Typography sx={{ fontWeight: "light" }}>{b}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} container justifyContent={"center"}>
          <Grid item xs="auto">
            {!selected() && can_select() && (
              <Button
                onClick={() => setConfirmingPlan(true)}
                size="large"
                variant="contained"
                color="secondary"
                sx={{ m: "auto", width: "200px" }}
                id="select-plan"
              >
                Select
              </Button>
            )}
            {!selected() && !can_select() && (
              <Tooltip
                title={
                  "Cannot downgrade. Please cancel current subscription and wait for it to expire."
                }
              >
                <Box display="flex" width={"100%"}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    sx={{ m: "auto", width: "200px" }}
                    id="select-plan"
                    disabled={true}
                  >
                    Select
                  </Button>
                </Box>
              </Tooltip>
            )}
            {selected() && !canceled() && product_type() !== "FRE" && (
              <Button
                onClick={() => setCancelPlan(true)}
                size="large"
                variant="outlined"
                color="error"
                sx={{ m: "auto", width: "200px" }}
                id="cancel-plan"
              >
                Cancel
              </Button>
            )}
            {selected() && product_type() === "FRE" && (
              <Button
                size="large"
                variant="outlined"
                color="info"
                sx={{ m: "auto", width: "200px" }}
                id="selected-plan"
                disabled={true}
              >
                Selected
              </Button>
            )}
            {selected() && canceled() && (
              <Button
                onClick={() => setUndoCancelPlan(true)}
                size="large"
                variant="outlined"
                color="secondary"
                sx={{ m: "auto", width: "200px" }}
                id="undo-cancel-plan"
              >
                Undo Cancel
              </Button>
            )}

            {confirming_plan && !active_subscription() && (
              <PlanConfirmationDialog
                open={confirming_plan && stripe_customer_payment_method()}
                handleCancel={() => setConfirmingPlan(false)}
                user={props.user}
                amount={price_amount()}
                stripe_price_id={price.id}
                setStripeCustomer={props.setStripeCustomer}
                setError={props.setError}
                annualContract={
                  product_type() === "UNL" && period() === "month"
                }
              />
            )}
            {confirming_plan ? (
              <CollectPaymentDetailsForPlan
                setStripeCustomer={props.setStripeCustomer}
                open={confirming_plan && !stripe_customer_payment_method()}
                handleCancel={() => setConfirmingPlan(false)}
                callback={() => setConfirmingPlan(true)}
                plan_type={product_type()}
                plan_period={period()}
              />
            ) : null}
            {active_subscription() && selected() && (
              <PlanCancelDialog
                open={cancel_plan}
                handleCancel={() => setCancelPlan(false)}
                end_date={props.stripe_customer.subscription.current_period_end}
                setStripeCustomer={props.setStripeCustomer}
                annualContract={
                  product_type() === "UNL" && period() === "month"
                }
                user={props.user}
              />
            )}
            {props.stripe_customer.subscription ? (
              <UndoPlanCancelDialog
                open={undo_cancel_plan}
                handleCancel={() => setUndoCancelPlan(false)}
                end_date={props.stripe_customer.subscription.current_period_end}
                stripe_customer={props.stripe_customer}
                setStripeCustomer={props.setStripeCustomer}
                amount={props.stripe_customer.subscription.plan.amount / 100}
              />
            ) : null}
            {active_subscription() &&
            !selected() &&
            can_select() &&
            confirming_plan ? (
              <SwitchPlanDialog
                open={confirming_plan}
                handleCancel={() => setConfirmingPlan(false)}
                new_price_id={price.id}
                setStripeCustomer={props.setStripeCustomer}
                stripe_customer={props.stripe_customer}
                plan_amount={price_amount()}
                annualContract={
                  product_type() === "UNL" && period() === "month"
                }
                user={props.user}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Paper>
  );
};

const PlanConfirmationDialog = (props) => {
  const [loading, setLoading] = React.useState(false);

  const [first_payment_date, setFirstPaymentDate] = React.useState("");

  const setSubscription = () => {
    setLoading(true);
    setSubscriptionApi(props.stripe_price_id).then((resp) => {
      let new_user = resp.data;
      if (new_user.subscription?.items?.data) {
        new_user.subscription.items = new_user.subscription.items.data;
      }
      props.setStripeCustomer(new_user);
      props.handleCancel();
      setLoading(false);
    });
  };
  const [agree, setAgree] = React.useState(props.annualContract ? false : true);
  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Subscription Confirmation</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              {props.amount > 0
                ? (props.user.stripe_customer.balance * -1) / 100 < props.amount
                  ? "You will be charged " +
                    format_currency(
                      props.amount -
                        (props.user.stripe_customer.balance * -1) / 100
                    )
                  : format_currency(props.amount) +
                    " will be deducted from your FlowerBuddy credit balance"
                : "You will not be charged until an event books."}

              {first_payment_date}
            </Typography>
          </Grid>
          {props.annualContract && (
            <Grid item xs={12}>
              <Box
                sx={{
                  p: ".5rem",
                  border: "dotted 1px",
                  borderRadius: "5px",
                }}
              >
                <Typography>
                  This is an annual plan, billed monthly. If you cancel this
                  plan before 12 months, you will loose access to your account
                  until the balance is paid or the subscription period ends.{" "}
                  {props.user.anual_contract_end_date &&
                    "Current annual contract ends on " +
                      new Date(
                        props.user.anual_contract_end_date
                      ).toLocaleDateString() +
                      "."}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox checked={agree} onChange={handleAgreeChange} />
                  }
                  label="I Understand And Agree"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="info"
          variant="outlined"
          onClick={() => props.handleCancel()}
          id="cancel-subscription"
        >
          Cancel
        </Button>
        {!loading ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={setSubscription}
            id="confirm-subscription"
            disabled={!agree}
          >
            Confirm
          </Button>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

const PlanCancelDialog = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    cancelSubscriptionApi().then((resp) => {
      props.setStripeCustomer(resp.data);
      props.handleCancel();
      setSubmitting(false);
    });
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        <Typography variant="h5">Cancel Subscription Confirmation</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ maxWidth: "350px" }}>
          <Grid item xs={12}>
            <Typography>
              You subscription will expire on{" "}
              {new Date(props.end_date).toLocaleDateString()}.
            </Typography>
          </Grid>
          {props.annualContract && (
            <Grid item xs={12}>
              <Typography
                sx={{ p: ".5rem", border: "dotted 1px", borderRadius: "5px" }}
              >
                Note: Since you have an annual contract, you will lose all
                access to your account until the balance is paid or the contract
                period ends.{" "}
                {props.user.anual_contract_end_date &&
                  "Annual contract ends on " +
                    new Date(
                      props.user.anual_contract_end_date
                    ).toLocaleDateString() +
                    "."}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!submitting ? (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                onClick={props.handleCancel}
                id="cancel-subscription"
              >
                Keep Subscription
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="error"
                onClick={handleSubmit}
                id="confirm-subscription"
              >
                Cancel Subscription
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

const UndoPlanCancelDialog = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    cancelSubscriptionApi().then((resp) => {
      props.setStripeCustomer(resp.data);
      props.handleCancel();
      setSubmitting(false);
    });
  };

  const getAmount = (stripe_customer) => {
    if (stripe_customer.subscription.items.data) {
      return stripe_customer.subscription.items.data[0].price.unit_amount / 100;
    } else {
      return stripe_customer.subscription.items[0].price.unit_amount / 100;
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        <Typography variant="h5">Undo Cancel Subscription</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ maxWidth: "350px" }}>
          <Grid item xs={12}>
            <Typography>
              You will be charged{" "}
              {format_currency(getAmount(props.stripe_customer))} on{" "}
              {new Date(props.end_date).toLocaleDateString()}.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!submitting ? (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                onClick={props.handleCancel}
                id="cancel-subscription"
              >
                Nevermind
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                id="confirm-subscription"
              >
                Undo Cancel Subscription
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

const SwitchPlanDialog = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    upgradeSubscriptionApi(props.new_price_id).then((resp) => {
      let new_subscription = resp.data;
      if (new_subscription.items?.data) {
        new_subscription.items = new_subscription.items.data;
      }
      props.setStripeCustomer({
        ...props.stripe_customer,
        subscription: new_subscription,
      });
      setSubmitting(false);
      props.handleCancel();
    });
  };

  const [amount, setAmount] = React.useState(undefined);
  const [invoice_date, setInvoiceDate] = React.useState(undefined);

  React.useEffect(() => {
    if (props.stripe_customer.subscription.status == "trialing") {
      setAmount(props.plan_amount);
      setInvoiceDate(new Date(props.stripe_customer.subscription.trial_end));
      setLoaded(true);
    } else {
      getProrationPreviewApi(props.new_price_id).then((resp) => {
        setAmount(resp.data.total / 100);
        setInvoiceDate(new Date());
        setLoaded(true);
      });
    }
  }, []);

  const [agree, setAgree] = React.useState(props.annualContract ? false : true);
  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Switch Subscription Confirmation</DialogTitle>
      {loaded ? (
        <Box>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  You will be charged {format_currency(amount)} on{" "}
                  {invoice_date.toLocaleDateString()}.
                </Typography>
              </Grid>
              {props.annualContract && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: ".5rem",
                      border: "dotted 1px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography>
                      This is an annual plan, billed monthly. If you cancel this
                      plan before 12 months, you will loose access to your
                      account until the balance is paid or the subscription
                      period ends.{" "}
                      {props.user.anual_contract_end_date &&
                        "Current annual contract ends on " +
                          new Date(
                            props.user.anual_contract_end_date
                          ).toLocaleDateString() +
                          "."}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={agree}
                          onChange={handleAgreeChange}
                        />
                      }
                      label="I Understand And Agree"
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {!submitting ? (
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item xs="auto">
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={props.handleCancel}
                    id="cancel-subscription"
                  >
                    Nevermind
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    id="confirm-subscription"
                    disabled={!agree}
                  >
                    Switch
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <CircularProgress />
            )}
          </DialogActions>
        </Box>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};
