import React from "react";
import { withRouter } from "react-router-dom";

import { Grid, Box, IconButton } from "@mui/material";

import CroppedImg from "./CroppedImg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { sort_by_order } from "../utils/utils";
import { Cropper, FileDropZone } from "./FileUploader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DraggableList from "./DraggableList";
import { useAccountState } from "../state/store";

const RecipeImgs = (props) => {
  const [handleRecipeImgDragEnd] = useAccountState((state) => [
    state.handleRecipeImgDragEnd,
  ]);

  const handleUploadedFile = (images) => {
    var data = {
      cropped_image: images,
    };
    props.addImg(data);
  };

  const max_photos = 25;

  const handleDragEnd = (result) => {
    handleRecipeImgDragEnd(props.event_item.uuid, result);
  };

  return (
    <Grid container spacing={0.5}>
      <DraggableList
        list={props.imgs.sort(sort_by_order)}
        listContainerComponent={Grid}
        listContainerComponentProps={{
          container: true,
          spacing: 0.25,
        }}
        listItemComponent={InspirationImg}
        listItemComponentProps={{
          displayOnly: props.displayOnly,
          zoom: props.zoom,
          removeImg: props.removeImg,
          addImg: props.addImg,
          updateImg: props.updateImg,
          height: props.height,
        }}
        listItemPropName="img"
        onDragEnd={handleDragEnd}
      />
      {!props.displayOnly && props.imgs.length < max_photos ? (
        <Grid item xs={"auto"}>
          <Box
            sx={{
              p: "1rem",
              borderStyle: "dotted",
              borderColor: "lightgrey",
              display: "flex",
              overflow: "visible",
            }}
          >
            <Box sx={{ m: "auto", width: "100%", height: "100%" }}>
              <FileDropZone
                hideBorder
                width="100%"
                height="100%"
                id={props.id + "-dropzone"}
                handleSubmit={handleUploadedFile}
                maxSelections={max_photos - props.imgs.length}
              />
            </Box>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

const InspirationImg = (props) => {
  const [img, setImg] = React.useState(props.img);

  const handleCroppingSubmit = (cropped_images) => {
    props.updateImg({
      uuid: img.uuid,
      cropped_image: { ...img.cropped_image, ...cropped_images[0] },
    });
  };

  const handleDelete = () => {
    props.removeImg(img.uuid);
    setImg(undefined);
  };

  React.useEffect(() => {
    setImg(props.img);
  }, [props.img]);
  const grid_props = props.displayOnly
    ? {}
    : {
        draggable: props.draggable,
        onDragStart: props.onDragStart,
        onDragOver: props.onDragOver,
        onDrop: props.onDrop,
        onDragEnd: props.onDragEnd,
        style: props.style,
      };

  return img ? (
    <Grid item xs={"auto"} {...grid_props}>
      <CroppedImg
        img={img.cropped_image}
        zoom={props.zoom}
        height={props.height ? props.height : 100}
        onDelete={props.displayOnly ? undefined : handleDelete}
        onEdit={props.displayOnly ? undefined : handleCroppingSubmit}
      />
    </Grid>
  ) : null;
};

export default withRouter(RecipeImgs);
