import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  itemCategories,
  itemColors,
  itemDisplayName,
  itemSeasons,
  materialDisplaySize,
  materialTypeName,
} from "../utils/item_utils";
import CreateItemDialog from "./CreateItemDialog";
import AddItemDialog, {
  ItemFilterAndSearch,
  ItemsTable,
} from "./AddItemDialog";
import { ItemIcon } from "./ItemIcon";
import { useAccountState } from "../state/store";
import { LoadingButton } from "@mui/lab";
import { G } from "@react-pdf/renderer";

const CustomItems = (props) => {
  const [addCustomItems, customItemsByType, customItems] = useAccountState(
    (state) => [
      state.addCustomItems,
      state.customItemsByType,
      state.customItems,
    ]
  );
  const item_name = props.item_type === "FL" ? "Flower" : "Hard Good";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [adding, setAdding] = React.useState(false);
  const [loadingAddItem, setLoadingAddItem] = React.useState(false);
  const addItems = (items) => {
    setLoadingAddItem(true);
    setAdding(false);
    addCustomItems(items, () => {
      setLoadingAddItem(false);
    });
  };
  const customItems_ = React.useMemo(() => {
    return customItemsByType(props.item_type);
  }, [customItemsByType, props.item_type, customItems]);
  const [items, setItems] = React.useState(customItems_);

  const columns =
    props.item_type === "FL"
      ? [
          {
            field: "name",
            headerName: "Name",
            flex: 5,
            renderCell: RenderName,
            valueGetter: (params) => itemDisplayName(params.row),
            hideable: false,
          },
          {
            field: "categories",
            headerName: "Categories",
            minWidth: 100,
            renderCell: RenderTags,
            tags: itemCategories(),
            sortable: false,
            hideable: false,
            hide: fullScreen,
          },
          {
            field: "seasons",
            headerName: "Seasons",
            minWidth: 165,
            renderCell: RenderTags,
            tags: itemSeasons(),
            sortable: false,
            hideable: false,
            hide: fullScreen,
          },
          {
            field: "colors",
            headerName: "Colors",
            minWidth: 85,
            renderCell: RenderColors,
            sortable: false,
            hideable: false,
            hide: fullScreen,
          },
          {
            field: "actions",
            headerName: "",
            flex: 2,
            renderCell: RenderActions,
            sortable: false,
            hideable: false,
            item_type: props.item_type,
          },
        ]
      : [
          {
            field: "name",
            headerName: "Name",
            flex: 6,
            renderCell: RenderName,
            valueGetter: (params) =>
              materialTypeName(params.row.type) + " " + params.row.name,
            hideable: false,
          },
          {
            field: "dimensions",
            headerName: "Dimensions",
            minWidth: 150,
            renderCell: RenderDimensions,
            sortable: false,
            hideable: false,
            hide: fullScreen,
          },
          {
            field: "colors",
            headerName: "Colors",
            minWidth: 75,
            renderCell: RenderColors,
            sortable: false,
            hideable: false,
            hide: fullScreen,
          },
          {
            field: "actions",
            headerName: "",
            flex: 2,
            renderCell: RenderActions,
            sortable: false,
            hideable: false,
            item_type: props.item_type,
          },
        ];

  const getItemId = (item) => {
    return item.uuid;
  };

  const columnProps =
    props.item_type === "FL" ? { enableCategories: true } : { width: 150 };

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item xs="auto">
        <LoadingButton
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setAdding(true)}
          loading={loadingAddItem}
        >
          {`Add ${item_name}`}
        </LoadingButton>
        <AddItemDialog
          open={adding}
          handleCancel={() => setAdding(false)}
          addItems={addItems}
          disableEventItems
          disableMyItems
          filter_items={customItemsByType(props.item_type)}
          item_type={props.item_type}
          disablePricing
        />
      </Grid>
      <Grid item xs={12}>
        <ItemFilterAndSearch
          list={customItems_}
          setOptions={setItems}
          itemType={props.item_type}
          disableEventItems={true}
          disableMyItems={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ItemsTable
          items={items}
          enableColors
          enableCategories={props.item_type === "FL"}
          enableSeasons={props.item_type === "FL"}
          renderActions={RenderActions}
          height="65vh"
          width="100%"
        />
      </Grid>
    </Grid>
  );
};

const RenderName = (props) => {
  return (
    <Grid container key={props.row.uuid} display="flex" alignItems="center">
      <Grid item xs={2}>
        <ItemIcon
          key={props.row.thumbnail?.uuid}
          item={props.row}
          width={30}
          height={30}
        />
      </Grid>
      <Grid item xs={10}>
        <Tooltip title={itemDisplayName(props.row)} key={props.row.uuid}>
          <Typography noWrap>{itemDisplayName(props.row)}</Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const RenderTags = (props) => {
  const tags = props.colDef.tags;
  return (
    <Grid container key={props.row.uuid} spacing={0.25}>
      {tags
        .filter((tag) => {
          return props.row[tag];
        })
        .map((tag) => (
          <Grid item xs="auto">
            <Tooltip title={tag.toUpperCase()}>
              <Typography
                textAlign="center"
                fontSize=".75rem"
                sx={{
                  backgroundColor: "lightgray",
                  borderRadius: "50%",
                  width: "15px",
                  pl: ".5rem",
                  pr: ".5rem",
                }}
              >
                {tag.toUpperCase().slice(0, 2)}
              </Typography>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

const RenderDimensions = (props) => {
  return (
    <Grid container key={props.row.uuid} spacing={0.25}>
      <Typography>{materialDisplaySize(props.row)}</Typography>
    </Grid>
  );
};

const RenderColors = (props) => {
  const colors = itemColors("MA"); //material colors encompass all color options
  return (
    <Grid container key={props.row.uuid} spacing={0.25}>
      {colors
        .filter((color) => {
          return props.row[color.value];
        })
        .map((color) => (
          <Grid item xs="auto">
            <Tooltip title={color.label}>
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  mr: ".25rem",
                  backgroundColor: color.color_code,
                }}
              ></Box>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

const RenderActions = (props) => {
  const [updateCustomItem] = useAccountState((state) => [
    state.updateCustomItem,
  ]);
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  return (
    <Grid container justifyContent="flex-end" key={props.row.uuid}>
      <Grid item xs="auto">
        <Tooltip title="Edit">
          <IconButton
            onClick={() => setEditing(true)}
            id={props.row.uuid + "-edit"}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      {editing && (
        <CreateItemDialog
          initialValues={props.row}
          open={editing}
          handleCancel={() => setEditing(false)}
          disablePricing
          handleSubmit={updateCustomItem}
          disableName={!!props.row.core_item}
          disableColorName={!!props.row.core_item}
          item_type={props.row.item_type}
        />
      )}

      <Grid item xs="auto">
        <Tooltip title="Delete">
          <IconButton
            onClick={() => setDeleting(true)}
            id={props.row.uuid + "-delete"}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      {deleting && (
        <DeleteItemDialog
          open={deleting}
          handleCancel={() => setDeleting(false)}
          item={props.row}
        />
      )}
    </Grid>
  );
};

const DeleteItemDialog = (props) => {
  const [deleteCustomItem] = useAccountState((state) => [
    state.deleteCustomItem,
  ]);
  const handleSubmit = () => {
    deleteCustomItem(props.item.uuid);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Item</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to delete the item "
              {itemDisplayName(props.item)}"? This action will:
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={1}>
              <DeleteOutlinedIcon />
            </Grid>
            <Grid item xs={11}>
              <Typography>Remove the item from your Data page</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={1}>
              <DeleteOutlinedIcon />
            </Grid>
            <Grid item xs={11}>
              <Typography>
                Remove the item from your inventory and inventory log
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={1}>
              <CircleOutlinedIcon />
            </Grid>
            <Grid item xs={11}>
              <Typography>Leave the item in any events it is in</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(CustomItems);
