import React from "react";
import { withRouter } from "react-router-dom";

import { twicpic_url } from "../utils/cropImage";

import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";
import { Avatar, Box, Dialog, DialogContent, IconButton } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Cropper } from "./FileUploader";

const CroppedImg = (props) => {
  const [imageLoadError, setImageLoadError] = React.useState(false);

  const handleError = () => {
    setImageLoadError(true);
  };

  const DefaultComponent = props.default;
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => {
    console.log("OPEN");
    setOpen(!open);
  };

  const [cropping, setCropping] = React.useState(false);
  const toggleCropping = () => {
    setCropping(!cropping);
  };

  return !imageLoadError || !DefaultComponent ? (
    <div
      style={{
        ...(props.onEdit || props.onDelete
          ? {
              position: "relative",
              display: "inline-block",
            }
          : {}),
        width: props.width,
        height: props.height,
      }}
    >
      <img
        alt=""
        src={twicpic_url(
          props.img,
          {
            width: props.width,
            height: props.height,
          },
          props.quality,
          props.contain,
          props.square ? "1024x1024" : undefined
        )}
        sx={props.sx}
        style={{
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
          marginBottom: props.marginBottom,
          marginTop: props.marginTop,
          maxWidth: props.maxWidth,
          maxHeight: props.maxHeight,
          width: props.width,
          height: props.height,
          objectFit: props.fill ? "cover" : "contain",
          borderRadius: props.borderRadius,
          display: props.display,
        }}
        onError={handleError}
        onClick={props.zoom && toggleOpen}
      />
      {(props.onEdit || props.onDelete) && (
        <div
          style={{
            position: "absolute",
            top: "8px",
            left: "8px",
            display: "flex",
            gap: "8px",
          }}
        >
          {props.onDelete && (
            <IconButton
              onClick={props.onDelete}
              size="small"
              sx={{ color: "white" }}
            >
              <ClearOutlinedIcon fontSize="small" />
            </IconButton>
          )}
          {props.onEdit && (
            <>
              <IconButton
                onClick={toggleCropping}
                size="small"
                sx={{ color: "white" }}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <Cropper
                open={cropping}
                handleCancel={toggleCropping}
                handleSubmit={props.onEdit}
                image={props.img}
                disableDelete
                aspect={props.square ? 1 : undefined}
              />
            </>
          )}
        </div>
      )}
      {props.zoom && open && (
        <ZoomDialog
          open={open}
          handleCancel={toggleOpen}
          handleError={handleError}
          {...props}
        />
      )}
    </div>
  ) : (
    <DefaultComponent {...props.defaultProps} />
  );
};

export const CroppedAvatar = (props) => {
  return (
    <Avatar
      id={props.id}
      src={twicpic_url(props.img, { width: props.width, height: props.height })}
      sx={{
        ...props.sx,
        width: props.width && `${props.width}px`,
        height: props.height && `${props.height}px`,
      }}
      onClick={props.onClick}
      key={props.img?.uuid}
    >
      {props.children}
    </Avatar>
  );
};

const ZoomDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent onClick={props.handleCancel}>
        <img
          alt=""
          src={twicpic_url(
            props.img,
            {
              width: "100%",
              height: "100%",
            },
            undefined,
            "1024x1024",
            props.square ? "1024x1024" : undefined
          )}
          style={{
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
            marginBottom: props.marginBottom,
            marginTop: props.marginTop,
            maxWidth: "90vw", // Limit width to viewport width
            maxHeight: "90vh", // Limit height to viewport height
            width: "100%",
            height: "100%",
            objectFit: props.fill ? "cover" : "contain",
            borderRadius: props.borderRadius,
            display: props.display,
          }}
          onError={props.handleError}
          onClick={props.handleCancel}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(CroppedImg);
