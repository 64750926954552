import React from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  MenuItem,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  Tooltip,
  Typography,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  Divider,
  Link,
  LinearProgress,
} from "@mui/material";

import { useAccountState } from "../state/store";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useFormik } from "formik";
import { format_percent, format_utc_timestamp } from "../utils/utils";
import { revenueGoal, revenuePerEventGoal } from "../utils/goals_utils";
import { getGoalProgress } from "../api/GoalsApi";
import { set, update } from "lodash";

const Goals = (props) => {
  const [initializeUser, updateUser, user] = useAccountState((state) => [
    state.initializeUser,
    state.updateUser,
    state.user,
  ]);

  React.useEffect(() => initializeUser(props.user), []);

  const [year, setYear] = React.useState(2024);
  const [settingGoal, setSettingGoal] = React.useState(false);

  const [updateingProgress, setUpdatingProgress] = React.useState(false);

  const updateGoalProgress = () => {
    setUpdatingProgress(true);
    getGoalProgress(year).then((res) => {
      updateUser({
        goals: {
          ...user.goals,
          [year]: {
            ...user.goals[year],
            progress: res.data,
          },
        },
      });
      setUpdatingProgress(false);
    });
  };

  React.useEffect(() => {
    if (user.goals && user.goals[year] && !user.goals[year].progress) {
      updateGoalProgress();
    }
  }, [user]);

  return user ? (
    <>
      <Grid item xs="auto" container alignItems={"center"}>
        <Grid item xs="auto">
          {user.goals &&
            user.goals[year] &&
            user.goals[year].progress?.timestamp && (
              <Grid item xs="auto">
                <Typography color="info.main" sx={{ fontSize: ".75rem" }}>
                  Updated on{" "}
                  {format_utc_timestamp(user.goals[year].progress?.timestamp)}
                </Typography>
              </Grid>
            )}
          <Grid item xs="auto">
            <Typography color="info.main" sx={{ fontSize: ".75rem" }}>
              {updateingProgress ? (
                "Updating..."
              ) : (
                <Link
                  sx={{
                    color: "info.main",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={updateGoalProgress}
                >
                  Refresh Progress
                </Link>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <FormControl sx={{ m: 1 }}>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="goal-year-select"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label="Year"
              size="small"
            >
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
              <MenuItem value={2026}>2026</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {user.goals && user.goals[year] && (
          <Grid item xs="auto">
            <Tooltip title={`Edit ${year} Goal`}>
              <IconButton
                onClick={() => {
                  setSettingGoal(true);
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {user.goals && user.goals[year] ? (
        <GoalViewer year={year} />
      ) : (
        <Box
          sx={{
            p: ".5rem",
            border: "dashed 2px",
            borderColor: "info.main",
            borderRadius: "5px",
            color: "info.main",
          }}
        >
          <Grid container spacing={1} justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography>
                No goal set for {year}. Set a revenue goal to view your progress
                based on your booked events.
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={() => setSettingGoal(true)}
                variant="outlined"
                color="info"
              >
                Set {year} Goal
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <GoalSetter
        year={year}
        open={settingGoal}
        handleCancel={() => setSettingGoal(false)}
      />
    </>
  ) : (
    <CircularProgress />
  );
};

const GoalSetter = (props) => {
  const [getCurrencySymbol, updateUser, user] = useAccountState((state) => [
    state.getCurrencySymbol,
    state.updateUser,
    state.user,
  ]);

  const [year, setYear] = React.useState(props.year);

  React.useEffect(() => {
    setYear(props.year);
  }, [props.year]);

  const formik = useFormik({
    initialValues: user.goals && user.goals[year] ? user.goals[year] : {},
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let goals = user.goals ? user.goals : {};
      goals[year] = values;
      updateUser({
        goals: goals,
      });
      props.handleCancel();
      resetForm();
    },
  });

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Set {year} Goal</DialogTitle>
      <DialogContent>
        <Grid container>
          <GoalSetterTextField
            id="profit"
            label={`Profit (${getCurrencySymbol()})`}
            tooltip="Target annual profit"
            formik={formik}
            title="Net Profit Goal"
            description="Enter your year-end goal for personal earnings from the business, excluding your labor income from your direct event work, and after covering all expenses and overheads."
          />
          <GoalSetterTextField
            id="profit_margin"
            label={`Profit Margin (%)`}
            tooltip="Target profit margin as a percent of revenue"
            formik={formik}
            title="Gross Profit Margin Goal"
            description="Input your desired profit margin percentage for each event, calculated before deducting fixed overheads. This reflects the direct profitability of your events, and you can get this number from your FlowerBuddy events."
          />
          <GoalSetterTextField
            id="overhead"
            label={`Overhead (${getCurrencySymbol()})`}
            tooltip="Estimated overhead cost annually"
            formik={formik}
            title="Estimated Overhead Expenses"
            description="Enter your estimated sum total annual fixed costs, like rent, utilities, and salaries. These are regular expenses not tied to specific events."
          />
          <GoalSetterTextField
            id="event_number"
            label={`Number of Events`}
            tooltip="Target number of events for the year"
            formik={formik}
            title="Estimated Number of Events"
            description="Input the total number of events you plan to manage this year."
          />
          <GoalSetterTextField
            label={`Target Revenue (${getCurrencySymbol()})`}
            tooltip="Annual revenue needed to achieve target profit"
            disabled
            year={props.year}
            value={revenueGoal(formik.values)}
            title="Target Gross Revenue"
            description="Based on your inputs above, we’ve calculated the total yearly revenue needed to meet your net profit goal."
          />
          <GoalSetterTextField
            label={`Target Revenue / Event (${getCurrencySymbol()})`}
            tooltip="Revenue needed per event to achieve target profit"
            disabled
            year={props.year}
            value={revenuePerEventGoal(formik.values)}
            title="Target Gross Revenue per Event"
            description="Based on your inputs above, we’ve calculated the average revenue you should aim for in each event to achieve your annual financial targets."
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} variant="outlined" color="info">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GoalSetterTextField = (props) => {
  const handleChange = (e) => {
    props.formik.setFieldValue(props.id, e.target.value);
  };

  const handleBlur = (e) => {
    try {
      let value = parseFloat(e.target.value);
      if (value < 0) {
        value = 0;
      }
      props.formik.setFieldValue(props.id, value);
    } catch (err) {
      console.log(e.target.value, err);
    }
  };

  return (
    <Grid item xs={12} container sx={{ mt: ".5rem" }}>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold", fontSize: ".8rem" }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: ".8rem" }}>{props.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          sx={{ mt: ".5rem" }}
          label={props.label}
          value={props.disabled ? props.value : props.formik?.values[props.id]}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          size="small"
          disabled={props.disabled}
        />
      </Grid>
    </Grid>
  );
};

const GoalViewer = (props) => {
  const [formatCurrency, getCurrencySymbol, updateUser, user] = useAccountState(
    (state) => [
      state.formatCurrency,
      state.getCurrencySymbol,
      state.updateUser,
      state.user,
    ]
  );

  return (
    <Grid container justifyContent={"space-between"} spacing={2}>
      <Grid item xs={12} container>
        <Grid item xs={6}></Grid>
        <Grid item xs={2}>
          <Typography textAlign={"center"}>Booked</Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography textAlign={"center"}>Goal</Typography>
        </Grid>
      </Grid>
      <GoalViewerField
        label="Profit"
        value={user.goals[props.year].profit}
        progress={user.goals[props.year].progress?.total_profit}
        year={props.year}
        currency
        goal_type="value"
      />
      <GoalViewerField
        label="Number of Events"
        value={user.goals[props.year].event_number}
        progress={user.goals[props.year].progress?.num_events}
        year={props.year}
        goal_type="value"
      />
      <GoalViewerField
        label="Revenue"
        value={revenueGoal(user.goals[props.year])}
        progress={user.goals[props.year].progress?.total_price}
        year={props.year}
        currency
        goal_type="value"
      />
      <GoalViewerField
        label="Revenue per Event"
        value={revenuePerEventGoal(user.goals[props.year])}
        progress={user.goals[props.year].progress?.avg_price}
        year={props.year}
        currency
      />
      <GoalViewerField
        label="Profit Margin"
        value={user.goals[props.year].profit_margin / 100}
        progress={user.goals[props.year].progress?.avg_profit_margin}
        year={props.year}
        percent
      />
    </Grid>
  );
};

const GoalViewerField = (props) => {
  const [user] = useAccountState((state) => [state.user]);
  const getProgress = () => {
    if (props.goal_type === "value") {
      if (props.progress) {
        let progress = (props.progress / props.value) * 100;
        if (progress > 100) {
          return 100;
        } else {
          return progress;
        }
      } else {
        return 0;
      }
    } else {
      if (props.progress) {
        if (props.progress > 1.05 * props.value) {
          return "Ahead";
        } else if (props.progress < 0.95 * props.value) {
          return "Behind";
        } else {
          return "On Track";
        }
      } else {
        return "Behind";
      }
    }
  };

  const roundToNearest = (val, target) => {
    if (target === 0) {
      return val;
    } else {
      return Math.round(val / target) * target;
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: user.currency ? user.currency : "USD",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  const formatValue = (value) => {
    if (props.currency) {
      return formatter.format(parseFloat(value) / 1000, null, 0) + "k";
    } else if (props.percent) {
      return format_percent(value);
    } else {
      return value;
    }
  };

  return (
    <Grid item xs={12} container spacing={2} alignItems={"center"}>
      <Grid item xs={3}>
        <Typography>{props.label}</Typography>
      </Grid>
      <Grid item xs={3}>
        {props.goal_type === "value" ? (
          <LinearProgress variant={"determinate"} value={getProgress()} />
        ) : (
          <Typography textAlign={"center"}>{getProgress()}</Typography>
        )}
      </Grid>

      <Grid item xs={2}>
        {props.progress && (
          <Typography textAlign={"center"}>
            {formatValue(props.progress)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={2}>
        <Typography textAlign={"center"}>of</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography textAlign={"center"}>{formatValue(props.value)}</Typography>
      </Grid>
    </Grid>
  );
};

export default withRouter(Goals);
