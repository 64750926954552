import React from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";

import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Box,
  Alert,
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { getStripeAccountApi, getStripeLinkApi } from "../api/PaymentsApi";
import { titleCase } from "../utils/utils";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

const PaymentsIntegration = (props) => {
  const [loadingLink, setLoadingLink] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [account, setAccount] = React.useState(props.user.stripe_account);

  const reloadAccount = () => {
    setLoading(true);
    getStripeAccountApi().then((resp) => {
      setAccount(resp.data);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    reloadAccount();
  }, []);

  const goToAccountPage = () => {
    setLoadingLink(true);
    getStripeLinkApi().then((resp) => {
      window.location.replace(resp.data.url);
    });
  };

  return !loading && account ? (
    <Grid container alignItems="center" rowSpacing={2} columnSpacing={1}>
      {account.email ? (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h6">
                    Stripe Account
                    {!account.charges_enabled || !account.payouts_enabled ? (
                      <Tooltip title="Your Stripe integration is not setup. Complete the to do list below to finish setting up your Stripe integration.">
                        <CancelOutlinedIcon fontSize="inherit" color="error" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Your Stripe integration setup is complete!">
                        <CheckCircleOutlineOutlinedIcon
                          fontSize="inherit"
                          color="success"
                        />
                      </Tooltip>
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
              {!account.charges_enabled || !account.payouts_enabled ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Alert severity="error">
                      Stripe verification can take anywhere from 10 minutes to
                      24 hours to complete. Check the status using the Refresh
                      Details button below.
                    </Alert>
                  </TableCell>
                </TableRow>
              ) : null}
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{account.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payments Enabled</TableCell>
                <TableCell>
                  {account.charges_enabled ? "True" : "False"}
                  {!account.charges_enabled ? (
                    <Tooltip title="Your Stripe integration setup is incomplete. You will not be able to accept payments from clients through FlowerBuddy. Complete the to do list below to finish setting up your Stripe integration.">
                      <CancelOutlinedIcon fontSize="inherit" color="error" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Your can accept payments from your clients through FlowerBuddy!">
                      <CheckCircleOutlineOutlinedIcon
                        fontSize="inherit"
                        color="success"
                      />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payouts Enabled</TableCell>
                <TableCell>
                  {account.payouts_enabled ? "True" : "False"}
                  {!account.payouts_enabled ? (
                    <Tooltip title="Your Stripe integration setup is incomplete. You will not be able to accept payouts from Stripe. Complete the to do list below to finish setting up your Stripe integration.">
                      <CancelOutlinedIcon fontSize="inherit" color="error" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Your can accept payouts from your Stripe!">
                      <CheckCircleOutlineOutlinedIcon
                        fontSize="inherit"
                        color="success"
                      />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
      {requirements_list(account).length > 0 && account.email ? (
        <Grid item xs={12} container>
          <Alert severity="warning">
            There are pending item in your Stripe account. Click Manage Stripe
            Account to continue setup.
          </Alert>
        </Grid>
      ) : account.email &&
        !account.charges_enabled &&
        !account.payouts_enabled ? (
        <Grid item xs={12} container>
          <Alert severity="info">
            There are no pending setup items. To check the status of your Stripe
            account and integration use the Refresh Details button.
          </Alert>
        </Grid>
      ) : null}
      {account.email ? (
        <Grid item xs={12} container spacing={1} justifyContent="flex-end">
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="info"
              onClick={reloadAccount}
              id="refresh-details-button"
            >
              Refresh Details
            </Button>
          </Grid>
          <Grid item xs="auto">
            {!loadingLink ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={goToAccountPage}
                id="manage-stripe-account-button"
              >
                Manage Stripe Account
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      ) : null}
      {!account.email ? (
        <Grid item xs={12}>
          <Box
            sx={{
              border: "dashed 2px",
              borderRadius: "5px",
              p: "1rem",
              borderColor: "lightgrey",
            }}
          >
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <Typography align="center">
                  You have not connected a stripe account.
                </Typography>
              </Grid>
              <Grid item xs="auto">
                {!loadingLink ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={goToAccountPage}
                  >
                    Connect Stripe Account
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12} container alignItems="center">
        <Grid item xs="auto">
          <img
            width="200px"
            src="https://cdn.brandfolder.io/KGT2DTA4/at/555stp2cqvkm9ktv7c7btjr5/Stripe_Partner_Badge_--_Brand.png"
          />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Box display="flex">
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  );
};

const requirements_list = (account) => {
  return [
    ...new Set([
      ...account.requirements?.past_due,
      ...account.requirements?.currently_due,
      ...account.requirements?.eventually_due,
    ]),
  ].sort();
};

const format_requirement = (requirement) => {
  var req = requirement.replace(/_/g, " ").replace(".", ": ");
  return titleCase(req);
};

export default withRouter(PaymentsIntegration);
