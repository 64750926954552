import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAccountState } from "../../state/store";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { sort_by_order } from "../../utils/utils";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddItemDialog from "../AddItemDialog";

import CroppedImg from "../CroppedImg";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { itemColors, itemDisplayName } from "../../utils/item_utils";
import { twicpic_url } from "../../utils/cropImage";
import { DefaultStyles } from "../../utils/proposal_utils";
import { LoadingButton } from "@mui/lab";
import DraggableList from "../DraggableList";

export const ProposalItems = (props) => {
  const [
    addProposalItem,
    addProposalItems,
    handleProposalItemDragEnd,
    removeProposalItem,
  ] = useAccountState((state) => [
    state.addProposalItem,
    state.addProposalItems,
    state.handleProposalItemDragEnd,
    state.removeProposalItem,
  ]);

  const max_items = 60;
  const [addingFlower, setAddingFlower] = useState(false);
  const [addingHardGood, setAddingHardGood] = useState(false);

  const setAddingFlowerTrue = () => {
    setAddingFlower(true);
  };

  const setAddingFlowerFalse = () => {
    setAddingFlower(false);
  };

  const setAddingHardGoodTrue = () => {
    setAddingHardGood(true);
  };

  const setAddingHardGoodFalse = () => {
    setAddingHardGood(false);
  };

  const addItem = (data) => {
    addProposalItem({
      ...data,
      proposal_section_uuid: props.proposal_section.uuid,
    });
  };

  const [loadingAddItems, setLoadingAddItems] = useState(false);

  const addItems = (items) => {
    setLoadingAddItems(true);
    addProposalItems(
      {
        items: items,
        proposal_section_uuid: props.proposal_section.uuid,
      },
      () => setLoadingAddItems(false)
    );
  };

  const removeItem = (uuid) => {
    removeProposalItem(props.proposal_section.uuid, uuid);
  };

  const handleDragEnd = (result) => {
    handleProposalItemDragEnd(props.proposal_section.uuid, result);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      rowSpacing={1}
      sx={{ m: ".5rem" }}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        columnSpacing={1}
      >
        <Grid
          item
          xs="auto"
          container
          justifyContent={"flex-end"}
          columnSpacing={1}
        >
          {!props.profileSettings && (
            <Grid item xs="auto">
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                onClick={setAddingHardGoodTrue}
                disabled={
                  props.proposal_section.proposal_items.length >= max_items
                }
                loading={loadingAddItems}
              >
                <AddOutlinedIcon /> Hard Good
              </LoadingButton>
              {addingHardGood && (
                <AddItemDialog
                  open={addingHardGood}
                  handleCancel={setAddingHardGoodFalse}
                  addItems={addItems}
                  filter_items={props.proposal_section.proposal_items.map(
                    (item) => item.item
                  )}
                  max_selectable={
                    max_items - props.proposal_section.proposal_items.length
                  }
                  item_type="MA"
                />
              )}
            </Grid>
          )}
          {!props.profileSettings && (
            <Grid item xs="auto">
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                onClick={setAddingFlowerTrue}
                disabled={
                  props.proposal_section.proposal_items.length >= max_items
                }
                loading={loadingAddItems}
              >
                <AddOutlinedIcon /> Flower
              </LoadingButton>
              {addingFlower && (
                <AddItemDialog
                  open={addingFlower}
                  handleCancel={setAddingFlowerFalse}
                  addItems={addItems}
                  filter_items={props.proposal_section.proposal_items.map(
                    (item) => item.item
                  )}
                  max_selectable={
                    max_items - props.proposal_section.proposal_items.length
                  }
                  item_type="FL"
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        columnSpacing={3}
        justifyContent={
          props.proposal_section.style?.items_align || DefaultStyles.itemsAlign
        }
      >
        <DraggableList
          list={props.proposal_section.proposal_items.sort(sort_by_order)}
          listContainerComponent={Grid}
          listContainerComponentProps={{
            container: true,
            item: true,
            xs: 12,
            id: "proposal-items-grid",
            spacing: 3,
            justifyContent:
              props.proposal_section.style?.items_align ||
              DefaultStyles.itemsAlign,
          }}
          listItemComponent={ProposalItemGrid}
          listItemComponentProps={{
            proposal_section: props.proposal_section,
          }}
          onDragEnd={handleDragEnd}
          listItemPropName="proposal_item"
        />
        {!props.profileSettings && (
          <Grid item xs={12} container justifyContent={"center"}>
            <Grid item xs="auto">
              <Typography variant="caption" color="info.main">
                drag and drop to reorder
              </Typography>
            </Grid>
          </Grid>
        )}
        {props.profileSettings &&
          [...Array(3).keys()].map((i) => (
            <Grid item xs={4} sm={3} md={2} key={i}>
              <Skeleton
                variant="circular"
                width={100}
                height={100}
                animation={false}
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

const ProposalItemGrid = (props) => {
  return (
    <Grid
      item
      xs={4}
      sm={3}
      md={2}
      key={props.proposal_item.uuid}
      display="flex"
      {...props}
      sx={
        props.isPlaceholder && { border: "2px dashed #999", minHeight: "40px" }
      }
    >
      {!props.isPlaceholder && (
        <ProposalItem
          proposal_item={props.proposal_item}
          proposal_section={props.proposal_section}
        />
      )}
    </Grid>
  );
};

const ProposalItem = (props) => {
  const [hover, setHover] = useState(false);
  const setHoverTrue = () => {
    setHover(true);
  };
  const setHoverFalse = () => {
    setHover(false);
  };
  const [removeProposalItem] = useAccountState((state) => [
    state.removeProposalItem,
  ]);
  const handleDelete = () => {
    removeProposalItem(props.proposal_section.uuid, props.proposal_item.uuid);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: 0,
        marginBottom: "100%",
        position: "relative",
      }}
      id="item-icon-wrapper"
      onMouseEnter={setHoverTrue}
      onMouseLeave={setHoverFalse}
    >
      <ItemIcon item={props.proposal_item.item} />
      {hover && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <IconButton onClick={handleDelete} color="error">
            <ClearOutlinedIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export const ProposalItemsPreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={0.5}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <Skeleton
          variant="rounded"
          width={50}
          height={5}
          sx={{ mt: 3.5 }}
          animation={false}
        />
      </Grid>
      {[...Array(5).keys()].map((i) => (
        <Grid item xs="auto">
          <Skeleton
            variant="circular"
            width={10}
            height={10}
            animation={false}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const ProposalItemsView = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container justifyContent="flex-start" rowSpacing={2}>
      <Grid
        item
        xs={12}
        container
        spacing={3}
        justifyContent={
          props.proposal_section.style?.items_align || DefaultStyles.itemsAlign
        }
      >
        {props.proposal_section.proposal_items
          .sort(sort_by_order)
          .map((proposal_item) => (
            <Grid item xs={4} sm={2} md={2} key={proposal_item.uuid}>
              <ItemIcon item={proposal_item.item} />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

const ItemIcon = (props) => {
  const [imageLoadError, setImageLoadError] = useState(false);

  const handleError = () => {
    setImageLoadError(true);
  };

  const DefaultComponent =
    props.item.item_type === "FL" ? DefaultFlower : DefaultMaterial;
  return (
    <Box id="item-icon-root">
      <Tooltip title={itemDisplayName(props.item)} id="tooltip">
        <span id="tooltip-span">
          <Box
            sx={{
              width: "100%",
              height: 0,
              paddingTop: "100%",
              position: "relative",
            }}
            id="cropped-img-box"
          >
            {!imageLoadError ? (
              <img
                alt=""
                src={twicpic_url(
                  props.item.thumbnail
                    ? props.item.thumbnail
                    : props.item.thumbnail_url,
                  {
                    width: props.width,
                    height: props.height,
                  }
                )}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                onError={handleError}
              />
            ) : (
              <DefaultComponent item={props.item} key={props.key} />
            )}
          </Box>
        </span>
      </Tooltip>
    </Box>
  );
};

const item_colors = itemColors("MA");

const DefaultFlower = (props) => {
  const [item_color, setItemColor] = useState(undefined);

  const getItemColor = () => {
    var color;
    for (color of item_colors) {
      if (props.item && props.item[color.value]) {
        setItemColor(color.color_code);
      }
    }
  };

  useEffect(getItemColor, [props.item]);
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "50%",
      }}
    >
      <Box sx={{ p: "1rem" }}>
        <FilterVintageIcon
          htmlColor={item_color}
          sx={{ width: "100%", height: "100%" }}
          key={props.key}
        />
      </Box>
    </Box>
  );
};

const DefaultMaterial = (props) => {
  const [item_color, setItemColor] = useState(undefined);

  const getItemColor = () => {
    var color;
    for (color of item_colors) {
      if (props.item && props.item[color.value]) {
        setItemColor(color.color_code);
      }
    }
  };

  useEffect(getItemColor, [props.item]);
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "50%",
      }}
    >
      <Box sx={{ p: "1rem" }}>
        <EmojiEventsIcon
          htmlColor={item_color}
          sx={{ width: "100%", height: "100%" }}
          key={props.key}
        />
      </Box>
    </Box>
  );
};
