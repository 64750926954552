import { Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAccountState } from "../state/store";
import { formatStringForId } from "../utils/utils";

export const RecipeLabor = (props) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [updateEventItem] = useAccountState((state) => [state.updateEventItem]);

  return (
    <Grid container alignItems="center" rowSpacing={1}>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        columnSpacing={2}
      >
        <ColumnHeader width={4} value={""} />
        <ColumnHeader width={2} value={"minutes"} />
        {!fullScreen && (
          <>
            <ColumnHeader width={2} value={"cost"} />
            <ColumnHeader width={2} value={"retail"} />
            <ColumnHeader width={1} value={""} />
          </>
        )}
      </Grid>
      <LaborRow id="build" label="Build" event_item={props.event_item} />
      <LaborRow id="install" label="Install" event_item={props.event_item} />
    </Grid>
  );
};

const LaborRow = (props) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const labor_cost_key = `${props.id}_labor_cost_hourly`;
  const labor_estimate_key = `${
    props.id === "build" ? "build" : "instalation"
  }_labor_time_estimate_minutes`;
  const [back_office, formatCurrency, recipeLaborRetailPrice, updateEventItem] =
    useAccountState((state) => [
      state.back_office,
      state.formatCurrency,
      state.recipeLaborRetailPrice,
      state.updateEventItem,
    ]);
  const handleLaborUpdate = (e) => {
    if (e.target.value !== "") {
      updateEventItem({
        uuid: props.event_item.uuid,
        [labor_estimate_key]: e.target.value,
      });
    }
  };

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      columnSpacing={2}
      justifyContent={"space-between"}
    >
      <Grid item xs={2} sm={4}>
        <Typography>{props.label}</Typography>
      </Grid>
      <Grid item xs={3} sm={2}>
        <TextField
          value={props.event_item[labor_estimate_key]}
          onChange={handleLaborUpdate}
          variant="outlined"
          fullWidth
          size="small"
          id="unit_labor_estimate_minutes"
          sx={{ maxWidth: "90px" }}
          inputProps={{
            id: formatStringForId(
              props.event_item.name +
                `-${props.id}-labor-estimate-minutes-field`
            ),
            sx: { pt: "4px", pb: "4px" },
          }}
        />
      </Grid>
      {!fullScreen && (
        <>
          <Grid item xs={2}>
            <Typography>
              {formatCurrency(
                back_office && back_office[labor_cost_key]
                  ? back_office[labor_cost_key]
                  : props.event_item.template_back_office[labor_cost_key]
              )}
              /hr
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              {formatCurrency(
                recipeLaborRetailPrice(props.event_item, props.id)
              )}
              /hr
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </>
      )}
    </Grid>
  );
};

const ColumnHeader = (props) => {
  return (
    <Grid item xs={props.width} container>
      <Grid item xs="auto">
        <Typography variant="caption">{props.value}</Typography>
      </Grid>
    </Grid>
  );
};
