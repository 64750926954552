import React from "react";
import { withRouter } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

import Moment from "moment";

import { useTheme } from "@mui/material/styles";

import { useMediaQuery } from "@mui/material";

import {
  createEventContractApi,
  getEsigContractDetailsApi,
} from "../api/ContractsApi.js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Box,
} from "@mui/material";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useAccountState } from "../state/store.js";
import { getPaymentRelativeDate } from "../utils/payment_utils.js";

const ConfirmIdentityDialog = (props) => {
  const [
    allEventItems,
    clients,
    event,
    eventDate,
    eventDateFormatted,
    eventPrice,
    eventPriceSubtotal,
    finalPaymentAmount,
    flowerbuddyFeeCost,
    initializeProposal,
    otherCostsPriceWithTax,
    paymentAmount,
    proposal,
    recipePriceWithTax,
    recipes,
    sortPayments,
    taxLines,
  ] = useAccountState((state) => [
    state.allEventItems,
    state.clients,
    state.event,
    state.eventDate,
    state.eventDateFormatted,
    state.eventPrice,
    state.eventPriceSubtotal,
    state.finalPaymentAmount,
    state.flowerbuddyFeeCost,
    state.initializeProposal,
    state.otherCostsPriceWithTax,
    state.paymentAmount,
    state.proposal,
    state.recipePriceWithTax,
    state.recipes,
    state.sortPayments,
    state.taxLines,
  ]);
  const [submitting, setSubmitting] = React.useState(false);
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(2, "First name must be at least 2 characters.")
      .max(50, "First name cannot contain more than 50 characters")
      .required("Must provie a first name."),
    last_name: Yup.string()
      .min(2, "Last name must be at least 2 characters.")
      .max(50, "Last name cannot contain more than 50 characters")
      .required("Must provie a last name."),
    email: Yup.string()
      .email()
      .min(2, "email must be at least 2 characters.")
      .max(100, "email cannot contain more than 100 characters")
      .required("Must provie a email."),
  });

  const nName = (name, n) => {
    const name_vec = name.split(" ").filter((v) => v);
    if (n < 0) {
      n = name_vec.length + n;
    }
    console.log(name_vec);
    if (name_vec.length > 0) {
      return name_vec[n];
    } else {
      return "";
    }
  };

  const firstName = (name) => {
    return nName(name, 0);
  };

  const lastName = (name) => {
    return nName(name, -1);
  };

  const getSigner = () => {
    let signers = clients.filter((client) => client.is_signer && client.email);
    if (signers.length > 0) {
      return signers[0];
    } else {
      return null;
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: getSigner()
      ? {
          first_name: firstName(getSigner().name),
          last_name: lastName(getSigner().name),
          email: getSigner().email,
        }
      : { first_name: null, last_name: null, email: null },
    onSubmit: (values) => {
      setSubmitting(true);
      var payments = sortPayments().map((payment) => ({
        name: payment.name,
        amount: paymentAmount(payment, false).amount,
        due_date: getPaymentRelativeDate(payment),
      }));
      payments.push({
        name: "Final Payment",
        amount: finalPaymentAmount(false).amount,
        due_date: getPaymentRelativeDate(event.payment_schedule),
      });
      createEventContractApi({
        client: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
        },
        proposal_uuid: proposal.uuid,
        tax: taxLines(false),
        subtotal: eventPriceSubtotal(),
        total: eventPrice(false),
        other_costs: otherCostsPriceWithTax(false),
        flowerbuddy_fee_cost: flowerbuddyFeeCost(),
        key_event_date: eventDateFormatted(),
        recipe_prices: allEventItems().map((recipe) => ({
          uuid: recipe.uuid,
          price: recipePriceWithTax(recipe, false),
        })),
        payments: payments,
      }).then((resp) => {
        initializeProposal(resp.data);
        setSubmitting(false);
        props.Sign();
        props.handleCancel();
      });
    },
  });

  const formDisabled = () => {
    return proposal.contracts.length > 0;
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Document Signing Confirmation</DialogTitle>
      <DialogContent>
        <Typography>
          Are you ready to sign your event contract? Once you sign your
          contract, you will be responsible for paying your event deposit.
        </Typography>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormField
                name="first_name"
                label="First Name"
                formik={formik}
                disabled={formDisabled()}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name="last_name"
                label="Last Name"
                formik={formik}
                disabled={formDisabled()}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name="email"
                label="Email"
                formik={formik}
                disabled={formDisabled()}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {!submitting ? (
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                onClick={props.handleCancel}
                variant="outlined"
                color="info"
              >
                Not Ready Yet
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={formik.handleSubmit}
                variant="contained"
                color="secondary"
              >
                Sign Contract
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

export const FormField = (props) => {
  return (
    <TextField
      {...props}
      id={props.name}
      value={props.formik.values[props.name]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.name] &&
        Boolean(props.formik.errors[props.name])
      }
      helperText={
        props.formik.touched[props.name] && props.formik.errors[props.name]
      }
      variant="filled"
      fullWidth
    />
  );
};

export const EmbeddedSignDialog = (props) => {
  const { ButtonComponent } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [pdfUrl, setPdfUrl] = React.useState(undefined);
  const [gettingPdfUrl, setGettingPdfUrl] = React.useState(true);

  const getIframeUrl = () => {
    if (props.signed) {
      if (!pdfUrl) {
        getEsigContractDetailsApi(props.document_id, props.florist).then(
          (resp) => {
            setPdfUrl(resp.data.contract_pdf_url);
            setGettingPdfUrl(false);
            return resp.data.contract_pdf_url;
          }
        );
      } else {
        return pdfUrl;
      }
    } else {
      return props.sign_page_url;
    }
  };

  return (
    <Box sx={{ ml: "auto" }}>
      {!ButtonComponent &&
        (!fullScreen ? (
          <Button
            variant={props.signed ? "outlined" : "contained"}
            color={props.signed ? "info" : "secondary"}
            size="small"
            startIcon={
              props.signed ? (
                <SearchOutlinedIcon fontSize="inherit" />
              ) : (
                <EditOutlinedIcon fontSize="inherit" />
              )
            }
            onClick={props.Sign}
          >
            {props.signed ? "View" : "Sign"}
          </Button>
        ) : (
          <IconButton
            // variant={props.signed ? "outlined" : "contained"}
            color={props.signed ? "info" : "secondary"}
            size="small"
            onClick={props.Sign}
          >
            {props.signed ? (
              <SearchOutlinedIcon fontSize="inherit" />
            ) : (
              <EditOutlinedIcon fontSize="inherit" />
            )}
          </IconButton>
        ))}
      {ButtonComponent && <ButtonComponent onClick={props.Sign} />}
      <Dialog open={props.open} onClose={props.handleCancel} fullScreen>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              eSign Contract
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={props.handleCancel}>
                <CloseOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <iframe
            src={getIframeUrl()}
            id="eSignaturesIOIframe"
            style={{
              width: "100%",
              height: "99%",
            }}
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={props.handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default withRouter(ConfirmIdentityDialog);
