export function getFilenameFromUrl(url) {
  const urlObject = new URL(url);
  let filename = urlObject.pathname
    .split("/")
    .pop()
    .split("#")[0]
    .split("?")[0];
  const lastDashIndex = filename.lastIndexOf("-");
  if (lastDashIndex === -1) {
    return filename;
  } else {
    return filename.substring(0, lastDashIndex);
  }
}
