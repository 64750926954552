import React from "react";
import { Box, TextField, IconButton, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Root = styled(Box)(({ height, theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  height: height,
  boxSizing: "border-box",
  border: "1px solid rgba(0,0,0,0.23)",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
}));

const InputContainer = styled(Box)({
  flex: 1,
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  padding: "0 4px",
});

const Divider = styled(Box)({
  width: "1px",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.23)",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  height: "100%",
  boxSizing: "border-box",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  flex: 1,
  minWidth: 0,
  minHeight: 0,
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  borderRadius: 0,
}));

export const QuantityInputMobile = ({
  label,
  value,
  onChange,
  onBlur,
  height = 25,
  increase,
  decrease,
  ...props
}) => {
  const fontSize = height <= 25 ? "0.75rem" : "0.875rem";
  const iconSize = height <= 25 ? "0.75rem" : "0.875rem";

  return (
    <Root height={height}>
      {/* Minus button on the left */}
      <ButtonContainer>
        <StyledIconButton
          onClick={decrease}
          aria-label="decrement"
          sx={{
            "& svg": {
              fontSize: iconSize,
            },
          }}
        >
          <RemoveIcon />
        </StyledIconButton>
      </ButtonContainer>

      <Divider />

      {/* Text field in the center */}
      <InputContainer>
        <TextField
          variant="standard"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize,
              lineHeight: 1,
              padding: 0,
              height: "100%",
              display: "flex",
              alignItems: "center",
            },
          }}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              display: "none",
            },
          }}
          {...props}
        />
      </InputContainer>

      <Divider />

      {/* Plus button on the right */}
      <ButtonContainer>
        <StyledIconButton
          onClick={increase}
          aria-label="increment"
          sx={{
            "& svg": {
              fontSize: iconSize,
            },
          }}
        >
          <AddIcon />
        </StyledIconButton>
      </ButtonContainer>
    </Root>
  );
};
