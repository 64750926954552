import { format } from "date-fns";
import { Decimal } from "decimal.js";
import _ from "lodash";
import { enUS } from "date-fns/locale";

import * as Locales from "date-fns/locale";
import { sort } from "fast-sort";

export const decimalConfig = {
  precision: 20, // Set a higher precision. It should be at least number of significant digits you expect in calculations.
  rounding: Decimal.ROUND_HALF_UP, // This is standard for financial and monetary calculations.
};

Decimal.set(decimalConfig);

export function sortByKey(key, reverse = false) {
  let r = reverse ? -1 : 1;
  return function (a, b) {
    if (a[key] < b[key]) {
      return -1 * r;
    } else if (a[key] > b[key]) {
      return 1 * r;
    } else {
      return 0;
    }
  };
}

export function sortWithGetter(getter, reverse = false) {
  let r = reverse ? -1 : 1;
  return function (a, b) {
    if (getter(a) < getter(b)) {
      return -1 * r;
    } else if (getter(a) > getter(b)) {
      return 1 * r;
    } else {
      return 0;
    }
  };
}

export const sort_by_order = sortByKey("order");

export const sort_by_relevance = sortByKey("relevance", true);

export const sortByName = sortByKey("name");

export const sortByDate = sortByKey("date", true);

export const sortByLabel = sortByKey("label");

export const sortBySubmittedOn = sortByKey("submitted_on", true);

export const sortByCreated = sortByKey("created", true);

export const sortByUuid = sortByKey("uuid");

export const sortItemCountRecipesByName = (a, b) => {
  if (a.recipe.name < b.recipe.name) {
    return -1;
  } else if (a.recipe.name > b.recipe.name) {
    return 1;
  } else {
    return 0;
  }
};

const getEventItemGroupOrder = (recipe, recipes) => {
  let event_item_group = recipes.find(
    (r) => r.uuid === recipe.event_item_group
  );
  return event_item_group ? event_item_group.order : recipe.order;
};

export const sortEventItemsAndSubItems = (event_items) => {
  return sort(event_items).by([
    { asc: (r) => getEventItemGroupOrder(r, event_items) },
    { asc: (r) => (r.event_item_group ? r.order : -1) },
  ]);
};

export const sortItemsByLocation = (items) => {
  return sort(items).by([
    { asc: (o) => o.inventory_address.name },
    { asc: (o) => o.name },
  ]);
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export function format_currency(val) {
  if (Object.is(val, -0)) {
    return formatter.format(0);
  } else {
    return formatter.format(val);
  }
}

export const format_percent = (fraction) => {
  if (fraction) {
    let percent = Number.parseFloat(fraction) * 100;
    return percent.toFixed(2) + "%";
  } else {
    return undefined;
  }
};

export function format_utc_timestamp(val) {
  return format(new Date(val * 1000), "MM/dd/yy");
}

export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function formatDate(date, locale) {
  if (date && date != null) {
    return format(new Date(date), "P", {
      locale: locale === undefined ? enUS : locale,
    });
  }
}

export function formatDateWithTime(date, locale) {
  if (date && date != null) {
    return format(new Date(date), "Pp", {
      locale: locale === undefined ? enUS : locale,
    });
  }
}

export const validateEmail = (email) => {
  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  return expression.test(String(email).toLowerCase());
};

export const validateName = (name) => {
  const expression = /^[ a-zA-Z]+$/g;

  return (
    expression.test(String(name).toLocaleLowerCase()) && String(name).length > 1
  );
};

export function amendableField(recipe, field, ignore_amendments) {
  if (
    recipe["amend_" + field] !== null &&
    recipe[field] !== recipe["amend_" + field] &&
    !ignore_amendments
  ) {
    return recipe["amend_" + field];
  } else {
    return recipe[field];
  }
}

export function quantityField(recipe, ignore_amendments) {
  return amendableField(recipe, "quantity", ignore_amendments);
}

export function quantityFieldDec(recipe, ignore_amendments) {
  return DecimalWrapper(
    parseFloat(amendableField(recipe, "quantity", ignore_amendments))
  );
}

export function priceField(recipe, ignore_amendments) {
  return amendableField(recipe, "price", ignore_amendments);
}
export function priceFieldDec(recipe, ignore_amendments) {
  return DecimalWrapper(amendableField(recipe, "price", ignore_amendments));
}
export function isOptionalField(recipe, ignore_amendments) {
  return amendableField(recipe, "is_optional", ignore_amendments);
}
export function isPercentField(recipe, ignore_amendments) {
  return amendableField(recipe, "is_percent", ignore_amendments);
}
export function isSelectedField(recipe, ignore_amendments) {
  return amendableField(recipe, "is_selected", ignore_amendments);
}
export function percentField(recipe, ignore_amendments) {
  return DecimalWrapper(amendableField(recipe, "percent", ignore_amendments));
}
export function markupPercentageField(recipe, ignore_amendments) {
  return DecimalWrapper(
    amendableField(recipe, "markup_percentage", ignore_amendments)
  );
}
export function percentFieldWithMarkup(recipe, ignore_amendments) {
  let percent = DecimalWrapper(
    amendableField(recipe, "percent", ignore_amendments)
  );
  return percent.plus(
    percent.times(
      markupPercentageField(recipe, ignore_amendments).dividedBy(100)
    )
  );
}
export function salesTaxField(recipe, ignore_amendments) {
  return amendableField(recipe, "sales_tax", ignore_amendments);
}

export function dateField(event, ignore_amendments) {
  return amendableField(event, "date", ignore_amendments);
}

export function roundToNearest(val, target) {
  if (target === 0) {
    return val;
  } else {
    return Math.ceil(val / target) * target;
  }
}

export function twoDec(val) {
  if (val > 0 || val < 0) {
    return Math.round(val * 1e2) / 1e2;
  } else {
    return 0;
  }
}

export function getUUIDsFromList(list) {
  return list.map((item) => item.uuid);
}

export function byUUID(uuid) {
  return (item) => item.uuid === uuid;
}

export function orderByDate(a, b) {
  return new Date(a.date_modified) - new Date(b.date_modified);
}

export function orderByField(field) {
  return (a, b) => {
    if (a[field] < b[field]) {
      return -1;
    } else if (a[field] > b[field]) {
      return 1;
    } else {
      return 0;
    }
  };
}

export function formatPhoneNumber(phone_number) {
  if (!phone_number) {
    return phone_number;
  } else if (phone_number.length === 10) {
    return phone_number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else if (phone_number.length === 11 && phone_number[0] === "1") {
    return phone_number.replace(
      /(\d{1})(\d{3})(\d{3})(\d{4})/,
      "$1 ($2) $3-$4"
    );
  } else {
    return phone_number;
  }
}

export function DecimalWrapper(value) {
  if (value === null || value === undefined) {
    return new Decimal(0);
  }
  try {
    return new Decimal(value);
  } catch (e) {
    return new Decimal(0);
  }
}

export function DecimalPercent(value) {
  return DecimalWrapper(value).dividedBy(100);
}

export function printKeyAndValue(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      console.log(`Key: ${key}, Value: ${obj[key].toString()}`);
    }
  }
}

export function formatStringForId(str) {
  return str.replace(/[^a-zA-Z0-9]/g, "-");
}

export function generateRandomString(N) {
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";
  for (var i = 0; i < N; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }
  return randomString;
}

export function haveCommonKey(obj1, obj2) {
  // Get the keys of the first object
  const keysObj1 = Object.keys(obj1);

  // Iterate over the keys of obj1 and check if any key is present in obj2
  for (let i = 0; i < keysObj1.length; i++) {
    if (keysObj1[i] in obj2) {
      // If a common key is found, return true
      return true;
    }
  }

  // If no common keys are found, return false
  return false;
}

export function externalUrl(url) {
  return url.match(/^http/) ? url : "http://" + url;
}

export function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export const snapshot = (...list_of_args) => {
  let args = list_of_args.map((arg) => _.cloneDeep(arg));
  console.log(...args);
};

export const snapshotEventItems = (label, event_items) => {
  let event_items_clone = _.cloneDeep(event_items);
  event_items_clone = event_items_clone.map((item) => {
    let { uuid, event_item_type, order, name } = item;
    return {
      uuid: uuid.slice(0, 4),
      event_item_type,
      order,
      name,
    };
  });
  console.log(label, event_items_clone);
};

export const loadLocaleObj = async (language) => {
  if (!language) {
    return enUS;
  }
  try {
    const locale = await import(`date-fns/locale/${language}/index.js`);
    return locale;
  } catch (error) {
    try {
      const locale = await import(
        `date-fns/locale/${language.substring(0, 2)}/index.js`
      );
      return locale;
    } catch (error2) {
      console.warn(
        `Locale ${language} not found, falling back to en-US.`,
        error,
        error2
      );
      return enUS;
    }
  }
};

export const loadLocale = async (language) => {
  let locale = await loadLocaleObj(language);
  return locale.default;
};

export function toProperCase(str) {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter
    .join(" "); // Join the words back into a single string
}
