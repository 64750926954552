import React from "react";
import { withRouter } from "react-router-dom";

import { useFormik } from "formik";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { getVendorDefaults } from "../api/EventsApi";

import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
  IconButton,
  InputLabel,
} from "@mui/material";
import ItemPriceFields from "./ItemPriceFields";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ItemIcon } from "./ItemIcon";
import {
  flower_type_options,
  flowerUnitOptions,
  itemColors,
  itemTypes,
  materialUnitOptions,
  season_options,
} from "../utils/item_utils";
import { useAccountState } from "../state/store";
import { sortByLabel } from "../utils/utils";
import { FileDropZoneDialog } from "./FileUploader";

const CreateItemDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [createItem] = useAccountState((state) => [state.createItem]);
  const [initial_vendor_location, setInitialVendorLocation] =
    React.useState(null);
  const [uploadingFile, setUploadingFile] = React.useState(false);

  const isFlower = () => {
    return props.item_type === "FL";
  };

  const item_name = isFlower() ? "Flower" : "Hard Good";
  var color_options = itemColors(props.item_type);

  const formatValuesLists = (initial_values) => {
    var color = [];
    color_options.forEach((color_option) => {
      if (initial_values[color_option.value]) {
        color.push(color_option);
      }
    });
    var season = [];
    season_options.forEach((season_option) => {
      if (initial_values[season_option.value]) {
        season.push(season_option);
      }
    });
    var flower_type = [];
    var type_options = itemTypes(props.item_type);
    type_options.forEach((type_option) => {
      if (initial_values[type_option.value]) {
        flower_type.push(type_option);
      }
    });

    return {
      ...initial_values,
      color: color,
      season: season,
      flower_type: flower_type,
    };
  };

  const default_flower_values = {
    name: "",
    color_name: "",
    thumbnail_url: "",
    thumbnail: "",
    unit_name: "ST",
    unit_labor_estimate_minutes: "0.5",
    group_name: "BU",
    minimum_group_quantity: "1",
    flower_type: [],
    season: [season_options.find((season) => season.value === "all_seasons")],
    color: [],
    vendor_location: initial_vendor_location,
    item_type: "FL",
  };
  const default_material_values = {
    disposable: false,
    type: "BU",
    color: [],
    price_per_group: "0",
    individual_per_group: "1",
    minimum_group_quantity: "1",
    unit_name: "ST",
    item_type: "MA",
    group_name: "BO",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.initialValues
      ? formatValuesLists(props.initialValues)
      : isFlower()
      ? default_flower_values
      : default_material_values,
    onSubmit: (values, { resetForm }) => {
      if (props.initialValues) {
        props.handleSubmit(values);
      } else {
        createItem(values, props.handleSubmit);
      }
      props.handleCancel();
      resetForm();
    },
  });

  const initializeForm = () => {
    getVendorDefaults().then((resp) => {
      setInitialVendorLocation(
        resp.data.length > 0 ? resp.data[0].vendor_location : undefined
      );
      formik.setFieldValue(
        "vendor_location",
        resp.data.length > 0 ? resp.data[0].vendor_location : undefined
      );
    });
  };

  const handleUploadedFile = (cropped_images) => {
    formik.setFieldValue("thumbnail", cropped_images[0]);
  };

  React.useEffect(() => {
    formik.resetForm();
    initializeForm();
  }, []);

  const sortMaterialType = (a, b) => {
    if (a[1] > b[1]) {
      return 1;
    } else if (a[1] < b[1]) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    <Dialog
      onClose={props.handleCancel}
      open={props.open}
      fullScreen={fullScreen}
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {props.initialValues ? `Edit  ${item_name}` : `Create New ${item_name}`}
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ ml: "auto", mr: "auto" }} rowSpacing="1rem">
          <Grid item xs={12}>
            <Typography variant="h6">{`${item_name} Details`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label={isFlower() ? "Flower Type" : "Name"}
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              variant="outlined"
              fullWidth
              disabled={props.disableName}
            />
          </Grid>
          {isFlower() ? (
            <Grid item xs={12}>
              <TextField
                name="color_name"
                label="Color/Brand"
                id="color_name"
                value={formik.values.color_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.color_name && Boolean(formik.errors.color_name)
                }
                helperText={
                  formik.touched.color_name && formik.errors.color_name
                }
                variant="outlined"
                fullWidth
                disabled={props.disableColorName}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontSize: ".75rem",
                }}
              >
                Type
              </InputLabel>
              <Select
                name="type"
                label="Type"
                id="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                variant="standard"
                fullWidth
                disabled={props.disableType}
              >
                {Object.entries(materialUnitOptions)
                  .sort(sortByLabel)
                  .map(([key, option]) => (
                    <MenuItem key={key} value={key}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          )}

          <Grid item xs={12} container>
            {formik.values.thumbnail ? (
              <>
                <Grid item xs={10}>
                  <ItemIcon item={formik.values} />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      formik.setFieldValue("thumbnail", "");
                    }}
                    id="delete-thumbnail"
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Button
                variant="outlined"
                color="info"
                fullWidth
                onClick={() => setUploadingFile(true)}
                id="upload-thumbnail"
              >
                Upload Thumbnail
              </Button>
            )}
            <FileDropZoneDialog
              open={uploadingFile}
              handleCancel={() => setUploadingFile(false)}
              handleSubmit={handleUploadedFile}
              maxSelections={1}
              aspect={1}
              forceRecrop
            />
          </Grid>
          {isFlower() ? (
            <>
              <Grid item xs={12}>
                <TextField
                  name="unit_labor_estimate_minutes"
                  label="Processing Time Estimate (minutes)"
                  id="unit_labor_estimate_minutes"
                  value={formik.values.unit_labor_estimate_minutes}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.unit_labor_estimate_minutes &&
                    Boolean(formik.errors.unit_labor_estimate_minutes)
                  }
                  helperText={
                    formik.touched.unit_labor_estimate_minutes &&
                    formik.errors.unit_labor_estimate_minutes
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="unit_name"
                  label="Individual Name"
                  id="unit_name"
                  value={formik.values.unit_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.unit_name && Boolean(formik.errors.unit_name)
                  }
                  helperText={
                    formik.touched.unit_name && formik.errors.unit_name
                  }
                  variant="outlined"
                  fullWidth
                >
                  {Object.entries(flowerUnitOptions).map(([key, option]) => (
                    <MenuItem key={key} value={key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={formik.values.flower_type}
                  onChange={(e, val) => {
                    formik.setFieldValue("flower_type", val);
                  }}
                  error={
                    formik.touched.flower_type &&
                    Boolean(formik.errors.flower_type)
                  }
                  multiple
                  id="flower_type"
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  options={flower_type_options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Floral Category (select all that apply)"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={formik.values.season}
                  onChange={(e, val) => {
                    formik.setFieldValue("season", val);
                  }}
                  error={formik.touched.season && Boolean(formik.errors.season)}
                  multiple
                  id="season"
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  options={season_options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Seasonality (select all that apply)"
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <FormikTextField
                  formik={formik}
                  label="Product Page Url"
                  id="page_url"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  sx={{
                    fontSize: ".75rem",
                  }}
                >
                  Disposable
                </InputLabel>
                <Select
                  name="disposable"
                  label="Disposable"
                  id="disposable"
                  value={formik.values.disposable}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.disposable &&
                    Boolean(formik.errors.disposable)
                  }
                  helperText={
                    formik.touched.disposable && formik.errors.disposable
                  }
                  variant="standard"
                  fullWidth
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  formik={formik}
                  label="Length (inches)"
                  id="length"
                  fullWidth
                  type="number"
                  inputProps={{ min: "0", step: "0.01" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  formik={formik}
                  label="Width (inches)"
                  id="width"
                  fullWidth
                  type="number"
                  inputProps={{ min: "0", step: "0.01" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  formik={formik}
                  label="Height (inches)"
                  id="height"
                  fullWidth
                  type="number"
                  inputProps={{ min: "0", step: "0.01" }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Autocomplete
              value={formik.values.color}
              onChange={(e, val) => {
                formik.setFieldValue("color", val);
              }}
              error={formik.touched.color && Boolean(formik.errors.color)}
              multiple
              id="color"
              renderTags={(values, getTagProps) => {
                return values.map((value, ind) => (
                  <Box
                    key={value.color_code}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    {...getTagProps(value)}
                  >
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        mr: ".25rem",
                        backgroundColor: value.color_code,
                      }}
                    ></Box>
                    <Typography>{value.label}</Typography>
                  </Box>
                ));
              }}
              renderOption={(props, option) => {
                return [
                  null,
                  <Box
                    key={option.color_code}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    {...props}
                  >
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        mr: ".25rem",
                        backgroundColor: option.color_code,
                      }}
                    ></Box>
                    <Typography>{option.label}</Typography>
                  </Box>,
                ];
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              options={color_options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Colors (select all that apply)"
                />
              )}
            />
          </Grid>
          {!props.disablePricing ? (
            <Grid item xs={12}>
              <Typography variant="h6">{`${item_name} Pricing`}</Typography>
            </Grid>
          ) : null}
        </Grid>
        {!props.disablePricing ? (
          <ItemPriceFields
            maxWidth="300px"
            item={{
              item: {
                unit_name: formik.values.unit_name,
                name: formik.values.name,
                color_name: formik.values.color_name,
                type: formik.values.type,
                item_type: props.item_type,
              },
            }}
            formik={formik}
            event_version={props.event_version}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Grid container>
          {props.initialValues ? (
            <Grid item xs={12}>
              <Typography color="error.main">
                Warning: This update will affect all events that use this item.
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={6} display="flex">
            <Button
              onClick={props.handleCancel}
              size="small"
              sx={{ ml: "auto", mr: "auto" }}
              id="cancel-item-button"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} display="flex">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              sx={{ ml: "auto", mr: "auto" }}
              onClick={formik.handleSubmit}
              id="save-item-button"
            >
              {props.initialValues
                ? `Save ${item_name}`
                : `Create ${item_name}`}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export const FormikTextField = (props) => {
  return (
    <TextField
      name={props.id}
      value={props.formik.values[props.id]}
      onChange={props.formik.handleChange}
      onBlur={props.formik.handleBlur}
      error={props.formik.touched[props.id] && props.formik.errors[props.id]}
      helperText={
        props.formik.touched[props.id] && props.formik.errors[props.id]
      }
      variant="standard"
      {...props}
    />
  );
};

export default withRouter(CreateItemDialog);
