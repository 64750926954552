import React, { useState, useRef, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { propsToClassKey } from "@mui/styles";

export const ExpandablePreview = ({
  children,
  previewHeight = 200,
  overrideExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const isOverflowingNow = contentRef.current.scrollHeight > previewHeight;
      setIsOverflowing(isOverflowingNow);
    }
  }, [children, previewHeight]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const expanded = () => {
    return isExpanded || overrideExpanded;
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        ref={contentRef}
        sx={{
          maxHeight: expanded() ? "none" : previewHeight,
          overflow: "hidden",
          width: "100%",
          position: "relative",
        }}
      >
        {children}
        {!expanded() && isOverflowing && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "40px", // Adjust fade height as needed
              background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)`,
            }}
          />
        )}
      </Box>
      {isOverflowing && (
        <Button
          onClick={toggleExpand}
          variant="text"
          sx={{ textTransform: "none", marginTop: "8px" }}
          color="info"
        >
          {expanded() ? "Show Less" : "Show More"}
        </Button>
      )}
    </Box>
  );
};
