export const locationNameParts = (location) => {
  var parts = [location.name];
  if (location.google_address) {
    parts.push(location.google_address?.structured_formatting?.secondary_text);
  } else if (location.address_line_1) {
    parts.push(location.address_line_1);
  }
  return parts;
};

export const locationAddress = (location) => {
  let parts = locationNameParts(location);
  if (parts.length > 1) {
    return parts[1];
  } else {
    return undefined;
  }
};

export const locationOptionName = (location) => {
  if (location.google_address) {
    return `${location.name}, ${location.google_address?.structured_formatting?.secondary_text}`;
  } else if (location.city) {
    return `${location.name}, ${location.city}`;
  } else {
    return location.name;
  }
};
