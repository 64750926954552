import { date } from "yup";
import { deleteEventContractApi } from "../api/ContractsApi";
import {
  addPaletteColorApi,
  addProposalItemApi,
  addProposalImgApi,
  addProposalNoteApi,
  debouncedUpdateProposalApi,
  removePaletteColorApi,
  removeProposalItemApi,
  removeProposalImgApi,
  removeProposalNoteApi,
  updateProposalApi,
  updateProposalNoteApi,
  updateProposalNotesOrderApi,
  updateProposalSectionApi,
  deleteProposalAttachmentApi,
  addProposalAttachmentApi,
  deleteProposalSectionApi,
  addProposalSectionApi,
  updateProposalAttachmentApi,
  bulkUpdateProposalSectionsApi,
  updatePaletteColorApi,
  updateProposalItemApi,
  updateProposalImgApi,
} from "../api/EventsApi";
import { orderByDate, snapshot, sort_by_order } from "../utils/utils";

export const proposalSlice = (set, get) => ({
  proposal: {},
  addPaletteColor: (proposal_section_uuid, hexCode) => {
    addPaletteColorApi({
      proposal_section_uuid: proposal_section_uuid,
      hex_code: hexCode,
    }).then((resp) => {
      let proposal = get().proposal;
      let proposal_sections = proposal.proposal_sections;
      let index = proposal_sections.findIndex(
        (section) => section.uuid === proposal_section_uuid
      );
      let section = proposal_sections[index];
      let new_section = {
        ...section,
        proposal_colors: [...section.proposal_colors, resp.data],
      };
      let new_sections = [
        ...proposal_sections.slice(0, index),
        new_section,
        ...proposal_sections.slice(index + 1),
      ];
      set({
        proposal: {
          ...proposal,
          proposal_sections: new_sections,
        },
      });
    });
  },
  addProposalAttachment: (proposal_section_uuid, attachment_url) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === proposal_section_uuid
    );
    let section = proposal_sections[index];
    addProposalAttachmentApi({ proposal_section_uuid, attachment_url }).then(
      (resp) => {
        let new_section = {
          ...section,
          proposal_attachments: [...section.proposal_attachments, resp.data],
        };
        let new_sections = [
          ...proposal_sections.slice(0, index),
          new_section,
          ...proposal_sections.slice(index + 1),
        ];
        set({
          proposal: {
            ...proposal,
            proposal_sections: new_sections,
          },
        });
      }
    );
  },
  addProposalItem: (data) => {
    addProposalItemApi(data).then((resp) => {
      let proposal = get().proposal;
      let proposal_sections = proposal.proposal_sections;
      let index = proposal_sections.findIndex(
        (section) => section.uuid === data.proposal_section_uuid
      );
      let section = proposal_sections[index];
      let new_section = {
        ...section,
        proposal_items: [...section.proposal_items, resp.data],
      };
      let new_sections = [
        ...proposal_sections.slice(0, index),
        new_section,
        ...proposal_sections.slice(index + 1),
      ];
      set({
        proposal: {
          ...proposal,
          proposal_sections: new_sections,
        },
      });
    });
  },
  addProposalItems: (data, cb) => {
    addProposalItemApi(data).then((resp) => {
      let proposal = get().proposal;
      let proposal_sections = proposal.proposal_sections;
      let index = proposal_sections.findIndex(
        (section) => section.uuid === data.proposal_section_uuid
      );
      let section = proposal_sections[index];
      let new_section = {
        ...section,
        proposal_items: [...section.proposal_items, ...resp.data],
      };
      let new_sections = [
        ...proposal_sections.slice(0, index),
        new_section,
        ...proposal_sections.slice(index + 1),
      ];
      set({
        proposal: {
          ...proposal,
          proposal_sections: new_sections,
        },
      });
      if (cb) {
        cb();
      }
    });
  },
  addProposalItemState: (data) => {
    let proposal = get().proposal;
    set({
      proposal: {
        ...proposal,
        proposal_items: [...proposal.proposal_items, data],
      },
    });
  },
  addProposalImg: (data) => {
    addProposalImgApi({
      ...data,
      proposal_section_uuid: data.proposal_section_uuid,
    }).then((resp) => {
      let proposal = get().proposal;
      let proposal_sections = proposal.proposal_sections;
      let index = proposal_sections.findIndex(
        (section) => section.uuid === data.proposal_section_uuid
      );
      let section = proposal_sections[index];
      let new_section = {
        ...section,
        proposal_imgs: [...section.proposal_imgs, ...resp.data],
      };
      let new_sections = [
        ...proposal_sections.slice(0, index),
        new_section,
        ...proposal_sections.slice(index + 1),
      ];
      set({
        proposal: {
          ...proposal,
          proposal_sections: new_sections,
        },
      });
    });
  },
  addProposalNote: (data) => {
    let proposal = get().proposal;
    addProposalNoteApi({ proposal_uuid: proposal.uuid, ...data }).then(
      (resp) => {
        set({
          proposal: {
            ...proposal,
            proposal_notes: [...proposal.proposal_notes, resp.data],
          },
        });
      }
    );
  },
  addProposalSection: (data) => {
    let proposal = get().proposal;
    addProposalSectionApi({ proposal_uuid: proposal.uuid, ...data }).then(
      (resp) => {
        let sections = proposal.proposal_sections.sort(sort_by_order);
        let new_sections = [
          ...sections.slice(0, resp.data.order),
          resp.data,
          ...sections.slice(resp.data.order),
        ];
        set({
          proposal: {
            ...proposal,
            proposal_sections: new_sections.map((section, index) => {
              return { ...section, order: index };
            }),
          },
        });
      }
    );
  },
  bulkUpdateProposalSections: (data) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let new_sections = proposal_sections.map((section) => {
      //loop over keys in data
      //if key is in section, update section[key] with data[key]
      //return
      Object.keys(data).forEach((key) => {
        if (key in section) {
          section[key] = { ...section[key], ...data[key] };
        } else {
          section[key] = data[key];
        }
      });
      return section;
    });
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });
    bulkUpdateProposalSectionsApi({
      ...data,
      proposal_uuid: proposal.uuid,
    }).then((resp) => {
      console.log(resp);
    });
  },
  contractExists: () => {
    if (get().proposal.contracts?.length > 0) {
      return true;
    } else {
      return false;
    }
  },
  contractSignDate: () => {
    let proposal = get().proposal;
    if (!proposal.contracts) {
      return undefined;
    }
    const signed_contracts = proposal.contracts.filter(
      (c) => (c.status = "signed")
    );
    if (signed_contracts.length > 0) {
      const ordered_signed_contracts = signed_contracts.sort(orderByDate);
      return ordered_signed_contracts[0].date_modified;
    } else {
      return undefined;
    }
  },
  deleteContract: (uuid) => {
    let proposal = get().proposal;
    set({
      proposal: {
        ...proposal,
        contracts: proposal.contracts.filter((c) => c.uuid !== uuid),
      },
    });
    deleteEventContractApi(uuid).then((resp) => {
      if (!resp.data.success) {
        set({ proposal: proposal });
      }
      if (!get().contractExists()) {
        get().updateEventInState({ flowerbuddy_fee_cost: undefined });
      }
    });
  },
  deleteProposalAttachment: (
    proposal_section_uuid,
    proposal_attachment_uuid
  ) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === proposal_section_uuid
    );
    let section = proposal_sections[index];
    let attachments = section.proposal_attachments.filter(
      (attachment) => attachment.uuid !== proposal_attachment_uuid
    );
    let new_section = { ...section, proposal_attachments: attachments };
    let new_sections = [
      ...proposal_sections.slice(0, index),
      new_section,
      ...proposal_sections.slice(index + 1),
    ];
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });
    deleteProposalAttachmentApi({ uuid: proposal_attachment_uuid }).then(
      (resp) => {
        console.log(resp);
      }
    );
  },
  deleteProposalSection: (uuid) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections.filter(
      (section) => section.uuid !== uuid
    );
    set({
      proposal: {
        ...proposal,
        proposal_sections: proposal_sections,
      },
    });
    deleteProposalSectionApi(uuid).then((resp) => {
      console.log(resp);
    });
  },
  documentIsSigned: (document) => {
    return !document.signers.some((signer) => !signer.signed);
  },
  getProposalLogo: () => {
    let title_page_section = get().getProposalSectionByType("title_page_1");
    if (title_page_section) {
      return title_page_section.image;
    } else {
      return undefined;
    }
  },
  getProposalSectionByType: (type) => {
    return get()
      .proposal.proposal_sections.sort(sort_by_order)
      .find((section) => section.section_type === type);
  },
  handleProposalColorDragEnd: (proposal_section_uuid, result) => {
    console.log(result);
    if (result.destination === null) {
      return;
    }
    let proposal = get().proposal;
    let section = proposal.proposal_sections.find(
      (section) => section.uuid === proposal_section_uuid
    );
    let colors = section.proposal_colors.sort(sort_by_order);
    let current_index = colors.findIndex(
      (color) => color.uuid === result.draggableId
    );
    let [color] = colors.splice(current_index, 1);
    let destination_index = result.destination.index;
    colors.splice(destination_index, 0, color);
    colors = colors.map((color, index) => {
      return { ...color, order: index };
    });
    get().updateProposalSectionInState({
      uuid: proposal_section_uuid,
      proposal_colors: colors,
    });
    let updates = colors.map((color) => {
      return { uuid: color.uuid, order: color.order };
    });
    updatePaletteColorApi(updates).then((resp) => {
      console.log(resp);
    });
  },
  handleProposalImgDragEnd: (proposal_section_uuid, imgs) => {
    imgs = imgs.map((img, index) => {
      return { ...img, order: index };
    });
    get().updateProposalSectionInState({
      uuid: proposal_section_uuid,
      proposal_imgs: imgs,
    });
    let updates = imgs.map((img) => {
      return { uuid: img.uuid, order: img.order };
    });
    updateProposalImgApi(updates);
  },
  handleProposalItemDragEnd: (proposal_section_uuid, items) => {
    items = items.map((item, index) => {
      return { ...item, order: index };
    });
    get().updateProposalSectionInState({
      uuid: proposal_section_uuid,
      proposal_items: items,
    });
    let updates = items.map((item) => {
      return { uuid: item.uuid, order: item.order };
    });
    updateProposalItemApi(updates);
  },
  handleProposalSectionDragEnd: (result) => {
    console.log(result);
    if (result.destination === null) {
      return;
    }
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections.filter(
      (section) => section.uuid !== result.draggableId
    );
    let section = proposal.proposal_sections.find(
      (section) => section.uuid === result.draggableId
    );
    let new_proposal_sections = [
      ...proposal_sections.slice(0, result.destination.index),
      section,
      ...proposal_sections.slice(result.destination.index),
    ];
    new_proposal_sections = new_proposal_sections.map((section, index) => {
      return { ...section, order: index };
    });
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_proposal_sections,
      },
    });
    let updates = new_proposal_sections.map((section) => {
      return { uuid: section.uuid, order: section.order };
    });
    updateProposalSectionApi(updates).then((resp) => {
      console.log(resp);
    });
  },
  initializeProposal: (data) => set({ proposal: data }),
  proposalHasWarnings: () => {
    return get().proposalWarnings().length > 0;
  },
  proposalWarnings: () => {
    var warnings = [];
    let proposal = get().proposal;
    let template = get().event.is_template;
    let event = get().event;
    let event_has_amendments = get().eventHasAmendments();
    if (!proposal.published && !template && event.status !== "BO") {
      warnings.push(
        "This proposal is not published and cannot be seen by clients. Use the switch above to publish the proposal."
      );
    }
    if (!proposal.amendments_published && event_has_amendments) {
      warnings.push(
        "Amendments for this event are not published and cannot be seen by clients. Use the switch above to publish amendments."
      );
    }
    if (
      proposal.expiration_date &&
      new Date(proposal.expiration_date) < new Date() &&
      proposal.expires &&
      !template &&
      event.status !== "BO"
    ) {
      warnings.push(
        "The expiration date for this proposal has passed and therefore it cannot be seen by clients. Change the exiration date above or use the switch to disable expiration."
      );
    }
    return warnings;
  },
  removePaletteColor: (proposal_section_uuid, palette_color_uuid) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === proposal_section_uuid
    );
    let section = proposal_sections[index];
    let colors = section.proposal_colors.filter(
      (color) => color.uuid !== palette_color_uuid
    );
    let new_section = { ...section, proposal_colors: colors };
    let new_sections = [
      ...proposal_sections.slice(0, index),
      new_section,
      ...proposal_sections.slice(index + 1),
    ];
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });
    removePaletteColorApi(palette_color_uuid).then((resp) => {
      console.log(resp);
    });
  },
  removeProposalItem: (proposal_section_uuid, uuid) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === proposal_section_uuid
    );
    let section = proposal_sections[index];
    let items = section.proposal_items.filter((item) => item.uuid !== uuid);
    let new_section = { ...section, proposal_items: items };
    let new_sections = [
      ...proposal_sections.slice(0, index),
      new_section,
      ...proposal_sections.slice(index + 1),
    ];
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });

    removeProposalItemApi(uuid).then((resp) => {
      console.log(resp);
    });
  },
  removeProposalImg: (proposal_section_uuid, image_uuid) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === proposal_section_uuid
    );
    let section = proposal_sections[index];
    let imgs = section.proposal_imgs.filter((img) => img.uuid !== image_uuid);
    let new_section = { ...section, proposal_imgs: imgs };
    let new_sections = [
      ...proposal_sections.slice(0, index),
      new_section,
      ...proposal_sections.slice(index + 1),
    ];
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });
    removeProposalImgApi(image_uuid).then((resp) => {
      console.log(resp);
    });
  },
  removeProposalNote: (uuid) => {
    let proposal = get().proposal;
    set({
      proposal: {
        ...proposal,
        proposal_notes: proposal.proposal_notes.filter(
          (note) => note.uuid !== uuid
        ),
      },
    });
    removeProposalNoteApi(uuid).then((resp) => {
      if (!resp.data.success) {
        set({ proposal: proposal });
      }
    });
  },
  unsignedDocumentExists: () => {
    return get().proposal.contracts?.some(
      (document) => !get().documentIsSigned(document)
    );
  },
  updateProposal: (data) => {
    let proposal = get().proposal;
    set({ proposal: { ...proposal, ...data } });
    debouncedUpdateProposalApi({ ...data, uuid: proposal.uuid });
  },
  updateProposalAttachment: (data) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === data.proposal_section_uuid
    );
    let section = proposal_sections[index];
    let attachments = section.proposal_attachments;
    let attachment_index = attachments.findIndex(
      (attachment) => attachment.uuid === data.uuid
    );
    let attachment = attachments[attachment_index];
    let new_attachment = { ...attachment, ...data };
    let new_attachments = [
      ...attachments.slice(0, attachment_index),
      new_attachment,
      ...attachments.slice(attachment_index + 1),
    ];
    let new_section = { ...section, proposal_attachments: new_attachments };
    let new_sections = [
      ...proposal_sections.slice(0, index),
      new_section,
      ...proposal_sections.slice(index + 1),
    ];
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });
    updateProposalAttachmentApi(data).then((resp) => {
      console.log(resp);
    });
  },
  updateProposalImgInState: (data) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === data.proposal_section_uuid
    );
    let section = proposal_sections[index];
    let imgs = section.proposal_imgs;
    let img_index = imgs.findIndex((img) => img.uuid === data.uuid);
    let img = imgs[img_index];
    let new_img = { ...img, ...data };
    let new_imgs = [
      ...imgs.slice(0, img_index),
      new_img,
      ...imgs.slice(img_index + 1),
    ];
    let new_section = { ...section, proposal_imgs: new_imgs };
    let new_sections = [
      ...proposal_sections.slice(0, index),
      new_section,
      ...proposal_sections.slice(index + 1),
    ];
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });
  },
  updateProposalNote: (data) => {
    let proposal = get().proposal;
    let index = proposal.proposal_notes.findIndex(
      (note) => note.uuid === data.uuid
    );
    let note = proposal.proposal_notes[index];
    set({
      proposal: {
        ...proposal,
        proposal_notes: [
          ...proposal.proposal_notes.slice(0, index),
          { ...note, ...data },
          ...proposal.proposal_notes.slice(index + 1),
        ],
      },
    });
    updateProposalNoteApi(data).catch((err) => {
      console.log(err);
      set({
        proposal: {
          ...proposal,
          proposal_notes: [
            ...proposal.proposal_notes.slice(0, index),
            note,
            ...proposal.proposal_notes.slice(index + 1),
          ],
        },
      });
    });
  },
  updateProposalNotesOrder: (data) => {
    let proposal = get().proposal;
    updateProposalNotesOrderApi(data).then((resp) => {
      set({
        proposal: {
          ...proposal,
          proposal_notes: resp.data,
        },
      });
    });
  },
  updateProposalSection: (data) => {
    get().updateProposalSectionInState(data);
    updateProposalSectionApi(data).then((resp) => {
      console.log(resp);
    });
  },
  updateProposalSectionInState: (data) => {
    let proposal = get().proposal;
    let proposal_sections = proposal.proposal_sections;
    let index = proposal_sections.findIndex(
      (section) => section.uuid === data.uuid
    );
    let section = proposal_sections[index];
    let new_section = { ...section, ...data };
    let new_sections = [
      ...proposal_sections.slice(0, index),
      new_section,
      ...proposal_sections.slice(index + 1),
    ];
    set({
      proposal: {
        ...proposal,
        proposal_sections: new_sections,
      },
    });
  },
  updateProposalSectionStyle: (proposal_section_uuid, data) => {
    let proposal_section = get().proposal.proposal_sections.find(
      (section) => section.uuid === proposal_section_uuid
    );
    get().updateProposalSection({
      uuid: proposal_section_uuid,
      style: { ...proposal_section.style, ...data },
    });
  },
});
