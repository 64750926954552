import { Box, Grid, Tooltip, Typography } from "@mui/material";
import {
  itemDisplayName,
  materialDisplaySize,
  materialTypeName,
} from "../utils/item_utils";

export const StackedItemName = ({ item, ...props }) => {
  return (
    <Tooltip title={itemDisplayName(item)}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            noWrap
            sx={{
              lineHeight: "1rem",
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item.item_type === "FL" ? item.name : materialTypeName(item.type)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            noWrap
            sx={{
              color: "text.secondary",
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item.item_type === "FL"
              ? item.color_name
              : `${item.name} ${materialDisplaySize(item)}`}
          </Typography>
        </Grid>
      </Grid>
    </Tooltip>
  );
};
