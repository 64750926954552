import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import CroppedImg from "../CroppedImg";
import { eventDateTime } from "../EventDetails";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { sort_by_order } from "../../utils/utils";
import { ColorPickerDialog } from "../Proposal";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InspirationImgs from "../InspirationImgs";
import InspirationImgsV2 from "../InspirationImgsV2";
import { updateImageApi } from "../../api/DataApi";

export const ImagesV2 = (props) => {
  const [
    addProposalImg,
    handleProposalImgDragEnd,
    imgsInEvent,
    removeProposalImg,
    updateProposalImgInState,
  ] = useAccountState((state) => [
    state.addProposalImg,
    state.handleProposalImgDragEnd,
    state.imgsInEvent,
    state.removeProposalImg,
    state.updateProposalImgInState,
  ]);

  const addImg = (data) => {
    addProposalImg({
      ...data,
      proposal_section_uuid: props.proposal_section.uuid,
    });
  };

  const updateProposalImg = (data) => {
    updateProposalImgInState({
      ...data,
      proposal_section_uuid: props.proposal_section.uuid,
    });
  };

  const removeImg = (image_uuid) => {
    removeProposalImg(props.proposal_section.uuid, image_uuid);
  };

  const handleDragEnd = (result) => {
    handleProposalImgDragEnd(props.proposal_section.uuid, result);
  };

  return (
    <Box sx={{ p: "1rem" }}>
      <InspirationImgsV2
        proposal_section={props.proposal_section}
        addImg={addImg}
        removeImg={removeImg}
        updateProposalImg={updateProposalImg}
        imgs={props.proposal_section.proposal_imgs}
        options={imgsInEvent()}
        onDragEnd={handleDragEnd}
      />
    </Box>
  );
};

const heights = [15, 20, 15, 25, 17, 30, 20];

export const ImagesV2Preview = (props) => {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <ImageList variant="masonry" cols={3} gap={2}>
        {heights.map((h, idx) => (
          <ImageListItem key={idx}>
            <Skeleton
              variant="rectangular"
              width={15}
              height={h}
              animation={false}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Grid>
  );
};

export const ImagesV2View = (props) => {
  return (
    <InspirationImgsV2
      proposal_section={props.proposal_section}
      imgs={props.proposal_section.proposal_imgs}
      displayOnly
      zoom
    />
  );
};
