import React from "react";
import { withRouter } from "react-router-dom";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Link,
  Alert,
  Grid,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Menu,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EventItem from "./EventItem";
import { useAccountState } from "../state/store";
import { snapshot, sort_by_order } from "../utils/utils";
import RecipeProposalSection from "./RecipeProposalSection";
import EventItemDividerButtons from "./EventItemDividerButtons";
import { RecipeTemplateSelector } from "./RecipeTemplates";
import { UnderPricedEventItemTable } from "./Proposal";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const EventBuild = (props) => {
  const [
    addOtherCost,
    addRecipe,
    addRecipeGroup,
    addRecipeProposalSection,
    addRecipeTemplatesToEvent,
    draggable,
    eventItemsBelowSuggested,
    eventLocked,
    getRecipeListItemByUuid,
    handleEventItemDragEnd,
    recipeListItems,
    recipesBelowSuggested,
    setDraggable,
  ] = useAccountState((state) => [
    state.addOtherCost,
    state.addRecipe,
    state.addRecipeGroup,
    state.addRecipeProposalSection,
    state.addRecipeTemplatesToEvent,
    state.draggable,
    state.eventItemsBelowSuggested,
    state.eventLocked,
    state.getRecipeListItemByUuid,
    state.handleEventItemDragEnd,
    state.recipeListItems,
    state.recipesBelowSuggested,
    state.setDraggable,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [addingTemplate, setAddingTemplate] = React.useState(false);
  const [newTemplatesLoading, setNewTemplatesLoading] = React.useState(false);
  const toggleAddingTemplate = () => {
    setAddingTemplate(!addingTemplate);
  };
  const handleAddRecipeTemplates = (recipe_template_uuids) => {
    setNewTemplatesLoading(true);
    addRecipeTemplatesToEvent(
      recipe_template_uuids,
      undefined,
      undefined,
      () => {
        setNewTemplatesLoading(false);
      }
    );
    toggleAddingTemplate();
  };

  const handleDragEnd = (result) => {
    setDraggable(null);
    let error = handleEventItemDragEnd(result);
    if (error) {
      setDragDropError(error);
    }
  };

  const handleBeforeDragStart = (result) => {
    setDraggable(result.draggableId);
  };

  const [dragDropError, setDragDropError] = React.useState(undefined);

  const [underPricedDialogOpen, setUnderPricedDialogOpen] =
    React.useState(false);

  const toggleUnderPricedDialog = () => {
    setUnderPricedDialogOpen(!underPricedDialogOpen);
  };

  return (
    <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
      <Box sx={{ mb: "calc(100vh/2)" }}>
        <Grid
          container
          sx={{ width: "100%", maxWidth: "800px", m: "auto" }}
          spacing={1}
        >
          <Grid item xs={12} container justifyContent={"flex-end"} spacing={1}>
            <Grid item xs="auto">
              {newTemplatesLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={eventLocked()}
                  onClick={toggleAddingTemplate}
                  variant="outlined"
                  size="small"
                  color={"info"}
                >
                  <SearchOutlinedIcon /> Templates
                </Button>
              )}
              <RecipeTemplateSelector
                open={addingTemplate}
                handleCancel={toggleAddingTemplate}
                handleSubmit={handleAddRecipeTemplates}
              />
            </Grid>
            <Grid item xs="auto">
              <DropdownButton />
            </Grid>
          </Grid>
          {eventItemsBelowSuggested(true).length > 0 ? (
            <Grid item xs={12}>
              <Alert
                severity="error"
                id="recipes-below-suggested-price-alert"
                onClick={toggleUnderPricedDialog}
                sx={{ cursor: "pointer" }}
              >
                {eventItemsBelowSuggested(true).length}{" "}
                {eventItemsBelowSuggested(true).length > 1
                  ? "items are"
                  : "item is"}{" "}
                priced below their suggested retail price (click for more info).
              </Alert>
              <UnderPricedEventItemDialog
                open={underPricedDialogOpen}
                handleCancel={toggleUnderPricedDialog}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            {recipeListItems().length > 0 ? (
              <DragDropContext
                onDragEnd={handleDragEnd}
                // onDragStart={handleDragStart}
                onBeforeCapture={handleBeforeDragStart}
              >
                <Droppable
                  droppableId="event-items"
                  type="parent"
                  isCombineEnabled
                >
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Box
                        sx={{
                          mb: "calc(100vh/4)",
                        }}
                      >
                        {recipeListItems()
                          .sort(sort_by_order)
                          .map((event_item, i) => {
                            return event_item.event_item_type ? (
                              <EventItem
                                key={event_item.uuid}
                                event_item={event_item}
                                handleTabSelect={props.handleTabSelect}
                                id={`event-item-${event_item.uuid}`}
                                index={i}
                              />
                            ) : (
                              <RecipeProposalSection
                                recipe_proposal_section={event_item}
                                index={i}
                                key={event_item.uuid}
                              />
                            );
                          })}
                        {provided.placeholder}
                        <EventItemDividerButtons />
                      </Box>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Box
                sx={{
                  borderStyle: "dotted",
                  borderColor: "info.light",
                  p: "2rem",
                  borderRadius: "7.5px",
                }}
              >
                <Typography
                  sx={{
                    color: "info.light",
                    fontWeight: "normal",
                  }}
                  variant="h6"
                >
                  No items have been added.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={dragDropError !== undefined}
        autoHideDuration={5000}
        onClose={() => setDragDropError(undefined)}
      >
        <Alert
          severity="warning"
          sx={{ width: "100%" }}
          onClose={() => setDragDropError(undefined)}
        >
          {dragDropError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const DropdownButton = () => {
  const [
    addOtherCost,
    addRecipe,
    addRecipeGroup,
    addRecipeProposalSection,
    eventLocked,
  ] = useAccountState((state) => [
    state.addOtherCost,
    state.addRecipe,
    state.addRecipeGroup,
    state.addRecipeProposalSection,
    state.eventLocked,
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddRecipe = () => {
    addRecipe("New Recipe");
    handleClose();
  };

  const handleAddCost = () => {
    addOtherCost("New Other Cost");
    handleClose();
  };

  const handleAddCostPercent = () => {
    addOtherCost("New Other Cost", undefined, undefined, undefined, true);
    handleClose();
  };

  const handleAddRecipeProposalSection = () => {
    addRecipeProposalSection("New Proposal Section");
  };

  const handleAddRecipeGroup = () => {
    addRecipeGroup("New Recipe Group");
  };

  const options = [
    {
      label: "Recipe",
      action: handleAddRecipe,
      icon: LocalFloristOutlinedIcon,
    },
    {
      label: "Fixed Cost",
      action: handleAddCost,
      icon: AttachMoneyOutlinedIcon,
    },
    {
      label: "Percent Cost",
      action: handleAddCostPercent,
      icon: PercentOutlinedIcon,
    },
    { label: "Group", action: handleAddRecipeGroup, icon: ListAltOutlinedIcon },
    {
      label: "Section",
      action: handleAddRecipeProposalSection,
      icon: SegmentOutlinedIcon,
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        size="small"
        color="secondary"
        disabled={eventLocked()}
      >
        <AddOutlinedIcon /> Add Item
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              option.action();
              handleClose();
            }}
          >
            <option.icon sx={{ mr: ".5rem" }} />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const UnderPricedEventItemDialog = (props) => {
  const [eventItemsBelowSuggested] = useAccountState((state) => [
    state.eventItemsBelowSuggested,
  ]);
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        {eventItemsBelowSuggested().length} items are priced below their
        suggested retail price.
      </DialogTitle>
      <DialogContent>
        <UnderPricedEventItemTable />
        <Alert severity="info" sx={{ mt: ".5rem" }}>
          Find items highlighted in red and click the{" "}
          <WarningAmberRoundedIcon color="error" fontSize="inherit" /> for more
          info.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(EventBuild);
