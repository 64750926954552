import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import FormatAlignRightOutlinedIcon from "@mui/icons-material/FormatAlignRightOutlined";
import FormatAlignJustifyOutlinedIcon from "@mui/icons-material/FormatAlignJustifyOutlined";
import FormatSizeOutlinedIcon from "@mui/icons-material/FormatSizeOutlined";
import FormatUnderlinedOutlinedIcon from "@mui/icons-material/FormatUnderlinedOutlined";
import { useState } from "react";
import { useAccountState } from "../../state/store";
import { ColorPickerDialog } from "../Proposal";
import { DefaultStyles } from "../../utils/proposal_utils";
import { FontSelector } from "../FontSelector";

export const ProposalSectionStylePopover = (props) => {
  return (
    <TextControlsPopover label="Section Style">
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        sx={{ maxWidth: "300px" }}
      >
        {!props.disableHeading && (
          <>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>Heading</Typography>
            </Grid>
            <Grid item xs={12}>
              <HeadingTextControls {...props} />
            </Grid>
          </>
        )}
        {!props.disableBodyControls && (
          <>
            {!props.disableHeading && (
              <Divider sx={{ width: "100%", mt: "1rem" }} />
            )}
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                {props.bodyName || "Body"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BodyTextControls {...props} />
            </Grid>
          </>
        )}
        {props.text2 && (
          <>
            <Divider sx={{ width: "100%", mt: "1rem" }} />

            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                {props.bodyName2 || "Body 2"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BodyTextControls {...props} bodyTextControlsId="2" />
            </Grid>
          </>
        )}
        {props.text3 && (
          <>
            <Divider sx={{ width: "100%", mt: "1rem" }} />

            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                {props.bodyName3 || "Body 3"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BodyTextControls {...props} bodyTextControlsId="3" />
            </Grid>
          </>
        )}
        {props.misc && (
          <>
            <Divider sx={{ width: "100%", mt: "1rem" }} />

            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                {props.miscName || "Miscellaneous"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <MiscControls {...props} />
            </Grid>
          </>
        )}
      </Grid>
    </TextControlsPopover>
  );
};

const TextControlsPopover = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const setOpenTrue = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };
  const setOpenFalse = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={props.label}>
        <IconButton onClick={setOpenTrue}>
          <SettingsOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={setOpenFalse}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ m: ".5rem" }}>{props.children}</Box>
      </Popover>
    </>
  );
};

export const HeadingTextControls = (props) => {
  return (
    <Grid container spacing={1} alignItems={"center"}>
      {!props.disableHeadingAlign && (
        <Grid item xs="auto">
          <TextAlignToggle
            proposal_section={props.proposal_section}
            id="heading_text_align"
          />
        </Grid>
      )}
      {!props.disableHeadingUnderline && (
        <Grid item xs="auto">
          <HeadingUnderlineToggle proposal_section={props.proposal_section} />
        </Grid>
      )}
      <Grid item xs="auto">
        <FontColorPicker
          proposal_section={props.proposal_section}
          id="heading_font_color"
          label="Color"
        />
      </Grid>
      <Grid item xs="auto">
        <FontSizeSelector
          proposal_section={props.proposal_section}
          id="heading_font_size"
          label="Font Size"
        />
      </Grid>
      <Grid item xs="auto">
        <FontFamilySelector
          proposal_section={props.proposal_section}
          id="heading_font"
          label="Font"
        />
      </Grid>
    </Grid>
  );
};

export const BodyTextControls = (props) => {
  const Id = (id) => {
    return props.bodyTextControlsId ? `${id}_${props.bodyTextControlsId}` : id;
  };

  const enabled = (id) => {
    return props.bodyTextControlsId
      ? props[`${id}${props.bodyTextControlsId}`]
      : props[id];
  };
  return (
    <Grid container spacing={1} alignItems={"center"} sx={{ pl: ".25rem" }}>
      {enabled("textAlign") && (
        <Grid item xs="auto">
          <TextAlignToggle
            proposal_section={props.proposal_section}
            id={Id("text_align")}
          />
        </Grid>
      )}
      {enabled("fontColor") && (
        <Grid item xs="auto">
          <FontColorPicker
            proposal_section={props.proposal_section}
            id={Id("font_color")}
            label="Color"
          />
        </Grid>
      )}
      {enabled("fontSize") && (
        <Grid item xs="auto">
          <FontSizeSelector
            proposal_section={props.proposal_section}
            id={Id("font_size")}
            label="Font Size"
          />
        </Grid>
      )}
      {enabled("fontFamily") && (
        <Grid item xs="auto">
          <FontFamilySelector
            proposal_section={props.proposal_section}
            id={Id("font")}
            label="Font"
          />
        </Grid>
      )}
      {enabled("itemsAlign") && (
        <Grid item xs="auto">
          <ItemAlignmentSelector proposal_section={props.proposal_section} />
        </Grid>
      )}
      {enabled("itemShape") && (
        <Grid item xs="auto">
          <ItemShapeSelector proposal_section={props.proposal_section} />
        </Grid>
      )}
    </Grid>
  );
};

export const MiscControls = (props) => {
  const Id = (id) => {
    return props.bodyTextControlsId ? `${id}_${props.bodyTextControlsId}` : id;
  };

  const enabled = (id) => {
    return props.bodyTextControlsId
      ? props[`${id}${props.bodyTextControlsId}`]
      : props[id];
  };
  return (
    <Grid container spacing={1} alignItems={"center"} sx={{ pl: ".25rem" }}>
      {enabled("elementColor") && (
        <Grid item xs="auto">
          <FontColorPicker
            proposal_section={props.proposal_section}
            id={Id("element_color")}
            label="Element Color"
            default={DefaultStyles.elementColor}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const TextAlignToggle = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const handleAlignment = (event, newAlignment) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      [props.id]: newAlignment,
    });
  };

  return (
    <ToggleButtonGroup
      value={
        (props.proposal_section.style &&
          props.proposal_section.style[props.id]) ||
        DefaultStyles.textAlign
      }
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      size="small"
    >
      <ToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeftOutlinedIcon />
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
        <FormatAlignCenterOutlinedIcon />
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        <FormatAlignRightOutlinedIcon />
      </ToggleButton>
      <ToggleButton value="justify" aria-label="justified">
        <FormatAlignJustifyOutlinedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const FontColorPicker = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const [updatingFontColor, setUpdatingFontColor] = useState(false);

  const setUpdatingFontColorTrue = () => {
    setUpdatingFontColor(true);
  };

  const setUpdatingFontColorFalse = () => {
    setUpdatingFontColor(false);
  };

  const updateFontColor = (color) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      [props.id]: color,
    });
    setUpdatingFontColorFalse();
  };

  return (
    <>
      <Tooltip title={props.label}>
        <Box
          sx={{
            display: "flex",
            width: "30px",
            height: "30px",
            backgroundColor: "info.light",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={setUpdatingFontColorTrue}
          variant="contained"
          id="font-color-button"
          color="info"
          size="small"
        >
          <Box
            sx={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor:
                (props.proposal_section.style &&
                  props.proposal_section.style[props.id]) ||
                props.default ||
                DefaultStyles.fontColor,
              m: "auto",
            }}
          ></Box>
        </Box>
      </Tooltip>
      <ColorPickerDialog
        open={updatingFontColor}
        handleCancel={setUpdatingFontColorFalse}
        handleSubmit={updateFontColor}
      />
    </>
  );
};

export const FontSizeValueLabelMap = {
  "0.5rem": "Extra Small",
  "0.75rem": "Small",
  "1rem": "Regular",
  "1.25rem": "Large",
  "1.5rem": "Extra Large",
  "2rem": "Huge",
};

const FontSizeSelector = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const handleChange = (event) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      [props.id]: event.target.value,
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="font-size-select-label">{props.label}</InputLabel>
      <Select
        labelId="font-size-select-label"
        id="font-size-select"
        value={
          (props.proposal_section.style &&
            props.proposal_section.style[props.id]) ||
          DefaultStyles.fontSize
        }
        label={props.label}
        onChange={handleChange}
        size="small"
        renderValue={(value) => (
          <Typography>{FontSizeValueLabelMap[value]}</Typography>
        )}
      >
        <MenuItem value={"0.5rem"}>
          <Typography sx={{ fontSize: "0.5rem" }}>Extra Small</Typography>
        </MenuItem>
        <MenuItem value={"0.75rem"}>
          <Typography sx={{ fontSize: "0.75rem" }}>Small</Typography>
        </MenuItem>
        <MenuItem value={"1rem"}>
          <Typography sx={{ fontSize: "1rem" }}>Regular</Typography>
        </MenuItem>
        <MenuItem value={"1.25rem"}>
          <Typography sx={{ fontSize: "1.25rem" }}>Large</Typography>
        </MenuItem>
        <MenuItem value={"1.5rem"}>
          <Typography sx={{ fontSize: "1.5rem" }}>Extra Large</Typography>
        </MenuItem>
        <MenuItem value={"2rem"}>
          <Typography sx={{ fontSize: "2rem" }}>Huge</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const FontFamilySelector = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const handleChange = (event) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      [props.id]: event.target.value,
    });
  };

  return (
    <FontSelector
      label={props.label}
      value={
        (props.proposal_section.style &&
          props.proposal_section.style[props.id]) ||
        DefaultStyles.fontFamily
      }
      updateFont={handleChange}
      sx={{
        m: "auto",
        width: "10rem",
      }}
      size="small"
      id="font-family-select"
      name="font"
    />
  );
};

export const ItemAlignmentSelector = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const handleAlignmentChange = (event) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      items_align: event.target.value,
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="items-align-select-label">Item Alignment</InputLabel>
      <Select
        id="items-align-select"
        labelId="items-align-select-label"
        value={
          props.proposal_section.style?.items_align || DefaultStyles.itemsAlign
        }
        onChange={handleAlignmentChange}
        size="small"
        label="Item Alignment"
        sx={{ minWidth: "110px" }}
      >
        <MenuItem value="left">Left</MenuItem>
        <MenuItem value="center">Center</MenuItem>
        <MenuItem value="right">Right</MenuItem>
      </Select>
    </FormControl>
  );
};

export const ItemShapeSelector = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const handleShapeChange = (event) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      item_shape: event.target.value,
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="items-shape-select-label">Item Shape</InputLabel>
      <Select
        id="items-shape-select"
        labelId="items-shape-select-label"
        value={
          props.proposal_section.style?.item_shape || DefaultStyles.itemShape
        }
        onChange={handleShapeChange}
        size="small"
        label="Item Shape"
        sx={{ minWidth: "110px" }}
      >
        <MenuItem value="square">Square</MenuItem>
        <MenuItem value="circle">Circle</MenuItem>
        <MenuItem value="rounded">Rounded</MenuItem>
        <MenuItem value="thumbnail">Thumbnail</MenuItem>
        <MenuItem value="swatch">Swatch</MenuItem>
      </Select>
    </FormControl>
  );
};

const HeadingUnderlineToggle = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);

  const handleUnderlineToggle = () => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      heading_underline: !props.proposal_section.style?.heading_underline,
    });
  };

  return (
    <ToggleButton
      value="underline"
      selected={props.proposal_section.style?.heading_underline}
      onChange={handleUnderlineToggle}
      size="small"
    >
      <FormatUnderlinedOutlinedIcon />
    </ToggleButton>
  );
};
