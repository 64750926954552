import React from "react";
import { withRouter } from "react-router-dom";

import {
  Typography,
  Box,
  Grid,
  Divider,
  Collapse,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  CircularProgress,
  Link,
  IconButton,
} from "@mui/material";

import { useAccountState } from "../state/store";

import {
  getItemColors,
  itemColors,
  itemDisplayName,
  materialDisplaySize,
  materialTypeName,
  materialUnitOptions,
  material_colors,
} from "../utils/item_utils";
import { ItemIcon } from "./ItemIcon";
import { ExpandButton } from "./EventItem";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  formatStringForId,
  sortByLabel,
  sortByName,
  sortItemsByLocation,
  sortWithGetter,
} from "../utils/utils";
import InventoryLogDialog, {
  AdjustInventoryQuantityFields,
} from "./InventoryLogDialog";
import { useFormik } from "formik";
import { matchSorter } from "match-sorter";
import { sort } from "fast-sort";
import debounce from "lodash.debounce";
import { AddEditLocationDialog } from "./InventoryLocations";
import CreateItemDialog from "./CreateItemDialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const InventoryItems = (props) => {
  const [inventoryItems] = useAccountState((state) => [state.inventoryItems]);
  const [filteredInventoryItems, setFilteredInventoryItems] =
    React.useState(inventoryItems);
  const [search, setSearch] = React.useState("");
  const [categoryFilter, setCategoryFilter] = React.useState([]);
  const [colorFilter, setColorFilter] = React.useState([]);
  const filterInventoryItems = () => {
    let inventoryItemsList = inventoryItems;
    if (categoryFilter.length > 0) {
      inventoryItemsList = inventoryItemsList.filter((ii) =>
        categoryFilter.includes(ii.item.type)
      );
    }
    if (colorFilter.length > 0) {
      inventoryItemsList = inventoryItemsList.filter((ii) => {
        return colorFilter.some((color) => ii.item[color.value]);
      });
    }
    if (search === "") {
      setFilteredInventoryItems(inventoryItemsList);
    } else {
      let res = matchSorter(inventoryItemsList, search, {
        keys: [
          (ii) => ii.item.name,
          (ii) => materialTypeName(ii.item.type),
          (ii) => ii.item.length,
          (ii) => ii.item.width,
          (ii) => ii.item.height,
        ],
      });
      setFilteredInventoryItems(res);
    }
  };
  const debouncedHandleChange = React.useCallback(
    debounce((e) => setSearch(e.target.value), 500),
    []
  );
  React.useEffect(() => {
    filterInventoryItems();
  }, [search, inventoryItems, categoryFilter, colorFilter]);
  const [addingItem, setAddingItem] = React.useState(false);

  const getCategoryOptions = () => {
    let category_options = filteredInventoryItems.map((ii) => ii.item.type);
    return Object.entries(materialUnitOptions)
      .map(([type, obj]) => type)
      .filter((type) => category_options.includes(type))
      .sort();
  };

  const getColorOptions = () => {
    let color_options = filteredInventoryItems
      .map((ii) => {
        return getItemColors(ii.item);
      })
      .reduce((acc, val) => acc.concat(val), []);
    return Array.from(new Set(color_options));
  };

  return props.loading ? (
    <CircularProgress />
  ) : (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item xs="auto">
          <Typography variant="h6">Inventory Items</Typography>
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setAddingItem(true)}
          >
            Add Item To Inventory
          </Button>
          {addingItem && (
            <AddInventoryItemDialog
              open={addingItem}
              handleCancel={() => setAddingItem(false)}
              auto
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Search"
            onChange={debouncedHandleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={4}>
            <Autocomplete
              id="category-filter"
              label="Category"
              variant="outlined"
              size="small"
              value={categoryFilter}
              multiple
              onChange={(e, newValue) => setCategoryFilter(newValue)}
              fullWidth
              options={getCategoryOptions()}
              getOptionLabel={(option) => materialTypeName(option)}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="color-filter"
              label="Color"
              variant="outlined"
              size="small"
              renderTags={(values, getTagProps) => {
                return values.map((value, ind) => (
                  <Box
                    key={value.color_code}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    {...getTagProps(value)}
                  >
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        mr: ".25rem",
                        backgroundColor: value.color_code,
                      }}
                    ></Box>
                    <Typography>{value.label}</Typography>
                  </Box>
                ));
              }}
              value={colorFilter}
              multiple
              onChange={(e, newValue) => setColorFilter(newValue)}
              fullWidth
              options={getColorOptions()}
              renderInput={(params) => <TextField {...params} label="Color" />}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => {
                return [
                  null,
                  <Box
                    key={option.color_code}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    {...props}
                  >
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        mr: ".25rem",
                        backgroundColor: option.color_code,
                      }}
                    ></Box>
                    <Typography>{option.label}</Typography>
                  </Box>,
                ];
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        contianer
        sx={{ maxHeight: "700px", overflow: "auto" }}
      >
        {Object.entries(materialUnitOptions).map(([type, label]) => (
          <InvenoryItemsSection
            key={type}
            type={type}
            inventory_items={filteredInventoryItems}
          />
        ))}
      </Grid>
    </Grid>
  );
};

const InvenoryItemsSection = (props) => {
  const inventoryItems = () => {
    return props.inventory_items.filter((ii) => {
      return ii.item.type === props.type;
    });
  };

  const [open, setOpen] = React.useState(true);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const showN = 25;

  const [show, setShow] = React.useState(showN);

  const showMore = () => {
    setShow(show + showN);
  };

  return inventoryItems().length > 0 ? (
    <Grid item xs={12} container>
      <Grid
        item
        xs={12}
        container
        // justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs="auto">
          <Typography sx={{ fontWeight: "bold" }}>
            {materialUnitOptions[props.type].plural}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <ExpandButton
            id={formatStringForId(props.type + "-expand-button")}
            expand={open}
            size="large"
            onClick={toggleOpen}
          >
            <KeyboardArrowDownOutlinedIcon fontSize="inherit" />
          </ExpandButton>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pl: "1rem" }}>
        <Collapse in={open}>
          {sort(inventoryItems())
            .by([{ asc: (ii) => ii.item.name }])
            .slice(0, show)
            .map((inventory_item) => (
              <InventoryItem
                key={inventory_item.uuid}
                inventory_item={inventory_item}
              />
            ))}
          {show < inventoryItems().length ? (
            <Grid item xs={12}>
              <Button
                size="small"
                color="info"
                variant="outlined"
                onClick={showMore}
              >
                Show More
              </Button>
            </Grid>
          ) : null}
        </Collapse>
      </Grid>
    </Grid>
  ) : null;
};

const InventoryItem = (props) => {
  const [updateItem] = useAccountState((state) => [state.updateItem]);

  const [expanded, setExpanded] = React.useState(false);

  const toggleOpen = () => {
    setExpanded(!expanded);
  };

  const [pulling_item, setPullingItem] = React.useState(false);
  const [adjusting_item, setAdjustingItem] = React.useState(false);
  const [returning_item, setReturningItem] = React.useState(false);
  const [editing_item_location, setEditingItemLocation] = React.useState(false);
  const [removingItem, setRemovingItem] = React.useState(false);
  const [editing_item_details, setEditingItemDetails] = React.useState(false);

  return (
    <>
      <Grid item xs={12} container spacing={1} justifyContent={"space-between"}>
        <Grid item xs="auto" container spacing={1}>
          <Grid item xs="auto">
            <ItemIcon item={props.inventory_item.item} />
          </Grid>
          <Grid item xs="auto" container>
            <Grid item xs={12}>
              <Typography>
                {materialTypeName(props.inventory_item.item.type) +
                  " " +
                  props.inventory_item.item.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: ".75rem" }}>
                {materialDisplaySize(props.inventory_item.item)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto" container spacing={1} alignItems={"center"}>
          <QuantityDisplay
            label="Total"
            quantity={props.inventory_item.quantity_total}
          />
          <QuantityDisplay
            label="In Stock"
            quantity={props.inventory_item.quantity_in_stock}
          />
          <Grid item xs="auto">
            <ExpandButton
              id={formatStringForId(
                props.inventory_item.item.name + "-expand-button"
              )}
              expand={expanded}
              size="large"
              onClick={toggleOpen}
            >
              <KeyboardArrowDownOutlinedIcon fontSize="inherit" />
            </ExpandButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {expanded && (
              <Box
                sx={{
                  border: "solid 2px",
                  borderColor: "info.main",
                  pt: "1rem",
                  borderRadius: "5px",
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={1}
                    justifyContent={"space-evenly"}
                    sx={{ ml: ".125rem", mr: ".5rem" }}
                  >
                    <Grid item xs={3}>
                      <Tooltip title="Pull items to use in an event, rent, or sell">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setPullingItem(true)}
                          fullWidth
                          size="small"
                        >
                          Pull Item
                        </Button>
                      </Tooltip>
                      <InventoryLogDialog
                        open={pulling_item}
                        inventory_item={props.inventory_item}
                        transaction_type="PU"
                        handleCancel={() => setPullingItem(false)}
                        auto
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip
                        title={
                          props.inventory_item.quantity_pulled === 0
                            ? "No items are rented"
                            : "Return rented items"
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setReturningItem(true)}
                            fullWidth
                            size="small"
                            disabled={
                              props.inventory_item.quantity_pulled === 0
                            }
                          >
                            Return Item
                          </Button>
                        </span>
                      </Tooltip>
                      <InventoryLogDialog
                        open={returning_item}
                        inventory_item={props.inventory_item}
                        transaction_type="RS"
                        handleCancel={() => setReturningItem(false)}
                        auto
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title="Manually edit the inventory quantities associated with this item">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setAdjustingItem(true)}
                          fullWidth
                          size="small"
                        >
                          Edit Stock Levels
                        </Button>
                      </Tooltip>
                      <InventoryLogDialog
                        open={adjusting_item}
                        inventory_item={props.inventory_item}
                        transaction_type="AD"
                        handleCancel={() => setAdjustingItem(false)}
                        initialValues={{
                          adjust_total: props.inventory_item.quantity_total,
                          adjust_in_stock:
                            props.inventory_item.quantity_in_stock,
                          adjust_pulled: props.inventory_item.quantity_pulled,
                          adjust_receiving:
                            props.inventory_item.quantity_receiving,
                          adjust_purchased:
                            props.inventory_item.quantity_purchased,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title="Edit item details like category, color, etc.">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setEditingItemDetails(true)}
                          fullWidth
                          size="small"
                        >
                          Edit Item Details
                        </Button>
                      </Tooltip>
                      <CreateItemDialog
                        initialValues={props.inventory_item.item}
                        open={editing_item_details}
                        handleCancel={() => setEditingItemDetails(false)}
                        disablePricing
                        handleSubmit={updateItem}
                        disableName={true}
                        disableColorName={true}
                        item_type={"MA"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{ p: "1rem" }}
                  justifyContent={"center"}
                >
                  <InventoryItemsDetailsSection label="Stock">
                    <Grid container>
                      <InventoryDetailLine
                        label="Total"
                        value={props.inventory_item.quantity_total}
                      />
                      <InventoryDetailLine
                        label="In Stock"
                        value={props.inventory_item.quantity_in_stock}
                        odd
                      />
                      <InventoryDetailLine
                        label="Rented"
                        value={props.inventory_item.quantity_pulled}
                      />
                      <InventoryDetailLine
                        label="Purchased"
                        value={props.inventory_item.quantity_purchased}
                        odd
                      />
                      <InventoryDetailLine
                        label="Receiving"
                        value={props.inventory_item.quantity_receiving}
                      />
                    </Grid>
                  </InventoryItemsDetailsSection>
                  <InventoryItemsDetailsSection
                    label="Location"
                    action={
                      <>
                        <Tooltip title="Edit item location">
                          <IconButton
                            size="small"
                            onClick={() => setEditingItemLocation(true)}
                          >
                            <EditOutlinedIcon
                              fontSize="inherit"
                              sx={{ height: ".75rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <EditItemLocationDialog
                          open={editing_item_location}
                          inventory_item={props.inventory_item}
                          handleCancel={() => setEditingItemLocation(false)}
                        />
                      </>
                    }
                  >
                    <InventoryDetailLine
                      label="Address"
                      value={
                        props.inventory_item.inventory_location
                          ?.inventory_address
                          ? FormatInventoryAddress(
                              props.inventory_item.inventory_location
                                ?.inventory_address
                            )
                          : "Unknown"
                      }
                    />
                    <InventoryDetailLine
                      label="Location"
                      value={
                        props.inventory_item.inventory_location
                          ? props.inventory_item.inventory_location?.name
                          : "Unknown"
                      }
                    />
                  </InventoryItemsDetailsSection>
                  <InventoryItemsDetailsSection label="Description">
                    {(props.inventory_item.item.height ||
                      props.inventory_item.item.width ||
                      props.inventory_item.item.length) && (
                      <InventoryDetailLine
                        label="Size"
                        value={materialDisplaySize(props.inventory_item.item)}
                      />
                    )}
                    {props.inventory_item.item.weight && (
                      <InventoryDetailLine
                        label="Weight"
                        value={props.inventory_item.item.weight}
                      />
                    )}
                    <InventoryDetailLine label="Color">
                      {RenderColors(props.inventory_item.item)}
                    </InventoryDetailLine>
                  </InventoryItemsDetailsSection>
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      size="small"
                      onClick={() => setRemovingItem(true)}
                    >
                      Remove From Inventory
                    </Button>
                    <RemoveInventoryItemDialog
                      inventory_item={props.inventory_item}
                      open={removingItem}
                      handleCancel={() => setRemovingItem(false)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Collapse>
        </Grid>
      </Grid>

      <Divider sx={{ width: "100%", m: ".5rem" }} />
    </>
  );
};

const QuantityDisplay = (props) => {
  return (
    <Grid item xs="auto" container>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: ".75rem", textAlign: "center" }}>
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ textAlign: "center" }}>{props.quantity}</Typography>
      </Grid>
    </Grid>
  );
};

const InventoryItemsDetailsSection = (props) => {
  return (
    <Grid item xs={12} container justifyContent={"space-between"}>
      <Grid item xs="auto">
        <Typography sx={{ fontSize: ".75rem", fontWeight: "bold" }}>
          {props.label}
        </Typography>
      </Grid>
      {props.action && (
        <Grid item xs="auto">
          {props.action}
        </Grid>
      )}
      <Divider sx={{ width: "100%", mb: ".25rem" }} />
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
};

const InventoryDetailLine = (props) => {
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent={"space-between"}
      sx={{
        pl: ".25rem",
        pr: ".25rem",
        backgroundColor: props.odd ? "info.light" : undefined,
      }}
    >
      <Grid item xs="auto">
        <Typography sx={{ fontSize: ".75rem" }}>{props.label}</Typography>
      </Grid>
      <Grid item xs="auto">
        <Typography sx={{ fontSize: ".75rem" }}>
          {props.value !== undefined ? props.value : props.children}
        </Typography>
      </Grid>
    </Grid>
  );
};

const FormatInventoryAddress = (inventory_address) => {
  return [
    inventory_address.name,
    inventory_address.address_1,
    inventory_address.address_2,
    inventory_address.city,
    inventory_address.state,
    inventory_address.zip_code,
  ]
    .filter((ia) => ia !== null && ia !== undefined && ia !== "")
    .join(", ");
};

export const RenderColors = (item) => {
  const colors = itemColors("MA"); //material colors encompass all color options
  return (
    <Grid container key={"colors-" + item.uuid} spacing={0.25}>
      {colors
        .filter((color) => {
          return item[color.value];
        })
        .map((color) => (
          <Grid item xs="auto">
            <Tooltip title={color.label}>
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  mr: ".25rem",
                  backgroundColor: color.color_code,
                }}
              ></Box>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

const EditItemLocationDialog = (props) => {
  const [inventoryLocations, updateInventoryItem] = useAccountState((state) => [
    state.inventoryLocations,
    state.updateInventoryItem,
  ]);
  const [addingLocation, setAddingLocation] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      inventory_item_uuid: props.inventory_item.uuid,
      inventory_location: props.inventory_item.inventory_location,
    },
    onSubmit: (values, { resetForm }) => {
      updateInventoryItem({
        uuid: values.inventory_item_uuid,
        inventory_location: values.inventory_location,
      });
      props.handleCancel();
      resetForm();
    },
  });

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        Edit {itemDisplayName(props.inventory_item.item)} Location
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ pt: "0.5rem" }}>
          <Grid item xs={12}>
            <Autocomplete
              id="inventory_location"
              options={sortItemsByLocation(inventoryLocations)}
              getOptionLabel={(option) =>
                `${option.inventory_address?.name} ${option.name}`
              }
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              groupBy={(option) => option.inventory_address.name}
              onChange={(event, value) => {
                formik.setFieldValue("inventory_location", value);
              }}
              value={formik.values.inventory_location}
              renderInput={(params) => (
                <TextField {...params} label="Location" />
              )}
              fullWidth
              noOptionsText={
                <Typography>
                  {`No locations match your search. `}
                  <Link
                    onClick={() => {
                      setAddingLocation(true);
                    }}
                  >
                    {`Create a new location?`}
                  </Link>
                </Typography>
              }
            />
            <AddEditLocationDialog
              open={addingLocation}
              handleCancel={() => setAddingLocation(false)}
              cb={(location) => {
                formik.setFieldValue("inventory_location", location);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} variant="outlined" color="info">
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddInventoryItemDialog = (props) => {
  const [
    addInventoryItem,
    createCustomItem,
    inventoryItems,
    inventoryLocations,
    selectableMaterials,
  ] = useAccountState((state) => [
    state.addInventoryItem,
    state.createCustomItem,
    state.inventoryItems,
    state.inventoryLocations,
    state.selectableMaterials,
  ]);
  const filteredSelectableItems = () => {
    let selectable_materials = selectableMaterials.filter(
      (item) => !inventoryItems.map((ii) => ii.item.uuid).includes(item.uuid)
    );

    return sort(selectable_materials).by([{ asc: "type" }, { asc: "name" }]);
  };
  const formik = useFormik({
    initialValues: {
      item_id: props.item ? props.item.uuid : null,
      inventory_location: null,
      adjust_total: 0,
      adjust_in_stock: 0,
      adjust_pulled: 0,
      adjust_receiving: 0,
      adjust_purchased: 0,
      ...props.initialValues,
    },
    onSubmit: (values, { resetForm }) => {
      addInventoryItem(values, props.cb);
      props.handleCancel();
      resetForm();
      setAuto(false);
    },
  });
  const handleCancel = () => {
    setAuto(props.auto);
    formik.resetForm();
    props.handleCancel();
  };
  const [creatingHardGood, setCreatingHardGood] = React.useState(false);
  const [item, setItem] = React.useState(props.item ? props.item : null);
  const [addingLocation, setAddingLocation] = React.useState(false);
  const [location, setLocation] = React.useState(null);
  const handleCreateItem = (item) => {
    setItem(item);
    formik.setFieldValue("item_id", item.uuid);
    setCreatingHardGood(false);
  };
  const [auto, setAuto] = React.useState(props.auto);
  return (
    <Dialog open={props.open} onClose={handleCancel} fullWidth>
      <DialogTitle>Add Item to Inventory</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ pt: "0.5rem" }}>
          <Grid item xs={12}>
            {!props.item ? (
              <Autocomplete
                id="item"
                value={item}
                options={filteredSelectableItems()}
                getOptionLabel={(option) => itemDisplayName(option)}
                onChange={(event, value) => {
                  setItem(value);
                  formik.setFieldValue("item_id", value?.uuid);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.uuid === value.uuid
                }
                renderInput={(params) => (
                  <TextField {...params} label="Hard Good" />
                )}
                fullWidth
                renderOption={(props, option, state) => {
                  return (
                    <Box {...props} key={option.uuid}>
                      <ItemIcon
                        item={option}
                        sx={{
                          mr: ".25rem",
                        }}
                      />
                      <Typography>{itemDisplayName(option)}</Typography>
                    </Box>
                  );
                }}
                noOptionsText={
                  <Typography>
                    {`No hard goods match your search. `}
                    <Link
                      onClick={() => {
                        setCreatingHardGood(true);
                      }}
                    >
                      {`Create a new hard good?`}
                    </Link>
                  </Typography>
                }
                groupBy={(option) => materialTypeName(option.type)}
              />
            ) : (
              <Typography>{itemDisplayName(item)}</Typography>
            )}
            <CreateItemDialog
              open={creatingHardGood}
              handleCancel={() => setCreatingHardGood(false)}
              disablePricing
              handleSubmit={handleCreateItem}
              item_type={"MA"}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="inventory_location"
              value={location}
              options={inventoryLocations}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                formik.setFieldValue("inventory_location_id", value.uuid);
                setLocation(value);
              }}
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              renderInput={(params) => (
                <TextField {...params} label="Location" />
              )}
              fullWidth
              groupBy={(option) => option.inventory_address.name}
              noOptionsText={
                <Typography>
                  {`No location match your search. `}
                  <Link
                    onClick={() => {
                      setAddingLocation(true);
                    }}
                  >
                    {`Create a new location?`}
                  </Link>
                </Typography>
              }
            />

            <AddEditLocationDialog
              open={addingLocation}
              handleCancel={() => setAddingLocation(false)}
              cb={(location) => {
                setLocation(location);
                formik.setFieldValue("inventory_location_id", location.uuid);
              }}
            />
          </Grid>
          {!props.disableInStock && (
            <AdjustInventoryQuantityFields formik={formik} auto={auto} />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs="auto">
            {auto && (
              <Button
                onClick={() => setAuto(false)}
                variant="outlined"
                color="info"
              >
                Advanced
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            justifyContent={"flex-end"}
            spacing={1}
          >
            <Grid item xs="auto">
              <Button onClick={handleCancel} variant="outlined" color="info">
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={formik.handleSubmit}
                variant="contained"
                color="secondary"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const RemoveInventoryItemDialog = (props) => {
  const [deleteInventoryItem] = useAccountState((state) => [
    state.deleteInventoryItem,
  ]);

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        Remove {itemDisplayName(props.inventory_item.item)} from Inventory
      </DialogTitle>
      <DialogContent>
        <Typography>
          You will still be able to see transactions for this item on the "Log"
          tab but it will no longer appear on your "Inventory" tab.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => deleteInventoryItem(props.inventory_item.uuid)}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(InventoryItems);
