import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Moment from "moment";

import {
  deleteEventContractApi,
  getEventContractsApi,
} from "../api/ContractsApi.js";

import {
  Paper,
  Grid,
  Typography,
  Divider,
  Chip,
  Link,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { EmbeddedSignDialog } from "./Sign";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { documentIsSigned, getSignerInfo } from "../utils/document_utils.js";
import { useAccountState } from "../state/store.js";
import { sortByKey } from "../utils/utils.js";

const Documents = (props) => {
  const [proposal] = useAccountState((state) => [state.proposal]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ p: "1rem" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">Documents</Typography>
        </Grid>
        <Divider sx={{ width: "100%", mb: ".5rem" }}></Divider>
        {proposal.contracts
          .sort(sortByKey("date_created"))
          .map((document, doc_ind) => (
            <DocumentLine
              key={document.uuid}
              document={document}
              last={doc_ind == proposal.contracts.length - 1}
              florist={props.florist}
              signing={props.signing}
              CloseEmbeddedSign={props.CloseEmbeddedSign}
            />
          ))}
        {proposal.contracts.length === 0 ? (
          <Grid item xs={12}>
            {props.florist ? (
              <Box
                sx={{
                  borderStyle: "dotted",
                  borderColor: "info.light",
                  p: "2rem",
                  borderRadius: "7.5px",
                  p: "1rem",
                }}
              >
                <Typography color="info.light">
                  No documents have been generated for this event. Documents are
                  generated after a client views the proposal and chooses to
                  sign the contract.
                </Typography>
              </Box>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={props.Sign}
              >
                Generate & Sign Contract
              </Button>
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

const DocumentLine = (props) => {
  const [deleteContract] = useAccountState((state) => [state.deleteContract]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [signing, setSigning] = React.useState(false);
  const [deletingDoc, setDeletingDoc] = React.useState(false);

  return (
    <Grid
      item
      xs={12}
      container
      // spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={6} sm={7} container alignItems="center">
        <Grid item xs={12} sm={9}>
          <Typography
            variant={fullScreen ? "h6" : undefined}
            alignItems="center"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArticleOutlinedIcon fontsize="inherit" />
              {props.document.title}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            variant={fullScreen ? "body2" : undefined}
            textAlign={fullScreen ? "left" : "right"}
          >
            {Moment(props.document.date_created).format("M/D/YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sm={4}
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        {props.document.signers
          .filter((signer) => signer.is_client)
          .map((recipient, ind) => (
            <Grid item xs="auto">
              {recipient.signed ? (
                <Chip
                  color="success"
                  label="Signed"
                  variant="filled"
                  size="small"
                  sx={{ ml: "auto" }}
                  // sx={{
                  //     minWidth: "92px",
                  // }}
                />
              ) : (
                <Chip
                  label="Not Signed"
                  color="error"
                  // sx={{
                  //     minWidth: "92px",
                  // }}
                  variant="filled"
                  size="small"
                />
              )}
            </Grid>
          ))}
        <Grid item xs="auto" display="flex">
          {!props.florist ? (
            <EmbeddedSignDialog
              open={signing || (props.signing && props.last)}
              handleCancel={() => {
                setSigning(false);
                props.CloseEmbeddedSign();
              }}
              sign_page_url={getSignerInfo(
                props.document.signers,
                true,
                "sign_page_url"
              )}
              signed={getSignerInfo(props.document.signers, true, "signed")}
              Sign={() => setSigning(true)}
              document_id={props.document.id}
              florist={false}
            />
          ) : null}
          {props.florist ? (
            <Box display="flex">
              <EmbeddedSignDialog
                open={signing}
                handleCancel={() => {
                  setSigning(false);
                }}
                sign_page_url={getSignerInfo(
                  props.document.signers,
                  false,
                  "sign_page_url"
                )}
                signed={true}
                Sign={() => setSigning(true)}
                document_id={props.document.id}
                florist
              />
              {!documentIsSigned(props.document) ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setDeletingDoc(true);
                  }}
                  id="delete-document"
                >
                  <DeleteOutlineOutlinedIcon fontSize="inherit" />
                </IconButton>
              ) : null}
              <DeleteDocumentDialog
                open={deletingDoc}
                handleCancel={() => setDeletingDoc(false)}
                handleSubmit={() => {
                  deleteContract(props.document.uuid);
                }}
              />
            </Box>
          ) : null}
        </Grid>
      </Grid>

      {!props.last ? (
        <Divider
          sx={{
            width: "100%",
            borderColor: fullScreen ? "black" : undefined,
            mt: ".5rem",
          }}
        />
      ) : null}
    </Grid>
  );
};

const DeleteDocumentDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Document</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this document? This will completely
          remove the document from your FlowerBuddy account. This action cannot
          be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="cancel-delete-document"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={props.handleSubmit}
          id="confirm-delete-document"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Documents;
