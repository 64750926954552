import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";
import {
  copyContractFromSettingsApi,
  getDefaultContractSections,
  updateContractSectionApi,
} from "../api/ContractsApi";

import {
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  Divider,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";

import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useAccountState } from "../state/store";
import { LoadingButton } from "@mui/lab";

const Contract = (props) => {
  const [
    back_office,
    contractIsEnabled,
    contract_sections,
    eventLocked,
    updateBackOffice,
    userPlanType,
  ] = useAccountState((state) => [
    state.back_office,
    state.contractIsEnabled,
    state.contract_sections,
    state.eventLocked,
    state.updateBackOffice,
    state.userPlanType,
  ]);

  const [editing, setEditing] = React.useState(
    props.editing ? props.editing : false
  );
  const [copyContractDialogOpen, setCopyContractDialogOpen] =
    React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const toggleEditing = () => {
    setEditing(!editing);
  };
  const toggleCopyContractDialog = () => {
    setCopyContractDialogOpen(!copyContractDialogOpen);
  };

  const [enabling_contracts, setEnablingContracts] = React.useState(false);
  return (
    <Grid container>
      {props.editable && (
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={2}>
            <FormControlLabel
              control={<Switch color="primary" id="enable-contract-switch" />}
              label="Enable"
              labelPlacement={fullScreen ? "start" : "top"}
              checked={contractIsEnabled()}
              disabled={userPlanType() !== "UNL" || eventLocked()}
              onChange={(e) => {
                if (contractIsEnabled()) {
                  updateBackOffice({
                    contract_is_enabled: false,
                  });
                } else {
                  setEnablingContracts(true);
                }
              }}
            />
          </Grid>
          <Grid item xs={contractIsEnabled() ? 6 : 10}>
            <Typography>
              Enabling contracts will allow your clients to view and sign the
              terms and conditions you have added.
            </Typography>
          </Grid>
          <AutoSignConfirmationDialog
            open={enabling_contracts}
            handleCancel={() => setEnablingContracts(false)}
            handleSubmit={() => {
              updateBackOffice({
                contract_is_enabled: true,
              });
            }}
          />
          {contractIsEnabled() && (
            <Grid item xs={4} container spacing={1} justifyContent="flex-end">
              {!props.disableCopy && (
                <Grid item xs="auto">
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={toggleCopyContractDialog}
                    id="copy-contract-button"
                    size="small"
                    disabled={eventLocked()}
                  >
                    Copy From Settings
                  </Button>
                </Grid>
              )}
              {copyContractDialogOpen && (
                <CopyContractFromSettingsDialog
                  open={copyContractDialogOpen}
                  handleCancel={toggleCopyContractDialog}
                />
              )}
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleEditing}
                  id="edit-contract-button"
                  size="small"
                  disabled={eventLocked()}
                >
                  Edit
                </Button>
                <EditContractDialog
                  open={editing}
                  handleCancel={toggleEditing}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {contractIsEnabled() && props.editable ? (
        <Divider sx={{ width: "100%", mb: "1rem", mt: "1rem" }}></Divider>
      ) : null}
      {contractIsEnabled() && (
        <>
          {contract_sections.map((contract_section) => (
            <Grid
              item
              xs={12}
              container
              sx={{ mb: ".5rem" }}
              key={contract_section.uuid}
            >
              {contract_section.heading ? (
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {contract_section.heading}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: ".75rem" }}>
                  {back_office.contract_is_numbered
                    ? contract_section.order + 1 + ". "
                    : null}
                  {contract_section.body}
                </Typography>
              </Grid>
              <br />
            </Grid>
          ))}
          {contract_sections.length < 1 && props.editable && (
            <Grid item xs={12} container sx={{ mb: ".5rem" }}>
              <Alert severity="error">
                <Typography align="center">
                  No contract sections have been added. Your clients will see
                  the following:{" "}
                </Typography>
                <br />
                <Typography align="center">
                  "No additional terms. Click sign to review the scope of
                  services and event details."
                </Typography>
              </Alert>
            </Grid>
          )}
          {contract_sections.length < 1 && !props.editable && (
            <Grid item xs={12} container sx={{ mb: ".5rem" }}>
              <Typography align="center">
                No additional terms. Click sign to review the scope of services
                and event details.
              </Typography>
            </Grid>
          )}
          {!props.editable && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ ml: "auto" }}
                  onClick={props.Sign}
                  id="sign-contract-button"
                >
                  Sign
                </Button>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const EditContractDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Edit Contract</DialogTitle>
      <DialogContent>
        <ContractSettings />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ContractSettings = (props) => {
  const [
    addContractSection,
    back_office,
    contract_sections,
    deleteContractSection,
    contractSectionOrderDown,
    contractSectionOrderUp,
    updateBackOffice,
    updateContractSection,
  ] = useAccountState((state) => [
    state.addContractSection,
    state.back_office,
    state.contract_sections,
    state.deleteContractSection,
    state.contractSectionOrderDown,
    state.contractSectionOrderUp,
    state.updateBackOffice,
    state.updateContractSection,
  ]);

  const handleIsNumberedChange = (e) => {
    let data = {
      contract_is_numbered: e.target.checked,
    };
    updateBackOffice(data);
  };

  const sortSections = (a, b) => {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={7}>
            <Typography>
              These sections will show up in the contract to be signed by your
              clients.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={<Switch color="primary" id="number-contract-switch" />}
              label="Numbered"
              labelPlacement="top"
              checked={back_office.contract_is_numbered}
              onChange={handleIsNumberedChange}
            />
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="secondary"
              onClick={addContractSection}
              startIcon={<AddOutlinedIcon fontSize="inherit" />}
              id="add-contract-section-button"
            >
              Add Section
            </Button>
          </Grid>
        </Grid>
        {contract_sections.sort(sortSections).map((contract_section) => (
          <ContractSectionInput
            id={"contract-section-input" + contract_section.uuid}
            key={contract_section.uuid}
            contract_section={contract_section}
            deleteSection={deleteContractSection}
            moveOrderUp={contractSectionOrderUp}
            moveOrderDown={contractSectionOrderDown}
            numbered={back_office.contract_is_numbered}
            updateContractSection={updateContractSection}
          />
        ))}
        {contract_sections.length > 0 ? (
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="secondary"
              onClick={addContractSection}
              startIcon={<AddOutlinedIcon fontSize="inherit" />}
              id="add-contract-section-button-duplicate"
            >
              Add Section
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export const ContractSectionInput = (props) => {
  const [body, setBody] = React.useState(props.contract_section.body);
  const [heading, setHeading] = React.useState(props.contract_section.heading);

  const handleChange = (e) => {
    let tmp_value = e.target.value;
    if (props.numbered) {
      tmp_value = tmp_value.replace(
        String(props.contract_section.order + 1) + ". ",
        ""
      );
    }
    setBody(tmp_value);
  };

  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  const updateSection = () => {
    let data = {
      uuid: props.contract_section.uuid,
      body: body,
      heading: heading,
    };
    props.updateContractSection(data);
    updateContractSectionApi(data).then((resp) => {
      console.log(resp);
    });
  };

  return (
    <Grid item xs={12} container spacing={1} alignItems="center">
      <Grid item xs={11}>
        <TextField
          value={heading}
          onChange={handleHeadingChange}
          onBlur={updateSection}
          fullWidth
          label="Heading (Optional)"
          id={props.id + "-heading-input"}
        />
      </Grid>
      <Grid item xs={1} container>
        <Grid item xs={12}>
          <IconButton
            size="small"
            onClick={() => props.moveOrderDown(props.contract_section.uuid)}
            id={props.id + "-move-down-button"}
          >
            <ArrowDownwardOutlinedIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => props.moveOrderUp(props.contract_section.uuid)}
            id={props.id + "-move-up-button"}
          >
            <ArrowUpwardOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <IconButton
            size="small"
            onClick={() => props.deleteSection(props.contract_section.uuid)}
            id={props.id + "-delete-button"}
          >
            <DeleteOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        {props.numbered ? (
          <Grid item xs="auto">
            <Typography>{props.contract_section.order + 1 + ". "}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={props.numbered ? 11 : 12}>
          <TextField
            value={body}
            onChange={handleChange}
            onBlur={updateSection}
            fullWidth
            multiline
            label="Body"
            id={props.id + "-body-input"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const AutoSignConfirmationDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Auto-Sign Confirmation</DialogTitle>
      <DialogContent>
        <Typography>
          By enabling the Contracts feature, you agree to let FlowerBuddy
          generate automatically signed contracts on your behalf. These
          contracts will contain terms you supply and can edit at any time in
          addition to relevant information from the proposal you create.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          id="auto-sign-confirm-cancel-button"
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
        >
          Cancel
        </Button>
        <Button
          id="auto-sign-confirm-agree-button"
          variant="contained"
          color="secondary"
          onClick={() => {
            props.handleSubmit();
            props.handleCancel();
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CopyContractFromSettingsDialog = (props) => {
  const [event, initializeContractSections] = useAccountState((state) => [
    state.event,
    state.initializeContractSections,
  ]);
  const [loading, setLoading] = React.useState(true);
  const [contractSections, setContractSections] = React.useState([]);
  React.useEffect(() => {
    getDefaultContractSections().then((resp) => {
      setContractSections(resp.data);
      setLoading(false);
    });
  }, []);

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    copyContractFromSettingsApi(event.uuid).then((resp) => {
      initializeContractSections(resp.data);
      props.handleCancel();
      setSubmitting(false);
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Copy Contract From Settings</DialogTitle>
      <DialogContent>
        <Grid container justifyContent={"center"} spacing={1}>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to replace the current contract sections
              with the contract in your settings?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }}>
              Settings Contract:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{ border: "solid 1px", borderRadius: "5px" }}
            justifyContent={"center"}
          >
            {!loading && (
              <Grid item xs={12} container spacing={1}>
                {contractSections.map((contract_section) => (
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontWeight: "bold", fontSize: ".75rem" }}
                      >
                        {contract_section.heading}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: ".75rem" }}>
                        {contract_section.body}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            {loading && (
              <Grid item xs={"auto"}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id="copy-contract-from-cancel-button"
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          id="copy-contract-from-confirm-button"
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={loading}
          loading={submitting}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Contract);
