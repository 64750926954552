import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { useAccountState } from "../../state/store";
import { sort_by_order } from "../../utils/utils";
import { ColorPickerDialog } from "../Proposal";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { DefaultStyles } from "../../utils/proposal_utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const ColorPalette = (props) => {
  const [addPaletteColor, handleProposalColorDragEnd, removePaletteColor] =
    useAccountState((state) => [
      state.addPaletteColor,
      state.handleProposalColorDragEnd,
      state.removePaletteColor,
    ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [addingColor, setAddingColor] = useState(false);

  const setAddingColorTrue = () => {
    setAddingColor(true);
  };

  const setAddingColorFalse = () => {
    setAddingColor(false);
  };

  const _addPaletteColor = (color) => {
    addPaletteColor(props.proposal_section.uuid, color);
    setAddingColorFalse();
  };

  const _removePaletteColor = (color_uuid) => {
    removePaletteColor(props.proposal_section.uuid, color_uuid);
  };

  const boxDims = () => {
    return { width: "100%", height: 0, paddingBottom: "100%" };
  };

  const handleDragEnd = (result) => {
    handleProposalColorDragEnd(props.proposal_section.uuid, result);
  };

  return (
    <Box sx={{ p: "1rem" }}>
      <Grid container spacing={1} justifyContent="flex-start">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId={`proposal-colors-${props.proposal_section.uuid}`}
            direction="horizontal"
          >
            {(provided) => (
              <Grid
                item
                xs={12}
                container
                justifyContent={
                  props.proposal_section.style?.items_align ||
                  DefaultStyles.itemsAlign
                }
                spacing={1}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {!props.profileSettings &&
                  props.proposal_section.proposal_colors
                    .sort(sort_by_order)
                    .map((proposal_color, index) => (
                      <Draggable
                        draggableId={proposal_color.uuid}
                        index={index}
                        key={proposal_color.uuid}
                      >
                        {(provided, snapshot) => {
                          return (
                            <Grid
                              item
                              xs={2}
                              key={proposal_color.uuid}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box
                                sx={{
                                  backgroundColor: proposal_color.hex_code,
                                  ...boxDims(),
                                  ...ItemShapeProps[
                                    props.proposal_section.style?.item_shape ||
                                      DefaultStyles.itemShape
                                  ],
                                }}
                              >
                                <IconButton
                                  onClick={() =>
                                    _removePaletteColor(proposal_color.uuid)
                                  }
                                  id={
                                    proposal_color.uuid +
                                    "-remove-palette-color-button"
                                  }
                                >
                                  <ClearOutlinedIcon />
                                </IconButton>
                              </Box>
                            </Grid>
                          );
                        }}
                      </Draggable>
                    ))}
                {props.profileSettings &&
                  [...Array(3).keys()].map((proposal_color, index) => (
                    <Grid item xs={2} key={index}>
                      <Box
                        sx={{
                          backgroundColor: proposal_color.hex_code,
                          ...boxDims(),
                          ...ItemShapeProps[
                            props.proposal_section.style?.item_shape ||
                              DefaultStyles.itemShape
                          ],
                        }}
                      ></Box>
                    </Grid>
                  ))}
                {provided.placeholder}
                {!props.profileSettings &&
                  props.proposal_section.proposal_colors.length < 6 && (
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          width: "100%",
                          height: 0,
                          paddingBottom: "100%",
                          backgroundColor: "white",
                          borderStyle: "dotted",
                          borderColor: "lightgrey",
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            height: "100%",
                            width: "100%",
                            pt: "35%",
                          }}
                        >
                          <IconButton
                            size="large"
                            variant="outlined"
                            sx={{ m: "auto", p: 0 }}
                            onClick={setAddingColorTrue}
                            id="add-palette-color-button"
                          >
                            <AddCircleOutlineOutlinedIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                {!props.profileSettings && (
                  <Grid item xs={12} container justifyContent={"center"}>
                    <Grid item xs="auto">
                      <Typography
                        variant="caption"
                        color="info.main"
                        sx={{ mt: 1 }}
                      >
                        drag and drop to reorder
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
        <ColorPickerDialog
          open={addingColor}
          handleCancel={setAddingColorFalse}
          handleSubmit={_addPaletteColor}
        />
      </Grid>
    </Box>
  );
};

const ItemShapeProps = {
  square: {},
  circle: { borderRadius: "50%" },
  rounded: { borderRadius: "10%" },
  swatch: { borderRadius: "20% 0 20% 0" },
  thumbnail: { borderRadius: "50% 50% 5% 5%" },
};

export const ColorPalettePreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={0.5}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <Skeleton
          variant="rounded"
          width={50}
          height={5}
          sx={{ mt: 3.5 }}
          animation={false}
        />
      </Grid>
      {[...Array(5).keys()].map((i) => (
        <Grid item xs="auto">
          <Skeleton
            variant="rectangular"
            width={10}
            height={10}
            animation={false}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const ColorPaletteView = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const boxDims = () => {
    return { width: "100%", height: 0, paddingBottom: "100%" };
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent={
        props.proposal_section.style?.items_align || DefaultStyles.itemsAlign
      }
    >
      {props.proposal_section.proposal_colors
        .sort(sort_by_order)
        .map((proposal_color) => (
          <Grid item xs={2} key={proposal_color.uuid}>
            <Box
              boxShadow={1}
              sx={{
                backgroundColor: proposal_color.hex_code,
                ...boxDims(),
                ...ItemShapeProps[
                  props.proposal_section.style?.item_shape ||
                    DefaultStyles.itemShape
                ],
              }}
            ></Box>
          </Grid>
        ))}
    </Grid>
  );
};
