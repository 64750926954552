import React, { useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { addTagApi, deleteTagApi, getTagsApi } from "../api/TagApi";
import { flower_colors, getColorNameByCode } from "../utils/item_utils";
import { useAccountState } from "../state/store";
import { matchSorter } from "match-sorter";
import { LoadingButton } from "@mui/lab";
import {
  sort_by_relevance,
  sortByCreated,
  sortByKey,
  sortByName,
} from "../utils/utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const searchTags = (tags, value) =>
  matchSorter(tags, value, { keys: ["name"] });

const searchColors = (colors, value) =>
  matchSorter(colors, value, { keys: ["label"] });

const Tags = (props) => {
  const [updateImageInState, updateEventItemInState, updateEventInState] =
    useAccountState((state) => [
      state.updateImageInState,
      state.updateEventItemInState,
      state.updateEventInState,
    ]);

  const [adding, setAdding] = React.useState(false);
  const toggleAdding = () => setAdding(!adding);

  const [addingColor, setAddingColor] = React.useState(false);
  const toggleAddingColor = () => setAddingColor(!addingColor);

  const tags = () => {
    if (props.event_item) {
      return props.event_item.tags;
    } else if (props.cropped_image) {
      return props.cropped_image.tags;
    } else if (props.event) {
      return props.event.tags;
    } else {
      return [];
    }
  };

  const updateInState = (data) => {
    if (props.event_item) {
      updateEventItemInState(data);
    } else if (props.cropped_image) {
      updateImageInState(data);
    } else if (props.event) {
      updateEventInState(data);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={0.5}>
        {tags()
          .filter((tag) => tag.name)
          .map((tag) => (
            <Grid item xs="auto">
              <Tag
                tag={tag}
                event_item_uuid={props.event_item?.uuid}
                cropped_image_uuid={props.cropped_image?.uuid}
                event_uuid={props.event?.uuid}
                tags={tags()}
                deleteTagInState={updateInState}
              />
            </Grid>
          ))}
        <Grid item xs={"auto"}>
          <Chip
            label={props.addTagLabel ? props.addTagLabel : "+ Tag"}
            variant="outlined"
            size="small"
            onClick={toggleAdding}
          />
        </Grid>
        {adding && (
          <AddTagDialog
            open={adding}
            handleCancel={toggleAdding}
            tags={tags()}
            updateInState={updateInState}
            event_item_uuid={props.event_item?.uuid}
            cropped_image_uuid={props.cropped_image?.uuid}
            event_uuid={props.event?.uuid}
          />
        )}
      </Grid>
      <Grid item xs={12} container spacing={0.5}>
        {tags()
          .filter((tag) => tag.color)
          .map((tag) => (
            <Grid item xs="auto">
              <Tag
                tag={tag}
                event_item_uuid={props.event_item?.uuid}
                cropped_image_uuid={props.cropped_image?.uuid}
                tags={tags()}
                deleteTagInState={updateInState}
              />
            </Grid>
          ))}
        {!props.disableColorTags && (
          <Grid item xs={"auto"}>
            <Chip
              label="+ Color"
              variant="outlined"
              size="small"
              onClick={toggleAddingColor}
            />
          </Grid>
        )}
        {!props.disableColorTags && (
          <AddColorTagDialog
            open={addingColor}
            handleCancel={toggleAddingColor}
            tags={tags()}
            updateInState={updateInState}
            event_item_uuid={props.event_item?.uuid}
            cropped_image_uuid={props.cropped_image?.uuid}
          />
        )}
      </Grid>
    </Grid>
  );
};

const Tag = (props) => {
  const deleteTag = () => {
    let new_tags = props.tags.filter((tag) => tag.uuid !== props.tag.uuid);
    props.deleteTagInState({
      uuid:
        props.event_item_uuid || props.cropped_image_uuid || props.event_uuid,
      tags: new_tags,
    });
    deleteTagApi({
      event_item_uuid: props.event_item_uuid,
      cropped_image_uuid: props.cropped_image_uuid,
      event_uuid: props.event_uuid,
      uuid: props.tag.uuid,
    });
  };
  return props.tag.name ? (
    <Chip
      key={props.tag.uuid}
      label={props.tag.name}
      size="small"
      onDelete={deleteTag}
    />
  ) : (
    <Chip
      size="small"
      onDelete={deleteTag}
      icon={
        <Box
          sx={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            mr: ".25rem",
            backgroundColor: props.tag.color,
          }}
        ></Box>
      }
      label={getColorNameByCode(props.tag.color)}
    />
  );
};

const AddTagDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    getTagsApi().then((resp) => {
      setOptions(resp.data.filter((tag) => tag.name));
    });
  }, []);

  const [loading, setLoading] = React.useState(false);

  const handleAdd = () => {
    setLoading(true);
    addTagApi({
      event_item_uuid: props.event_item_uuid,
      cropped_image_uuid: props.cropped_image_uuid,
      event_uuid: props.event_uuid,
      name: value.name,
    }).then((resp) => {
      let new_tags = [...props.tags, resp.data];
      let data = {
        uuid:
          props.event_item_uuid || props.cropped_image_uuid || props.event_uuid,
        tags: new_tags,
      };
      props.updateInState(data);
      setValue(null);
      props.handleCancel();
      setLoading(false);
    });
  };

  const OptionGroup = (option) => {
    if (props.event_uuid) {
      console.log("OPTION GROUP EVENT", option, option.has_event);
      return option.has_event ? "Event Tags" : "Other Tags";
    } else if (props.event_item_uuid) {
      return option.has_eventitem ? "Event Item Tags" : "Other Tags";
    } else if (props.cropped_image_uuid) {
      return option.has_croppedimage ? "Cropped Image Tags" : "Other Tags";
    }
  };

  const OptionsWithGroup = () => {
    let ops = options.map((tag) => {
      return { ...tag, group: OptionGroup(tag) };
    });
    return ops.sort(sortByKey("group"));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullScreen={fullScreen}
    >
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: fullScreen ? undefined : "300px", pt: ".5rem" }}>
          <Autocomplete
            options={OptionsWithGroup()}
            groupBy={(option) => option.group}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            renderInput={(params) => (
              <TextField {...params} label="Tag" variant="outlined" />
            )}
            renderOption={(props, option, state) => {
              let label = typeof option === "string" ? option : option.name;
              let color =
                typeof option === "string" ? "primary.main" : undefined;
              return (
                <Typography {...props} sx={{ color: color }}>
                  {label}
                </Typography>
              );
            }}
            fullWidth
            fullScreen={fullScreen}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  name: newValue.replace("Add ", "").replaceAll('"', ""),
                });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              let existing_tags = props.tags.map((tag) => tag.name);
              let filtered = options.filter(
                (option) => !existing_tags.includes(option.name)
              );

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.name
              );
              filtered = searchTags(filtered, inputValue);
              if (inputValue !== "" && !isExisting) {
                filtered.push(`Add "${inputValue}"`);
              }
              filtered = filtered.sort(sortByKey("group"));

              return filtered;
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={handleAdd}
          disabled={!value}
          loading={loading}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const AddColorTagDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleAdd = () => {
    setLoading(true);
    addTagApi({
      event_item_uuid: props.event_item_uuid,
      cropped_image_uuid: props.cropped_image_uuid,
      color: value.color_code,
    }).then((resp) => {
      let new_tags = [...props.tags, resp.data];
      props.updateInState({
        uuid: props.event_item_uuid || props.cropped_image_uuid,
        tags: new_tags,
      });
      setValue(null);
      props.handleCancel();
      setLoading(false);
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: fullScreen ? undefined : "300px", pt: ".5rem" }}>
          <Autocomplete
            options={flower_colors}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Tag" variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option) => {
              return [
                null,
                <Box
                  key={option.color_code}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  {...props}
                >
                  <Box
                    sx={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      mr: ".25rem",
                      backgroundColor: option.color_code,
                    }}
                  ></Box>
                  <Typography>{option.label}</Typography>
                </Box>,
              ];
            }}
            fullWidth
            fullScreen={fullScreen}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  name: newValue.replace("Add ", "").replaceAll('"', ""),
                });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              let existing_tags = props.tags.map((tag) => tag.color);
              const filtered = options.filter(
                (option) => !existing_tags.includes(option.color_code)
              );

              const { inputValue } = params;

              console.log("INPUT VALUE", inputValue);
              console.log("FILTERED", filtered);

              return searchColors(filtered, inputValue);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={handleAdd}
          disabled={!value}
          loading={loading}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const TagFilterAndSearch = (props) => {
  const [filterTags, setFilterTags] = React.useState([]);
  const [addingFilterTags, setAddingFilterTags] = React.useState(false);
  const handleAddFilterTags = (tags) => {
    let new_tags = tags.filter((tag) => !filterTags.includes(tag));
    setFilterTags([...filterTags, ...new_tags]);
  };

  const [filterColors, setFilterColors] = React.useState([]);
  const [addingColors, setAddingColors] = React.useState(false);
  const handleAddColors = (colors) => {
    let new_colors = colors.filter((color) => !filterColors.includes(color));
    setFilterColors([...filterColors, ...new_colors]);
  };

  const [search, setSearch] = React.useState("");

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredList = React.useMemo(() => {
    let filtered = props.list;
    props.staticFilters?.forEach((filter) => {
      filtered = filtered.filter(filter);
    });
    filtered = filtered.map((li) => {
      return { ...li, relevance: 0 };
    });
    if (filterTags.length > 0) {
      filtered = filtered.map((li) => {
        let relevance = li.tags.filter((tag) =>
          filterTags.includes(tag.name)
        ).length;
        return { ...li, relevance: relevance + li.relevance };
      });
    }
    if (filterColors.length > 0) {
      filtered = filtered.map((li) => {
        let relevance = li.tags.filter((tag) =>
          filterColors.includes(tag.color)
        ).length;
        return { ...li, relevance: relevance + li.relevance };
      });
    }
    if (filterTags.length > 0 || filterColors.length > 0) {
      filtered = filtered.filter(
        (li) => li.relevance >= filterTags.length + filterColors.length
      );
    }
    filtered = filtered.map((li) => {
      props.searchKeys.forEach((key) => {
        if (!li[key]) {
          li[key] = "";
        }
      });
      return li;
    });
    let filtered_list = matchSorter(filtered, search, {
      keys: props.searchKeys,
    }).sort(sortByCreated);

    props.setFilteredList(filtered_list);
    return filtered_list;
  }, [props.list, filterTags, filterColors, search]);
  const clearFilters = () => {
    setFilterTags([]);
    setFilterColors([]);
    setSearch("");
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange}
          size="small"
        />
      </Grid>
      <Grid item xs={12} container spacing={0.5}>
        <Grid item xs={"auto"}>
          <Chip
            label="+ Tag Filter"
            size="small"
            variant="outlined"
            onClick={() => setAddingFilterTags(true)}
          />
          <AddTagFilterDialog
            handleCancel={() => setAddingFilterTags(false)}
            handleSubmit={handleAddFilterTags}
            open={addingFilterTags}
            list={filteredList}
            filterTags={filterTags}
          />
        </Grid>
        {!props.disableColorTags && (
          <Grid item xs={"auto"}>
            <Chip
              label="+ Color Filter"
              size="small"
              variant="outlined"
              onClick={() => setAddingColors(true)}
            />
            <AddTagFilterDialog
              handleCancel={() => setAddingColors(false)}
              handleSubmit={handleAddColors}
              open={addingColors}
              list={filteredList}
              filterColors={filterColors}
              colors
            />
          </Grid>
        )}
        {(filterTags.length > 0 || filterColors.length > 0 || search) && (
          <Grid item xs={"auto"}>
            <Chip
              label="Clear Filters"
              size="small"
              variant="outlined"
              onClick={() => clearFilters()}
            />
          </Grid>
        )}
      </Grid>
      {(filterTags.length > 0 || filterColors.length > 0) && (
        <Grid item xs={12} container spacing={0.5}>
          {filterTags.map((tag) => (
            <Grid item xs="auto">
              <Chip
                label={tag}
                size="small"
                onDelete={() =>
                  setFilterTags(filterTags.filter((t) => t !== tag))
                }
              />
            </Grid>
          ))}
          {filterColors.map((tag) => (
            <Grid item xs="auto">
              <Chip
                icon={
                  <Box
                    sx={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      mr: ".25rem",
                      backgroundColor: tag,
                    }}
                  ></Box>
                }
                label={getColorNameByCode(tag)}
                size="small"
                onDelete={() =>
                  setFilterColors(filterColors.filter((t) => t !== tag))
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const AddTagFilterDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [filterTags, setFilterTags] = React.useState([]);

  const options = () => {
    if (!props.list) {
      return [];
    }
    const tag_key = props.colors ? "color" : "name";
    let tags = props.list.map((ei) => ei.tags).flat();
    tags = tags.filter((tag) => tag[tag_key]);
    tags = tags.map((tag) => tag[tag_key]);
    tags = tags.filter((t) => !props.filterTags?.includes(t));
    return [...new Set(tags)].sort();
  };

  const handleCancel = () => {
    setFilterTags([]);
    props.handleCancel();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={handleCancel}>
      <DialogTitle>Add Tag Filter</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: ".5rem", minWidth: fullScreen ? undefined : "300px" }}>
          <Autocomplete
            multiple
            id="tags-filter"
            options={options()}
            filterSelectedOptions
            value={filterTags}
            onChange={(event, value) => setFilterTags(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tags"
                placeholder="Tags"
              />
            )}
            filterOptions={
              props.colors
                ? (options, params) => {
                    let optionsWithLabel = options.map((option) => {
                      return {
                        code: option,
                        label: getColorNameByCode(option),
                      };
                    });
                    let filtered_options = matchSorter(
                      optionsWithLabel,
                      params.inputValue,
                      { keys: ["label"] }
                    );
                    return filtered_options.map((option) => option.code);
                  }
                : undefined
            }
            renderTags={
              props.colors
                ? (values, getTagProps) => {
                    return values.map((value, ind) => (
                      <Box
                        key={value}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        {...getTagProps(value)}
                      >
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            mr: ".25rem",
                            backgroundColor: value,
                          }}
                        ></Box>
                        <Typography>{getColorNameByCode(value)}</Typography>
                      </Box>
                    ));
                  }
                : undefined
            }
            renderOption={
              props.colors
                ? (props, option) => {
                    return [
                      null,
                      <Box
                        key={option}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        {...props}
                      >
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            mr: ".25rem",
                            backgroundColor: option,
                          }}
                        ></Box>
                        <Typography>{getColorNameByCode(option)}</Typography>
                      </Box>,
                    ];
                  }
                : undefined
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.handleSubmit(filterTags);
            handleCancel();
          }}
          color="secondary"
          variant="contained"
          disabled={filterTags.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TagExplorer = (props) => {
  const [tags] = useAccountState((state) => [state.tags]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Tag</TableCell>
          <TableCell>Event Templates</TableCell>
          <TableCell>Recipe Templates</TableCell>
          <TableCell>Library Images</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tags.sort(sortByName).map((tag) => (
          <TagRow tag={tag} />
        ))}
      </TableBody>
    </Table>
  );
};

const TagRow = (props) => {
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => setEditing(!editing);

  const [deleting, setDeleting] = React.useState(false);
  const toggleDeleting = () => setDeleting(!deleting);
  return (
    <TableRow>
      <TableCell>{props.tag.name}</TableCell>
      <TableCell>{props.tag.event_count}</TableCell>
      <TableCell>{props.tag.event_item_count}</TableCell>
      <TableCell>{props.tag.cropped_image_count}</TableCell>
      <TableCell sx={{ width: "100px" }}>
        <Grid container spacing={1} justifyContent={"flex-end"}>
          <Grid item xs="auto">
            <IconButton size="small" onClick={toggleEditing}>
              <EditOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <IconButton size="small" onClick={toggleDeleting}>
              <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
      <EditTagDialog
        tag={props.tag}
        open={editing}
        handleCancel={toggleEditing}
      />
      <DeleteTagDialog
        tag={props.tag}
        open={deleting}
        handleCancel={toggleDeleting}
      />
    </TableRow>
  );
};

const EditTagDialog = (props) => {
  const [updateTag] = useAccountState((state) => [state.updateTag]);
  const [name, setName] = React.useState(props.tag.name);
  const handleChange = (event) => {
    setName(event.target.value);
  };
  const handleSubmit = () => {
    updateTag({ uuid: props.tag.uuid, name: name });
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Edit Tag</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={handleChange}
              sx={{ mt: ".5rem" }}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              This action will edit the name of the tag in all associated recipe
              templates and library images.
            </Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteTagDialog = (props) => {
  const [deleteTag] = useAccountState((state) => [state.deleteTag]);
  const handleDelete = () => {
    deleteTag(props.tag.uuid);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Tag</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the tag "{props.tag.name}"? This
          action cannot be undone.
        </Typography>
        <Alert severity="error" sx={{ mt: ".5rem" }}>
          This action will remove this tag from all associated items.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Tags);
