import React from "react";
import { withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tabs,
  Tab,
  InputAdornment,
} from "@mui/material";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CroppedImg from "./CroppedImg";
import { Cropper, FileDropZone } from "./FileUploader";
import { useAccountState } from "../state/store";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { DefaultStyles } from "../utils/proposal_utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DraggableList from "./DraggableList";
import { sort_by_order } from "../utils/utils";
import { GridControl } from "./Proposal";

const InspirationImgSectionV2 = (props) => {
  const [updateProposalSectionStyle] = useAccountState((state) => [
    state.updateProposalSectionStyle,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleUploadedFile = (images) => {
    var data = {
      cropped_image: images,
      version: 2,
    };
    props.addImg(data);
  };

  const max_photos = 50;

  return (
    <Grid container>
      {!props.displayOnly && (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs="auto">
            <GridControl
              label="Columns"
              id="inspiration_images_cols"
              defaultKey="imageCols"
              proposal_section={props.proposal_section}
              min={1}
              max={6}
              step={1}
            />
          </Grid>
          <Grid item xs="auto">
            <GridControl
              label="Spacing"
              id="spacing"
              defaultKey="spacing"
              proposal_section={props.proposal_section}
              min={0}
              max={25}
              step={1}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="info.main">
              Mobile:{" "}
              {(props.proposal_section.style?.inspiration_images_cols ||
                DefaultStyles.imageCols) === 1
                ? "1 column"
                : "2 columns"}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box>
          <DraggableList
            list={props.imgs
              .filter((img) => img.version === 2)
              .sort(sort_by_order)}
            listContainerComponent={ImageList}
            listContainerComponentProps={{
              variant: "masonry",
              cols:
                fullScreen &&
                (props.proposal_section.style?.inspiration_images_cols ||
                  DefaultStyles.imageCols) > 1
                  ? 2
                  : props.proposal_section.style?.inspiration_images_cols ||
                    DefaultStyles.imageCols,
              gap:
                props.proposal_section.style?.inspiration_images_gap ||
                DefaultStyles.imageGap,
            }}
            listItemComponent={InspirationImg}
            listItemComponentProps={{
              removeImg: props.removeImg,
              addImg: props.addImg,
              updateProposalImg: props.updateProposalImg,
              displayOnly: props.displayOnly,
              zoom: props.zoom,
              display: "contents",
            }}
            listItemPropName="img"
            onDragEnd={props.onDragEnd}
          />
        </Box>
      </Grid>
      {!props.displayOnly && (
        <Grid item xs={12} container justifyContent={"center"}>
          <Grid item xs="auto">
            <Typography variant="caption" color="info.main">
              drag and drop to reorder
            </Typography>
          </Grid>
        </Grid>
      )}
      {!props.displayOnly &&
      props.imgs.filter((img) => img.version === 2).length < max_photos ? (
        <Grid item xs={12}>
          <>
            <Box
              sx={{
                width: "95%",
                p: "1rem",
                borderStyle: "dotted",
                borderColor: "lightgrey",
                display: props.displayOnly ? "none" : "flex",
                overflow: "visible",
              }}
            >
              <Box sx={{ m: "auto", width: "100%", height: "100%" }}>
                <FileDropZone
                  hideBorder
                  width="100%"
                  height="100%"
                  id={props.id + "-dropzone"}
                  handleSubmit={handleUploadedFile}
                  maxSelections={
                    max_photos -
                    props.imgs.filter((img) => img.version === 2).length
                  }
                  disableMaxSelectionsText
                />
              </Box>
            </Box>
          </>
        </Grid>
      ) : null}
    </Grid>
  );
};

const InspirationImg = (props) => {
  const [img, setImg] = React.useState(props.img);
  const [cropping, setCropping] = React.useState(false);
  const toggleCropping = () => setCropping(!cropping);

  const handleCropSubmit = (cropped_images) => {
    props.updateProposalImg({
      uuid: img.uuid,
      cropped_image: { ...img.cropped_image, ...cropped_images[0] },
    });
  };

  const handleDelete = () => {
    props.removeImg(img.uuid);
    setImg(undefined);
  };

  React.useEffect(() => {
    setImg(props.img);
  }, [props.img, props.img.cropped_image]);

  const grid_props = props.displayOnly
    ? {}
    : {
        draggable: props.draggable,
        onDragStart: props.onDragStart,
        onDragOver: props.onDragOver,
        onDrop: props.onDrop,
        onDragEnd: props.onDragEnd,
        style: props.style,
      };

  return img ? (
    <ImageListItem
      id={props.id + "-image-list-item"}
      key={props.id + "-image-list-item"}
      {...grid_props}
    >
      {!props.displayOnly && (
        <ImageListItemBar
          sx={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
          }}
          // title={props.ind}
          position="top"
          actionIcon={
            <>
              <IconButton
                sx={{ color: "white" }}
                aria-label={`star ${img.uuid}`}
                onClick={handleDelete}
                size="small"
                id={props.id + "-delete-button"}
              >
                <ClearOutlinedIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                sx={{ color: "white" }}
                aria-label={`star ${img.uuid}`}
                onClick={toggleCropping}
                size="small"
                id={props.id + "-delete-button"}
              >
                <EditOutlinedIcon fontSize="inherit" />
              </IconButton>
            </>
          }
          actionPosition="left"
        />
      )}
      <CroppedImg img={img.cropped_image} zoom={props.zoom} width="100%" />
      {!props.displayOnly && (
        <Cropper
          open={cropping}
          handleCancel={toggleCropping}
          handleSubmit={handleCropSubmit}
          image={img.cropped_image}
          disableDelete
        />
      )}
    </ImageListItem>
  ) : null;
};

export default withRouter(InspirationImgSectionV2);
