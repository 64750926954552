import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";

import { sort } from "fast-sort";

import { getSelectableItems } from "../api/EventsApi";
import {
  getColorByCode,
  getColorNameByCode,
  getItemCategories,
  getItemColors,
  getItemSeasons,
  itemCategories,
  itemColors,
  itemDisplayName,
  itemSeasons,
  materialTypeName,
  sortByItemDisplayName,
} from "../utils/item_utils";

import { matchSorter } from "match-sorter";
import debounce from "lodash.debounce";

import {
  Avatar,
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Autocomplete,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Tabs,
  Tab,
  Checkbox,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableContainer,
  Paper,
  Chip,
  Tooltip,
} from "@mui/material";
import { ItemIcon } from "./ItemIcon";

import { useAccountState } from "../state/store.js";
import CreateItemDialog from "./CreateItemDialog";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { TableVirtuoso } from "react-virtuoso";
import { StackedItemName } from "./StackedItemName.js";

const AddItemDialog = (props) => {
  const isFlower = () => {
    return props.item_type === "FL";
  };
  const item_name = isFlower() ? "Flower" : "Hard Good";

  const [selectableFlowers, selectableMaterials] = useAccountState((state) => [
    state.selectableFlowers,
    state.selectableMaterials,
  ]);

  const filter_item_uuids = props.filter_items?.map((item) => item.uuid);
  const [selectableItems, setSelectableItems] = React.useState(
    isFlower() ? selectableFlowers : selectableMaterials
  ).filter((item) => !filter_item_uuids.includes(item.uuid));

  React.useEffect(() => {
    let selectable_items = isFlower() ? selectableFlowers : selectableMaterials;
    setSelectableItems(
      selectable_items.filter((item) => !filter_item_uuids.includes(item.uuid))
    );
  }, [selectableFlowers, selectableMaterials]);

  const [options, setOptions] = React.useState(
    selectableItems.sort(sortByItemDisplayName)
  );
  const [creatingNewItem, setCreatingNewItem] = React.useState(false);

  const [selectedItems, setSelectedItems] = React.useState([]);

  const itemIsSelected = (item) => {
    let idx = selectedItems.findIndex(
      (selectedItem) => selectedItem.uuid === item.uuid
    );
    return idx > -1;
  };

  const addSelectedItem = (item) => {
    if (
      !itemIsSelected(item) &&
      (selectedItems.length < props.max_selectable || !props.max_selectable)
    ) {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const removeSelectedItem = (item) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem.uuid !== item.uuid)
    );
  };

  const clearSelections = () => {
    setSelectedItems([]);
  };

  const handleSubmit = () => {
    props.addItems(selectedItems);
    props.handleCancel();
  };

  const selectAll = () => {
    if (!props.max_selectable) {
      setSelectedItems(options);
    } else {
      setSelectedItems(options.slice(0, props.max_selectable));
    }
  };

  return (
    <Dialog
      onClose={props.handleCancel}
      open={props.open}
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle>
        {(props.submitLabel ? props.submitLabel : "Add") + ` ${item_name}`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ItemFilterAndSearch
              list={selectableItems}
              setOptions={setOptions}
              itemType={props.item_type}
              disableEventItems={props.disableEventItems}
              disableMyItems={props.disableMyItems}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectedItems
              selectedItems={selectedItems}
              removeItemFromSelected={removeSelectedItem}
              clearSelections={clearSelections}
              max_selectable={props.max_selectable}
            />
          </Grid>
          {options.length === 0 && (
            <>
              <Grid item xs={12}>
                <Typography textAlign={"center"}>No items found</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => setCreatingNewItem(true)}
                  >
                    Add New Item
                  </Link>
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <ItemsTable
              items={options}
              itemIsSelected={itemIsSelected}
              addSelectedItem={addSelectedItem}
              removeSelectedItem={removeSelectedItem}
            />
            <CreateItemDialog
              open={creatingNewItem}
              handleCancel={() => setCreatingNewItem(false)}
              handleSubmit={(item) => {
                addSelectedItem(item);
              }}
              item_type={props.item_type}
              disablePricing={props.disablePricing}
            />
          </Grid>
          {options.length !== 0 && options.length < 100 && (
            <Grid item xs={12} container spacing={1}>
              <Grid item xs="auto">
                <Typography>{options.length} Items</Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography>
                  <Link sx={{ cursor: "pointer" }} onClick={selectAll}>
                    Select All
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item xs="auto">
            <Button
              color="info"
              variant="outlined"
              onClick={() => setCreatingNewItem(true)}
              id="cancel-button"
            >
              Add New Item
            </Button>
          </Grid>
          <Grid item xs="auto" container spacing={1}>
            <Grid item xs="auto">
              <Button
                color="info"
                variant="outlined"
                onClick={props.handleCancel}
                id="cancel-button"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                id="add-button"
              >
                {props.submitLabel ? props.submitLabel : "Add"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const SelectedItems = (props) => {
  return (
    <Box sx={{ minHeight: 61 }}>
      <Grid container spacing={0.5}>
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs="auto">
            <Typography>
              {props.selectedItems.length}{" "}
              {props.max_selectable && `Of ${props.max_selectable}`} Items
              Selected
            </Typography>
          </Grid>
          {props.selectedItems.length > 0 && (
            <Grid item xs={"auto"}>
              <IconButton onClick={props.clearSelections} size="small">
                <CancelOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {props.selectedItems.map((item) => (
          <Grid item xs="auto">
            <SelectedItem
              item={item}
              removeItemFromSelected={props.removeItemFromSelected}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const SelectedItem = (props) => {
  const removeItemFromSelected = () => {
    props.removeItemFromSelected(props.item);
  };
  return (
    <Box onClick={removeItemFromSelected} sx={{ cursor: "pointer" }}>
      <ItemIcon width={25} height={25} item={props.item} />
    </Box>
  );
};

const RenderColors = (props) => {
  const colors = itemColors("MA"); //material colors encompass all color options
  return (
    <Grid container key={props.row.uuid} spacing={0.25}>
      {colors
        .filter((color) => {
          return props.row[color.value];
        })
        .map((color) => (
          <Grid item xs="auto">
            <Tooltip title={color.label}>
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  mr: ".25rem",
                  backgroundColor: color.color_code,
                }}
              ></Box>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

const RenderTags = (props) => {
  const tags = props.tags;
  return (
    <Grid container key={props.row.uuid} spacing={0.25}>
      {tags
        .filter((tag) => {
          return props.row[tag];
        })
        .map((tag) => (
          <Grid item xs="auto">
            <Tooltip title={tag.toUpperCase()}>
              <Typography
                textAlign="center"
                fontSize=".75rem"
                sx={{
                  backgroundColor: "lightgray",
                  borderRadius: "50%",
                  width: "15px",
                  pl: ".5rem",
                  pr: ".5rem",
                }}
              >
                {tag.toUpperCase().slice(0, 2)}
              </Typography>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

const ItemRow = ({ context, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const index = props["data-index"];
  const row = context.rows[index];
  const selectable =
    context.itemIsSelected &&
    context.addSelectedItem &&
    context.removeSelectedItem;
  const isSelected = () => {
    return context?.itemIsSelected(row);
  };

  const handleChangeSelected = (event) => {
    if (isSelected()) {
      context?.removeSelectedItem(row);
    } else {
      context?.addSelectedItem(row);
    }
  };

  const RenderActions = context.renderActions;

  return (
    <TableRow
      {...props}
      onClick={selectable ? handleChangeSelected : undefined}
      sx={{ cursor: "pointer" }}
    >
      {selectable && (
        <TableCell>
          <Checkbox checked={isSelected()} onChange={handleChangeSelected} />
        </TableCell>
      )}
      <TableCell sx={{ width: "50px", maxWidth: "50px", minWidth: "50px" }}>
        <ItemIcon item={row} width={fullScreen ? "30px" : "40px"} />
      </TableCell>
      <TableCell>
        <StackedItemName item={row} />
      </TableCell>
      {context.enableColors && !fullScreen && (
        <TableCell>
          <RenderColors row={row} />
        </TableCell>
      )}
      {context.enableCategories && !fullScreen && (
        <TableCell>
          <RenderTags tags={itemCategories()} row={row} />
        </TableCell>
      )}
      {context.enableSeasons && !fullScreen && (
        <TableCell>
          <RenderTags tags={itemSeasons()} row={row} />
        </TableCell>
      )}
      {context.renderActions && (
        <TableCell sx={{ minWidth: "80px" }}>
          <RenderActions row={row} />
        </TableCell>
      )}
    </TableRow>
  );
};

const TableComponent = ({ context, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const selectable =
    context.itemIsSelected &&
    context.addSelectedItem &&
    context.removeSelectedItem;
  return (
    <Table size="small" sx={{ tableLayout: "fixed" }}>
      <colgroup>
        {selectable && <col style={{ width: "50px" }} />}
        <col style={{ width: "50px" }} />
        <col style={{ width: "200px" }} />
        {context.enableColors && !fullScreen && (
          <col style={{ minWidth: "70px" }} />
        )}
        {context.enableCategories && !fullScreen && (
          <col style={{ minWidth: "70px" }} />
        )}
        {context.enableSeasons && !fullScreen && (
          <col style={{ minWidth: "70px" }} />
        )}
        <col style={{ minWidth: "80px" }} />
      </colgroup>
      {props.children}
    </Table>
  );
};

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Box} {...props} ref={ref} />
  )),
  Table: TableComponent,
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow: ItemRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export const ItemsTable = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <TableVirtuoso
      style={{
        height: props.height || "350px",
        width: props.width || (fullScreen ? "275px" : "335px"),
      }}
      data={props.items}
      components={VirtuosoTableComponents}
      context={{
        itemIsSelected: props.itemIsSelected,
        addSelectedItem: props.addSelectedItem,
        removeSelectedItem: props.removeSelectedItem,
        rows: props.items,
        enableCategories: props.enableCategories,
        enableSeasons: props.enableSeasons,
        enableColors: props.enableColors,
        renderActions: props.renderActions,
      }}
    />
  );
};

export const ItemFilterAndSearch = (props) => {
  const [itemsInEvent] = useAccountState((state) => [state.itemsInEvent]);
  const [searchValue, setSearchValue] = React.useState("");
  const handleSearchValueChange = debounce((e) => {
    setSearchValue(e.target.value);
  }, 300);

  const [itemsInEventFilter, setItemsInEventFilter] = React.useState(false);
  const toggleItemsInEventFilter = () => {
    setItemsInEventFilter(!itemsInEventFilter);
  };

  const [myItemsFilter, setMyItemsFilter] = React.useState(false);
  const toggleMyItemsFilter = () => {
    setMyItemsFilter(!myItemsFilter);
  };

  const [itemTypeFilters, setItemTypeFilters] = React.useState([]);
  const [addingItemTypeFilter, setaddingItemTypeFilter] = React.useState(false);
  const toggleaddingItemTypeFilter = () => {
    setaddingItemTypeFilter(!addingItemTypeFilter);
  };
  const selectableItemTypes = () => {
    let types = props.list.map((item) => item.name);
    return [...new Set(types)].sort();
  };

  const [colorFilters, setColorFilters] = React.useState([]);
  const [addingColorFilter, setaddingColorFilter] = React.useState(false);
  const toggleaddingColorFilter = () => {
    setaddingColorFilter(!addingColorFilter);
  };
  const selectableColors = () => {
    let colors = props.list.reduce((acc, item) => {
      let item_colors = getItemColors(item);
      return [...acc, ...item_colors];
    }, []);
    let color_codes = [...new Set(colors.map((color) => color.color_code))];
    colors = color_codes.map((color_code) => getColorByCode(color_code));
    return colors;
  };

  const [roleFilters, setRoleFilters] = React.useState([]);
  const [addingRoleFilter, setaddingRoleFilter] = React.useState(false);
  const toggleaddingRoleFilter = () => {
    setaddingRoleFilter(!addingRoleFilter);
  };
  const selectableRoles = () => {
    let roles = props.list.reduce((acc, item) => {
      return [...acc, ...getItemCategories(item)];
    }, []);
    return [...new Set(roles)].sort();
  };

  const [seasonFilters, setSeasonFilters] = React.useState([]);
  const [addingSeasonFilter, setaddingSeasonFilter] = React.useState(false);
  const toggleaddingSeasonFilter = () => {
    setaddingSeasonFilter(!addingSeasonFilter);
  };
  const selectableSeasons = () => {
    let seasons = props.list.reduce((acc, item) => {
      return [...acc, ...getItemSeasons(item)];
    }, []);
    return [...new Set(seasons)].sort();
  };

  const [materialTypeFilters, setMaterialTypeFilters] = React.useState([]);
  const [addingMaterialTypeFilter, setaddingMaterialTypeFilter] =
    React.useState(false);
  const toggleaddingMaterialTypeFilter = () => {
    setaddingMaterialTypeFilter(!addingMaterialTypeFilter);
  };
  const selectableMaterialTypes = () => {
    let types = props.list.map((item) => item.type);
    return [...new Set(types)].sort();
  };

  React.useEffect(() => {
    let filteredItems = props.list;
    if (itemsInEventFilter) {
      const items_in_event = itemsInEvent();
      filteredItems = filteredItems.filter(
        (item) =>
          items_in_event?.findIndex(
            (item_in_event) => item_in_event.uuid === item.uuid
          ) > -1
      );
    }
    if (myItemsFilter) {
      filteredItems = filteredItems.filter((item) => item.user);
    }
    if (itemTypeFilters.length > 0) {
      filteredItems = filteredItems.filter((item) =>
        itemTypeFilters.some((filter) => filter === item.name)
      );
    }
    if (colorFilters.length > 0) {
      filteredItems = filteredItems.filter((item) =>
        colorFilters.some((filter) => item[filter.value])
      );
    }
    if (roleFilters.length > 0) {
      filteredItems = filteredItems.filter((item) =>
        roleFilters.some((filter) => getItemCategories(item).includes(filter))
      );
    }
    if (seasonFilters.length > 0) {
      filteredItems = filteredItems.filter((item) =>
        seasonFilters.some((filter) => getItemSeasons(item).includes(filter))
      );
    }
    if (materialTypeFilters.length > 0) {
      filteredItems = filteredItems.filter((item) =>
        materialTypeFilters.some((filter) => filter === item.type)
      );
    }
    if (searchValue) {
      filteredItems = filteredItems.map((item) => {
        return {
          ...item,
          display_name: itemDisplayName(item),
        };
      });
      props.setOptions(
        matchSorter(filteredItems, searchValue, {
          keys: ["display_name"],
        })
      );
    } else {
      props.setOptions([...filteredItems].sort(sortByItemDisplayName));
    }
  }, [
    itemTypeFilters,
    colorFilters,
    roleFilters,
    seasonFilters,
    itemsInEventFilter,
    myItemsFilter,
    materialTypeFilters,
    searchValue,
    props.list,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Search"
          onChange={handleSearchValueChange}
          size="small"
          sx={{ mt: ".5rem" }}
        />
      </Grid>
      <Grid item xs={12} container spacing={0.5}>
        {!props.disableEventItems && (
          <Grid item xs="auto">
            <Chip
              label="Items in Event"
              onClick={toggleItemsInEventFilter}
              variant={itemsInEventFilter ? "filled" : "outlined"}
              size="small"
              color={itemsInEventFilter ? "primary" : undefined}
            />
          </Grid>
        )}
        {!props.disableMyItems && (
          <Grid item xs="auto">
            <Chip
              label="My Items"
              onClick={toggleMyItemsFilter}
              variant={myItemsFilter ? "filled" : "outlined"}
              size="small"
              color={myItemsFilter ? "primary" : undefined}
            />
          </Grid>
        )}
        <Grid item xs="auto">
          <Chip
            label="Type +"
            onClick={toggleaddingItemTypeFilter}
            variant="outlined"
            size="small"
          />
          <AddFilterDialog
            open={addingItemTypeFilter}
            handleCancel={toggleaddingItemTypeFilter}
            value={itemTypeFilters}
            setValue={setItemTypeFilters}
            options={selectableItemTypes()}
            label="Type"
          />
        </Grid>
        {props.itemType === "MA" && (
          <Grid item xs="auto">
            <Chip
              label="Category +"
              onClick={toggleaddingMaterialTypeFilter}
              variant="outlined"
              size="small"
            />
            <AddFilterDialog
              open={addingMaterialTypeFilter}
              handleCancel={toggleaddingMaterialTypeFilter}
              value={materialTypeFilters}
              setValue={setMaterialTypeFilters}
              options={selectableMaterialTypes()}
              label="Category"
              getOptionLabel={(option) => materialTypeName(option)}
            />
          </Grid>
        )}
        <Grid item xs="auto">
          <Chip
            label="Color +"
            onClick={toggleaddingColorFilter}
            variant="outlined"
            size="small"
          />
          <AddFilterDialog
            open={addingColorFilter}
            handleCancel={toggleaddingColorFilter}
            value={colorFilters}
            setValue={setColorFilters}
            options={selectableColors()}
            label="Color"
            filterOptions={(options, params) => {
              let filtered_options = matchSorter(options, params.inputValue, {
                keys: ["label"],
              });
              return filtered_options;
            }}
            renderTags={(values, getTagProps) => {
              return values.map((value, ind) => (
                <Box
                  key={value.value}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  {...getTagProps(value)}
                >
                  <Box
                    sx={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      mr: ".25rem",
                      backgroundColor: value.color_code,
                    }}
                  ></Box>
                  <Typography>{value.label}</Typography>
                </Box>
              ));
            }}
            renderOption={(props, option) => {
              return [
                null,
                <Box
                  key={option.value}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  {...props}
                >
                  <Box
                    sx={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      mr: ".25rem",
                      backgroundColor: option.color_code,
                    }}
                  ></Box>
                  <Typography>{option.label}</Typography>
                </Box>,
              ];
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Grid>
        {props.itemType === "FL" && (
          <Grid item xs="auto">
            <Chip
              label="Function +"
              onClick={toggleaddingRoleFilter}
              variant="outlined"
              size="small"
            />
            <AddFilterDialog
              open={addingRoleFilter}
              handleCancel={toggleaddingRoleFilter}
              value={roleFilters}
              setValue={setRoleFilters}
              options={selectableRoles()}
              label="Function"
            />
          </Grid>
        )}
        {props.itemType === "FL" && (
          <Grid item xs="auto">
            <Chip
              label="Season +"
              onClick={toggleaddingSeasonFilter}
              variant="outlined"
              size="small"
            />
            <AddFilterDialog
              open={addingSeasonFilter}
              handleCancel={toggleaddingSeasonFilter}
              value={seasonFilters}
              setValue={setSeasonFilters}
              options={selectableSeasons()}
              label="Season"
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} container spacing={0.5}>
        {itemTypeFilters.map((filter) => (
          <Grid item xs="auto">
            <Chip
              label={filter}
              onDelete={() => {
                setItemTypeFilters(itemTypeFilters.filter((f) => f !== filter));
              }}
              size="small"
            />
          </Grid>
        ))}
        {colorFilters.map((filter) => (
          <Grid item xs="auto">
            <Chip
              avatar={
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    mr: ".25rem",
                    backgroundColor: filter.color_code,
                  }}
                ></Box>
              }
              label={filter.label}
              onDelete={() => {
                setColorFilters(
                  colorFilters.filter((f) => f.value !== filter.value)
                );
              }}
              size="small"
            />
          </Grid>
        ))}
        {roleFilters.map((filter) => (
          <Grid item xs="auto">
            <Chip
              label={filter}
              onDelete={() => {
                setRoleFilters(roleFilters.filter((f) => f !== filter));
              }}
              size="small"
            />
          </Grid>
        ))}
        {seasonFilters.map((filter) => (
          <Grid item xs="auto">
            <Chip
              label={filter}
              onDelete={() => {
                setSeasonFilters(seasonFilters.filter((f) => f !== filter));
              }}
              size="small"
            />
          </Grid>
        ))}
        {materialTypeFilters.map((filter) => (
          <Grid item xs="auto">
            <Chip
              label={materialTypeName(filter)}
              onDelete={() => {
                setMaterialTypeFilters(
                  materialTypeFilters.filter((f) => f !== filter)
                );
              }}
              size="small"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const AddFilterDialog = (props) => {
  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Add Filter</DialogTitle>
      <DialogContent>
        <Autocomplete
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={props.label} />
          )}
          options={props.options}
          filterSelectedOptions
          value={props.value}
          onChange={handleChange}
          multiple
          renderOption={props.renderOption}
          renderTags={props.renderTags}
          filterOptions={props.filterOptions}
          isOptionEqualToValue={props.isOptionEqualToValue}
          disableCloseOnSelect
          getOptionLabel={props.getOptionLabel}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.handleCancel}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(AddItemDialog);
