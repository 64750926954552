import React, { useState, useCallback, useMemo } from "react";

const DraggableListItem = React.memo(
  ({
    item,
    isPlaceholder,
    isDragged,
    draggedDimensions,
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    listItemComponent: ItemComponent,
    listItemComponentProps,
    listItemPropName,
  }) => {
    const placeholderStyles = useMemo(() => {
      if (!isPlaceholder) return {};
      if (draggedDimensions) {
        return {
          border: "2px dashed #999",
          width: draggedDimensions.width,
          height: draggedDimensions.height,
          boxSizing: "border-box",
        };
      }
      return {
        border: "2px dashed #999",
        minHeight: "40px",
        boxSizing: "border-box",
      };
    }, [isPlaceholder, draggedDimensions]);

    const style = useMemo(
      () => ({
        cursor: !isPlaceholder ? "grab" : "default",
        ...(isDragged ? { opacity: "0.4", border: "2px dashed grey" } : {}),
        ...placeholderStyles,
      }),
      [isPlaceholder, isDragged, placeholderStyles]
    );

    const commonProps = {
      draggable: !isPlaceholder,
      onDragStart: !isPlaceholder ? (e) => handleDragStart(e, item) : undefined,
      onDragOver: (e) => handleDragOver(e, item),
      onDrop: (e) => handleDrop(e, item),
      onDragEnd: handleDragEnd,
      style,
    };

    const passedProps = {
      ...listItemComponentProps,
      ...commonProps,
      [listItemPropName]: item,
      isPlaceholder,
    };

    return <ItemComponent {...passedProps} />;
  }
);

function DraggableList({
  listContainerComponent = "div",
  listContainerComponentProps = {},
  listItemComponent = "div",
  listItemComponentProps = {},
  onDragEnd,
  list = [],
  listItemPropName = "item",
}) {
  const [draggedItem, setDraggedItem] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [draggedDimensions, setDraggedDimensions] = useState(null);

  const renderedItems = useMemo(() => {
    // If we're dragging something and have a placeholder index,
    // remove the dragged item from the array and insert a placeholder.
    if (draggedItem && placeholderIndex !== null) {
      const newList = list.filter((i) => i !== draggedItem);
      newList.splice(placeholderIndex, 0, { __placeholder: true });
      return newList;
    }
    return list;
  }, [list, draggedItem, placeholderIndex]);

  const handleDragStart = useCallback(
    (e, item) => {
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", ""); // Necessary for Firefox and Safari

      const rect = e.currentTarget.getBoundingClientRect();
      setDraggedDimensions({ width: rect.width, height: rect.height });
      setDraggedItem(item);

      const idx = list.indexOf(item);
      // Use requestAnimationFrame so the browser can capture the drag image
      // before we modify the DOM (which removes the dragged element).
      requestAnimationFrame(() => {
        setPlaceholderIndex(idx);
      });
    },
    [list]
  );

  const handleDragOver = useCallback(
    (e, overItem) => {
      e.preventDefault();
      if (!draggedItem) return;
      if (overItem === draggedItem) return;

      const overIndex = renderedItems.indexOf(overItem);
      const newIndex = overIndex === -1 ? renderedItems.length : overIndex;
      if (placeholderIndex !== newIndex) {
        setPlaceholderIndex(newIndex);
      }
    },
    [draggedItem, renderedItems, placeholderIndex]
  );

  const handleDrop = useCallback(
    (e, dropTarget) => {
      e.preventDefault();
      if (!draggedItem || placeholderIndex === null) return;

      const newList = list.filter((i) => i !== draggedItem);
      const finalIndex =
        placeholderIndex > newList.length ? newList.length : placeholderIndex;
      newList.splice(finalIndex, 0, draggedItem);

      setDraggedItem(null);
      setPlaceholderIndex(null);
      setDraggedDimensions(null);

      if (onDragEnd) {
        onDragEnd(newList);
      }
    },
    [draggedItem, placeholderIndex, list, onDragEnd]
  );

  const handleDragEnd = useCallback(() => {
    // If the drag is canceled or finished without drop
    if (draggedItem) {
      setDraggedItem(null);
      setPlaceholderIndex(null);
      setDraggedDimensions(null);
    }
  }, [draggedItem]);

  const ListContainer = listContainerComponent;

  return (
    <ListContainer {...listContainerComponentProps}>
      {renderedItems.map((item, index) => {
        const isPlaceholder = item && item.__placeholder === true;
        const isDragged = item === draggedItem;

        return (
          <DraggableListItem
            key={isPlaceholder ? `placeholder-${index}` : index}
            item={item}
            isPlaceholder={isPlaceholder}
            isDragged={isDragged}
            draggedDimensions={draggedDimensions}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleDragEnd={handleDragEnd}
            listItemComponent={listItemComponent}
            listItemComponentProps={listItemComponentProps}
            listItemPropName={listItemPropName}
          />
        );
      })}
    </ListContainer>
  );
}

export default DraggableList;
