import React, { useCallback } from "react";
import debounce from "lodash/debounce";
import { withRouter } from "react-router-dom";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import MUIRichTextEditor from "mui-rte";
import { Button, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import { Text, StyleSheet, Font, View } from "@react-pdf/renderer";
import Roboto from "../fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../fonts/Roboto/Roboto-Bold.ttf";
import RobotoItalic from "../fonts/Roboto/Roboto-Italic.ttf";
import RobotoBoldItalic from "../fonts/Roboto/Roboto-BoldItalic.ttf";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { convertToRaw } from "draft-js";
import _ from "lodash";

const empty_text = (text) => {
  return JSON.stringify({
    blocks: [
      {
        key: "24ad8",
        text: text,
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  });
};

export const RichTextField = (props) => {
  const [value, setValue] = React.useState(
    props.value ? props.value : empty_text("")
  );

  const defaultTheme = createTheme();

  Object.assign(defaultTheme, {
    overrides: {
      TDraftEditorProps: {
        spellCheck: true,
      },
      MUIRichTextEditor: {
        root: {
          border: "solid 1px lightgrey",
          borderRadius: "5px",
        },
        editor: {
          fontFamily: props.font,
          textAlign: props.textAlign ? props.textAlign : "left",
          fontSize: props.font_size,
          "& a": {
            color: "inherit",
            textDecorationColor: "inherit",
            "&:visited": {
              color: "inherit",
              textDecorationColor: "inherit",
            },
            "&:hover": {
              color: "inherit",
              textDecorationColor: "inherit",
            },
          },
          padding: ".5rem",
        },
      },
    },
  });

  const rteRef = React.useRef(null);
  const [saved, setSaved] = React.useState(false);

  const save = (data) => {
    setSaved(true);
    props.updateValue(data);
  };

  const autoSave = (data) => {
    if (rteRef && rteRef.current) {
      rteRef.current.save();
      if (props.stopEditingOnBlur) {
        props.stopEditing();
      }
    }
  };

  const debouncedAutoSave = useCallback(debounce(autoSave, 2500), []);

  const handleChange = (state) => {
    if (
      !_.isEqual(convertToRaw(state.getCurrentContent()), JSON.parse(value))
    ) {
      debouncedAutoSave();
    }
  };

  React.useEffect(() => {
    if (props.autoFocus) {
      rteRef.current.focus();
    }
  }, [props.autoFocus]);

  const stopEditing = () => {
    autoSave();
    props.stopEditing();
  };

  return (
    <Grid container justifyContent={"center"} spacing={1}>
      <Grid item xs={12}>
        <Typography
          sx={{ color: props.font_color }}
          variant={props.variant ? props.variant : undefined}
        >
          <ThemeProvider theme={defaultTheme}>
            <MUIRichTextEditor
              id={props.id}
              onSave={save}
              onBlur={autoSave}
              onChange={handleChange}
              defaultValue={value}
              controls={[
                "bold",
                "italic",
                "underline",
                "numberList",
                "bulletList",
                "quote",
                "link",
                "undo",
                "redo",
                "save",
              ]}
              ref={rteRef}
              draftEditorProps={{
                spellCheck: true,
              }}
            />
          </ThemeProvider>
          <Snackbar
            open={saved}
            onClose={() => setSaved(false)}
            message="Saved!"
            autoHideDuration={2500}
          />
        </Typography>
      </Grid>
      {props.stopEditing && (
        <Grid item xs={"auto"}>
          <Button
            size="small"
            color="info"
            variant="outlined"
            onClick={stopEditing}
          >
            {" "}
            Stop Editing
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export const RichTextFieldView = (props) => {
  const defaultTheme = createTheme();

  const isEmpty = () => {
    if (props.value) {
      let data = JSON.parse(props.value);
      if (
        data.blocks.length > 0 &&
        data.blocks.some((block) => block.text !== "")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        editor: {
          fontFamily: props.font,
          textAlign: props.textAlign ? props.textAlign : "left",
          fontSize: props.font_size,
          "& a": {
            color: "inherit",
            textDecorationColor: "inherit",
            "&:visited": {
              color: "inherit",
              textDecorationColor: "inherit",
            },
            "&:hover": {
              color: "inherit",
              textDecorationColor: "inherit",
            },
          },
        },
      },
    },
  });

  const displayValue = () => {
    if (!isEmpty()) {
      return props.value;
    }
    let empty_text = props.placeholder ? props.placeholder : "";
    return JSON.stringify({
      blocks: [
        {
          key: "24ad8",
          text: empty_text,
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    });
  };

  return (
    <Typography
      variant={props.variant ? props.variant : undefined}
      sx={{
        fontFamily: props.font,
        color: isEmpty() ? "info.light" : props.font_color,
      }}
      onClick={props.onClick}
    >
      <ThemeProvider theme={defaultTheme}>
        <MUIRichTextEditor
          id={props.id}
          inheritFontSize
          controls={[]}
          readOnly
          defaultValue={displayValue()}
        />
      </ThemeProvider>
    </Typography>
  );
};

export const RichTextFieldEditable = (props) => {
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    if (props.setEditing) {
      props.setEditing(editing);
    }
  }, [editing]);

  const updateValue = (data) => {
    props.updateValue(data);
  };

  const stopEditing = () => {
    setEditing(false);
  };

  return editing ? (
    <RichTextField
      {...props}
      updateValue={updateValue}
      autoFocus
      stopEditing={stopEditing}
      stopEditingOnBlur={props.stopEditingOnBlur}
    />
  ) : (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={props.hideEditIcon || props.stacked ? 12 : 10}>
        <RichTextFieldView {...props} onClick={() => setEditing(true)} />
      </Grid>
      {!props.hideEditIcon && !props.stacked && (
        <Grid item xs="auto">
          <IconButton
            id={props.id + "-edit-button"}
            onClick={() => setEditing(true)}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Grid>
      )}
      {props.stacked && (
        <Grid item xs={12} container justifyContent={"center"}>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="info"
              size="small"
              id={props.id + "-edit-button"}
              onClick={() => setEditing(true)}
            >
              Edit Notes
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

const mergeStyles = (styles) => {
  const mergedStyles = {};
  styles.forEach((style) => {
    for (let i = style.offset; i < style.offset + style.length; i++) {
      if (!mergedStyles[i]) {
        mergedStyles[i] = [];
      }
      mergedStyles[i].push(style.style);
    }
  });
  let style_keys = Object.keys(mergedStyles)
    .map((sk) => parseFloat(sk))
    .sort((a, b) => a - b);
  let start_key = style_keys[0];
  let end_key = style_keys[style_keys.length - 1];
  let consolodatedStyles = [];
  var style = undefined;
  for (let i = start_key; i <= end_key; i++) {
    if (!style) {
      if (mergedStyles[i]) {
        style = { styles: mergedStyles[i], start: i };
      } else {
        continue;
      }
    }
    if (!mergedStyles[i]) {
      style.end = i - 1;
      consolodatedStyles.push(style);
      style = undefined;
    } else if (!arraysEqual(mergedStyles[i], style.styles)) {
      style.end = i - 1;
      consolodatedStyles.push(style);
      style = { styles: mergedStyles[i], start: i };
    }
    if (i === end_key) {
      style.end = i;
      consolodatedStyles.push(style);
    }
  }
  return consolodatedStyles.sort((a, b) => a.start - b.start);
};

Font.register({
  family: "Roboto",
  src: Roboto,
});
Font.register({
  family: "RobotoBold",
  src: RobotoBold,
});
Font.register({
  family: "RobotoItalic",
  src: RobotoItalic,
  fontWeight: 400,
});
Font.register({
  family: "RobotoBoldItalic",
  src: RobotoBoldItalic,
});

const styles = StyleSheet.create({
  BOLD: {
    fontFamily: "RobotoBold",
  },
  ITALIC: {
    fontFamily: "RobotoItalic",
  },
  UNDERLINE: {
    textDecoration: "underline",
  },
  BOLD_ITALIC: {
    fontFamily: "RobotoBoldItalic",
  },
  BOLD_UNDERLINE: {
    textDecoration: "underline",
    fontFamily: "RobotoBold",
  },
  ITALIC_UNDERLINE: {
    textDecoration: "underline",
    fontFamily: "RobotoItalic",
  },
  BOLD_ITALIC_UNDERLINE: {
    textDecoration: "underline",
    fontFamily: "RobotoBoldItalic",
  },
});

const get_block_parts = (block) => {
  const merged_styles = mergeStyles(block.inlineStyleRanges);
  let start_index = 0;
  let end_index = block.text.length - 1;
  let parts = [];
  let part = undefined;
  let last_style_index = undefined;
  for (let i = start_index; i <= end_index; i++) {
    let style = merged_styles.find((ms) => ms.start === i);
    if (style) {
      last_style_index = merged_styles.findIndex((ms) => ms.start === i);
      parts.push({
        text: block.text.slice(i, style.end + 1),
        style: style.styles,
      });
      i = style.end;
    } else {
      let next_style_index;
      if (last_style_index !== -1 && last_style_index !== undefined) {
        next_style_index = last_style_index + 1;
      } else {
        next_style_index = 0;
      }
      let next_style = merged_styles[next_style_index];
      let end;
      if (next_style) {
        end = next_style.start - 1;
      } else {
        end = block.text.length - 1;
      }
      parts.push({
        text: block.text.slice(i, end + 1),
        style: undefined,
      });
      i = end;
    }
  }
  return parts;
};

export const renderRichTextContent = (content) => {
  let ol_num = undefined;
  return JSON.parse(content).blocks.map((block, blockIndex, blocks) => {
    const parts = get_block_parts(block);
    if (block.type === "ordered-list-item") {
      if (ol_num !== undefined) {
        ol_num += 1;
      } else {
        ol_num = 1;
      }
    } else {
      ol_num = undefined;
    }
    return block.text === "" ? (
      <Text>{"\n"}</Text>
    ) : (
      <Text key={block.key} style={{ fontSize: 12 }}>
        {ol_num !== undefined && <Text>{"\u00A0 \u00A0" + ol_num}. </Text>}
        {block.type === "unordered-list-item" && (
          <Text>{"\u00A0 \u00A0 \u2022 "}</Text>
        )}
        {parts &&
          parts.map((part, partIndex) => (
            <Text
              key={partIndex}
              style={part.style ? styles[part.style.join("_")] : {}}
            >
              {part.text}
            </Text>
          ))}
      </Text>
    );
  });
};

export default withRouter(RichTextField);
